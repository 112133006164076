import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Box, Button, Flex, Tooltip } from '@chakra-ui/react';
import CustomInput from '../../../Utils/Input/Index';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { FaRegEdit } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { useDispatch } from 'react-redux';
import { agaMgaAll } from './dependencies/action';


const MgaAgaAdvisor = () => {
  const dispatch=useDispatch()
  const [agaDataAll,setAgaDataAll]=useState([])

  useEffect(() => {
    dispatch(agaMgaAll())
      .then(res => {
        const { status, data } = res;
        
        setAgaDataAll(res)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [dispatch]);
  console.log("USERDATA",agaDataAll)


const downloadPDF = () => {
    const MyDoc = (
      <Document>
        <Page>
          <Text>Quotations</Text>
        </Page>
      </Document>
    );
  const blob = new Blob([MyDoc], { type: 'application/pdf' });
    // saveAs(blob, 'quotations.pdf');
  };
  
const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet();
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'quotations.xlsx');
    };
  
const downloadCSV = () => {
    };


return (
    <Sidebar headingText={'Advisor List'}>
        
      <Box w={'90%'} m={'auto'} mt={'70px'}>
      <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
        <Flex justifyContent={'space-between'} mt={'10px'} mb={'25px'}>
            <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
              <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button></Tooltip>
              <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
              <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button></Tooltip>
            </div>
            <div>
              {/* <label htmlFor="search">Search:</label> */}
                <CustomInput/>
              {/* <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
            </div>
        </Flex>

        <Box>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                    <col style={{ width: '15%'   }} />
                    <col style={{ width: '10%'    }} />
                    <col style={{ width: '15%'    }} />
                    <col style={{ width: '10%'    }} />
                    <col style={{ width: '10%'    }} />
                    <col style={{ width: '10%'    }} />
                    <col style={{ width: '7%'    }} />
                    <col style={{ width: '8%'    }} />
                    <col style={{ width: '5%'    }} />
                    <col style={{ width: '7%'    }} />
                </colgroup>
                <thead>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <th style={{ textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Name</th>
                        <th style={{ textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Role</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Email </th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Phone</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Created At</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Profile Status</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Approve Status</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Edit</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Delete</th>
                    </tr>
                </thead>
                <tbody>
                {agaDataAll.map((data, index) => (
                  <tr key={index} style={{ borderBottom: '1px solid #dddddd' }}>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.firstName} {data.lastName}</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Advisor</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.email}</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.phone}</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.created_at}</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Completed</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>
                      <Button size={'sm'} background={'#4caf50'} color={'white'}>
                        <TiTick />
                      </Button>
                    </td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>
                      <Button size={'sm'} background={'#4caf50'} color={'white'}>
                        <TiTick />
                      </Button>
                    </td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '20px', fontFamily: 'Open Sans, sans-serif' }}>
                      <FaRegEdit />
                    </td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>
                      <Button size={'sm'} background={'red'} color={'white'}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
                 </table>
        </Box>

        </Box>
      </Box>
    </Sidebar>
  )
}

export default MgaAgaAdvisor
