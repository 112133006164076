import { Tab, TabList, TabPanel, TabPanels, Tabs,Box } from "@chakra-ui/react";
import React from "react";
import SuperVisa from "../index";
import TripDetails from "./Tripdetails";

function GetQuoteComp() {


return (
    <div>
      <SuperVisa />
      <div>
      <Tabs style={{ marginTop: "20px", display:'flex' ,width:'80%', margin:'auto' }} variant="unstyled">
        <Box width="20%">
        <TabList 
          style={{
            display: "flex",
            flexDirection: "column",
            border: "2px solid #088183",
            backgroundColor: "#088183",
            padding: "10px",
            paddingBottom: "10px",
            marginTop:'50px',
            height:'auto',
            color: "white",
            fontSize: "16px",
            fontWeight: "700",
          }}
        >
          <Tab
            _selected={{
              color: "black",
              fontSize: "14px",
              fontWeight: "600",
              alignItems: "center",
              borderRadius: "10px",
              bg: "white",
            }}
          >
            Get a Quote
          </Tab>
          <Tab
            _selected={{
              color: "black",
              fontSize: "14px",
              fontWeight: "600",
              alignItems: "center",
              borderRadius: "10px",
              bg: "white",
            }}
          >
            Confirm & Pay
          </Tab>
          <Tab
            _selected={{
              color: "black",
              fontSize: "14px",
              fontWeight: "600",
              alignItems: "center",
              borderRadius: "10px",
              bg: "white",
            }}
          >
            Purchase Policy
          </Tab>
        </TabList>
        </Box>

        <TabPanels style={{ marginTop: "10px",width:'80%'}}>
          <TabPanel>
            <TripDetails />
          </TabPanel>
          <TabPanel>
            <p>two!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
      </div>
    </div>
  );
}
export default GetQuoteComp;
