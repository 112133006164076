import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, TabPanels, Tabs,Box } from "@chakra-ui/react";
import SuperVisa from "../../SuperVisa";
import EditQuote from "./EditQuote";
import Api from "../../../dependencies/utils/Api";
import EditSuperVisaQuote from "./EditSuperVisaQuote";


const CustomerEditQuote = () => {
    const { id } = useParams();
    const [data,setData]=useState([])

  useEffect(()=>{
      getData()
  },[])
  
  const getData =()=>{
      Api.GET(`http://192.168.1.6:8080/Quote/getQuoteByQuotationNo/${id}`).then((res)=>{
          console.log(res,'res')
          setData(res.data)
      })
  }

return (
        <div>
          <SuperVisa />
          <div>
          <Tabs style={{ marginTop: "20px", display:'flex' ,width:'80%', margin:'auto' }} variant="unstyled">
            <Box width="20%">
            <TabList 
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px solid #088183",
                backgroundColor: "#088183",
                padding: "10px",
                paddingBottom: "10px",
                marginTop:'50px',
                height:'auto',
                color: "white",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              <Tab
                _selected={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Get a Quote
              </Tab>
              <Tab
                _selected={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Confirm & Pay
              </Tab>
              <Tab
                _selected={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Purchase Policy
              </Tab>
            </TabList>
            </Box>
    
            <TabPanels style={{ marginTop: "10px",width:'80%'}}>
              <TabPanel>
                {data.product==="Supervisa"? <EditSuperVisaQuote id={id}/>:data.product==="VTC"?<EditQuote id={id}/>: <EditSuperVisaQuote id={id}/>}
              </TabPanel>
              <TabPanel>
                <p>two!</p>
              </TabPanel>
            </TabPanels>
          </Tabs>
          </div>
        </div>
      );
    }

export default CustomerEditQuote
