import React, { useEffect, useState } from 'react'
import {Button,Checkbox,Input,Select,Box,Flex,Text,TableContainer,Table,Thead,Tr,Th,Td,Tbody,Stack, Textarea, useToast,Tab,TabList,Tabs,TabPanels,TabPanel,Tooltip} from "@chakra-ui/react";
import Api from '../../../dependencies/utils/Api';
import { IoMdCloseCircle } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import RadioOption from '../../../Utils/RadioCard';
import { useDispatch } from 'react-redux';
import { isSameMonth } from "date-fns";
import { advisorCalculateVtc, advisorGenerateQuote } from '../../Advisor/Dependencies/action';
import { BeneficiaryDetailsModal, VTCDetailsModal, getMaxSelectableDate } from '../../Advisor/AdvisorQuote/Validation';
import { buttonBackgroundColor, buttonColor } from '../../../Utils/UniversalColor';



const ViewQuote = ({id}) => {
    const [data,setData]=useState([])
    const [policyLimit, setPolicyLimit] = useState('');
    const [studentPolicyLimit, setStudentPolicyLimit] = useState('');
    const [vtcPlanType,setVtcPlanType]=useState('')
    const [studentPlanType,setStudentPlanType]=useState('')
    const [dedecutibles,setDeductibles]=useState ('')
    const toast=useToast()
    const [waitingPeriod, setWaitingPeriod] = useState("");
    const [studentWaitingPeriod, setStudentWaitingPeriod] = useState("");
    const [duration,setDuration]=useState('')
    const [superVisa,setSuperVisa]=useState(false)
    const [familyCoverage,setFamilyCoverage]=useState(false)
    const [policyType, setPolicyType] = useState('VTC');
    const [studentPolicyOption,setStudentPolicyOption]=useState('Single Trip')
    const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
    const [isVtcModalOpen, setIsVtcModalOpen] = useState(false);
    const [vtcData,setVtcData]=useState([])
    const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState('0')
    const dispatch=useDispatch()
    const [selectedCostOption, setSelectedCostOption] = useState('');
    const [message,setMessage]=useState('')
    const [amount,setAmount]=useState(0)
  
    const [addTravelers, setAddTravelers] = useState([
      {
        name: "",
        gender: "",
        dob: " ",
        relation: "",
        benificiaryDob: "",
        benificiaryName: "",
      },
    ]);
    const [formData, setFormData] = useState({
      firstDate: '',
      lastDate: '',
      clientCountry: 'canada',
      clientProvince: '',
      vtcDepartureDate: "",
      studentDepartureDate: "",
    });
    const [errors, setErrors] = useState({
      firstDate: "",
      lastDate: "",
      vtcDepartureDate: "",
      policyOwnerEmail:'',
      policyOwnerAddress:'',
      policyOwnerPostalCode:'',
      policyOwnerCity:'',
      policyOwnerPhone:''
    });
    const [policyHolder, setPolicyHolder] = useState({
      policyOwnerName: '',
      policyOwnerDob: '',
      policyOwnerEmail: '',
      policyOwnerAddress: '',
      policyOwnerPostalCode: '',
      policyOwnerCity: '',
      policyOwnerPhone: ''
    });
  
    useEffect(() => {
      const sameMonth = () => {
        const liesDate = new Date(formData.firstDate);
        const selectedDate = new Date(formData.vtcDepartureDate);
        const validDate = isSameMonth(selectedDate, liesDate);
        return validDate;
      };
      setWaitingPeriod(sameMonth() ? "48 Hour" : "8 days");
    }, [formData.firstDate, formData.vtcDepartureDate]);
  
    const handleCostOptionChange = (index, value, ele) => {
      setSelectedCostOption(prevSelectedCostOption => ({
        ...prevSelectedCostOption,
        [index]: value
      }));
      const selectedValues = Object.values(selectedCostOption).filter((val, idx) => idx !== index);
      const totalSelectedValue = selectedValues.reduce((acc, curr) => acc + curr, 0);
      const totalValue = totalSelectedValue + value;
      const selectedOption = (value === ele.costWithPreExisting) ? 'withPreExisting' : 'withoutPreExisting';
      const message = selectedOption === 'withPreExisting' ? vtcData.messageOfPreExisting : vtcData.messageOfWithoutPreExisting;
      console.log(message, 'selected', ele, value, index);
      setMessage(message);
      setAmount(totalValue);
    };
    
  
    const handlePolicyOwnerChange = (e) => {
      const { name, value } = e.target;
      setPolicyHolder({ ...policyHolder, [name]: value });
      // setErrors({ ...errors, [e.target.name]: " " });
      const selectedPolicyHolder = addTravelers.find((traveler) => traveler.name === value);
      if (selectedPolicyHolder) {
        setPolicyHolder({ ...policyHolder, policyOwnerDob: selectedPolicyHolder.dob, policyOwnerName: selectedPolicyHolder.name });
      }
    };
  
    useEffect(() => {
      if (!formData.vtcDepartureDate) {
        setWaitingPeriod("NA");
      } else {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        const selectedDate = new Date(formData.vtcDepartureDate);
        const firstDate = new Date(formData.firstDate)
        const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
        if (selectedDate>=firstDate) {
          setWaitingPeriod("NA");
        } else {
          setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
        }
      }
      if (!formData.studentDepartureDate) {
        setStudentWaitingPeriod("NA");
      } else {
        const today = new Date();
        const selectedDate = new Date(formData.studentDepartureDate);
        if (selectedDate > today) {
          setStudentWaitingPeriod("NA");
        } else {
          setStudentWaitingPeriod("5 days");
        }
      }
    }, [formData.vtcDepartureDate,formData.studentDepartureDate]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "firstDate" || name === "lastDate") {
        setSuperVisa(false);
      }
      setFormData({ ...formData, [name]: value });
      if (name === "lastDate") {
        if (!formData.firstDate) {
          alert("Please select the first date of cover first");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
          return;
        }
        const startDate = new Date(formData.firstDate);
        const endDate = new Date(value);
        const diffInMilliseconds = endDate - startDate;
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
    
        if (endDate < startDate) {
          alert("Last date cannot be earlier than the first date");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
        } else if (diffInMilliseconds > oneYearInMilliseconds) {
          alert("Duration cannot exceed 1 year");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
        } else {
          const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
          setDuration(durationInDays);
        }
      }
    
      if (name === "vtcDepartureDate") {
        if (!formData.firstDate) {
          alert("Please select the first date of cover");
          setFormData({ ...formData, vtcDepartureDate: "" });
          return;
      }
      }
  
      if (name === "studentDepartureDate") {
        if (!formData.firstDate) {
          alert("Please select the first date of cover");
          setFormData({ ...formData, studentDepartureDate: "" });
          return;
      }
      }
    };
  
    const handleSuperVisaChange = (e) => {
      if (!formData.firstDate) {
        alert("Please select the first date of cover first");
        return;
      }
        const { checked } = e.target;
        if (checked) {
          setPolicyType("Supervisa");
        } else {
          setPolicyType("VTC");
        }
        setSuperVisa(checked);
          const startDate = new Date(formData.firstDate);
          const endDate = new Date(startDate);
          endDate.setFullYear(startDate.getFullYear() + 1);
          setFormData({ ...formData, lastDate: endDate.toISOString().split('T')[0] }); 
          setDuration('365')
    };
    
    const handleFamilyCoverageChange = (e) => {
      if(addTravelers.length<3){
        alert('Select Atleast 3 member of family')
        return;
      }
      const isAnyDetailEmpty = addTravelers.some(
        (traveler) =>
          traveler.name === "" ||
          traveler.gender === "" ||
          traveler.dob === "" ||
          traveler.relation === "" ||
          traveler.benificiaryName === "" ||
          traveler.benificiaryDob === ""
      );
    
      if (isAnyDetailEmpty) {
        alert("Please fill in all family member details before selecting family coverage.");
        return;
      }
      const isAnyAgeBetween27to69 = addTravelers.some((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 27 && age <= 69;
      });
    
      if (!isAnyAgeBetween27to69) {
        alert("At least one family member's age should be between 27 to 69 years for family coverage.");
        return;
      }
      const isAllAgeGreaterThanOne = addTravelers.every((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 1;
      });
    
      if (!isAllAgeGreaterThanOne) {
        alert("family member must be greater than one year");
        return;
      }
      const { checked } = e.target;
      setFamilyCoverage(checked);
    }
  
    const handlePolicyLimitChange = (value) => {
        setPolicyLimit(value);
    };
  
    const handleStudentPolicyLimitChange = (value) => {
      setStudentPolicyLimit(value);
    };
  
    const handleStudentPolicyOption = (e) => {
      const { value } = e.target;
      setStudentPolicyOption(value);
      setPolicyType("Studentvisa");
    };
  
    const handlePlanTypeChange = (value) => { 
      const isAnyDetailEmpty = addTravelers.some(
        (traveler) => {
          if (!traveler.name) {
            alert("Please enter name");
            return true;
          }
          if (!traveler.gender) {
            alert("Please Select gender");
            return true;
          }
          if (!traveler.dob) {
            alert("Please Select DOB");
            return true;
          }
          return false;
        }
      ); 
  
      if (isAnyDetailEmpty) {
        return; // Exit the function if any detail is empty
      }
  
      // Proceed with handling plan type change
      setVtcPlanType(value);
    };
  
    const handleStudentPlanTypeChange = (value) => { 
      setStudentPlanType(value);
    };
    const handleDeductiblesChange = (value) => { 
      setDeductibles(value);
    };
  
    const handleAddTraveller = () => {
      if (addTravelers.length < 3) {
        setAddTravelers([
          ...addTravelers,
          {
            name: "",
            gender: "",
            dob: "",
            relation: "",
            benificiaryDob: "",
            benificiaryName: "",
            // costWithoutPreExisting:"",
            // costWithPreExisting:""
          },
        ]);
      } else {
        toast({ title: "you can not add more than 3 family members." });
      }
    };
  
    const handleChangeTraveler = (index, e) => {
      const { name, value } = e.target;
      if (name === "dob" || name === "benificiaryDob") {
          const selectedDate = new Date(value);
          const minDate = new Date();
          minDate.setDate(minDate.getDate() - 15);
          if (selectedDate >= minDate) {
              alert("Users must be at least 15 days older than today.");
              return;
          }
      }
      setAddTravelers((prevState) => {
          const updatedState = [...prevState];
          updatedState[index] = { ...updatedState[index], [name]: value };
          return updatedState;
      });
    };
  
    const handleRemoveTraveller = (index) => {
      const updatedTravelers = [...addTravelers];
      updatedTravelers.splice(index, 1);
      setAddTravelers(updatedTravelers);
    };
  
    const validateFormData = (formData, vtcPlanType, addTravelers) => {
      if (!formData.firstDate) {
        return "First date of cover must be provided.";
      }
      if (!formData.lastDate) {
        return "Last date of cover must be provided.";
      }
      const isAnyBeneficiaryDetailEmpty = addTravelers.some(
        (traveler) =>
          traveler.benificiaryName === "" ||
          traveler.benificiaryDob === "" ||
          traveler.relation === ""
      );
      if (isAnyBeneficiaryDetailEmpty) {
        return "Please fill in all beneficiary details.";
      }
      return null;
    };
  
    const handleVtcCalculate = async () => {
      if(policyLimit.length===0){
        alert("Please choose policy limit")
        return;
      }
      if(!vtcPlanType){
        alert("Please choose plan type")
        return;
      }
      if(!dedecutibles){
        alert("Please choose Dedecutibles")
        return;
      }
      if(!formData.vtcDepartureDate){
        alert("Please choose date of Departure")
        return;
      }
      if(superVisa){
        setPolicyType('Supervisa')
      }else{
        setPolicyType('VTC')
      }
      const validationError = validateFormData(formData, vtcPlanType, addTravelers);
      if (validationError) {
        alert(validationError);
        return;
      }
      setFormData(prevFormData => ({
        ...prevFormData,
          studentDepartureDate: ''        
      }));
      setStudentPolicyLimit('')
      setStudentPlanType('')
      setIsVtcModalOpen(true);
    };
  
    const handleStudentCalculate = async () =>{
      setPolicyType('Studentvisa')
      if (!formData.firstDate) {
        alert('First date of cover must be provided.')
        return
      }
      if (!formData.lastDate) {
        alert('Last date of cover must be provided.')
        return
      }
      if (!formData.studentDepartureDate) {
        alert('Departure date must be provided.')
        return
      }
      if (!studentPlanType) {
        alert('Plan Type must be provided.')
        return
      }
      if (!studentPolicyLimit) {
        alert('Policy Limit must be provided.')
        return
      }
      setFormData(prevFormData => ({
        ...prevFormData,
          vtcDepartureDate: ''        
      }));
      setPolicyLimit('')
      setDeductibles('')
      setVtcPlanType('')
      const isAnyBeneficiaryDetailEmpty = addTravelers.some(
        (traveler) =>
          traveler.benificiaryName === "" ||
          traveler.benificiaryDob === "" ||
          traveler.relation === ""
      );
      if (isAnyBeneficiaryDetailEmpty) {
        alert('Please fill in all beneficiary details.')
        return
      }
      setIsStudentModalOpen(true);
    }
  
    const handleStudentModalSubmit = () => {
      setIsStudentModalOpen(false); 
        const updatedTravelers = addTravelers.map((traveler) => ({
          insuredName: traveler.name,
          insuredGender: traveler.gender,
          insuredDOB: traveler.dob,
          insuredRelation: traveler.relation,
          beneficiaryName: traveler.benificiaryName,
          beneficiaryDob: traveler.benificiaryDob,
        }));
      const dataToLog = {
          policyStartDate: formData.firstDate,
          policyEndDate: formData.lastDate,
          planType:studentPlanType,
          insuredDetailsRequests: updatedTravelers,
          policyType:'Studentvisa',
          tripType:studentPolicyOption
      };
      console.log(dataToLog,"Vtc form submit data")
      dispatch(advisorCalculateVtc(dataToLog)).then(res => {
        const {status, data} = res
        console.log(data,'data')
        setVtcData(data)
      })
    };
  
    const handleVtcModalSubmit = async () => {
      
      setIsVtcModalOpen(false); 
          const updatedTravelers = addTravelers.map((traveler) => ({
            insuredName: traveler.name,
            insuredGender: traveler.gender,
            insuredDOB: traveler.dob,
            insuredRelation: traveler.relation,
            beneficiaryName: traveler.benificiaryName,
            beneficiaryDob: traveler.benificiaryDob,
          }));
        const dataToLog = {
            firstDateOfCover: formData.firstDate,
            lastDateOfCover: formData.lastDate,
            deductible:dedecutibles,
            policyLimit:parseInt(policyLimit),
            planType:vtcPlanType,
            vtcDepartureDate: formData.vtcDepartureDate,
            supervisa: superVisa,
            insuredDetailsRequests: updatedTravelers,
            policyType:policyType,
            familyPolicy:familyCoverage,
            paymentFrequency:superVisa?parseInt(policyTypeSuperVisa):0
        };
        console.log(dataToLog,"Vtc form submit data")
        dispatch(advisorCalculateVtc(dataToLog)).then(res => {
          const {status, data} = res
          console.log(data,'data')
          setVtcData(data)
          updateAddTravelers(data.listOfInsuredQutation)
        })
    };
  
    const updateAddTravelers = (response) => {
      setAddTravelers(prevAddTravelers => {
        const updatedTravelers = [...prevAddTravelers];
        response.forEach(responseTraveler => {
          const index = updatedTravelers.findIndex(traveler => traveler.name === responseTraveler.name);
          if (index !== -1) {
            updatedTravelers[index] = {
              ...updatedTravelers[index],
              costWithPreExisting: responseTraveler.costWithPreExisting,
              costWithoutPreExisting: responseTraveler.costwithoutPreExisting
            };
          }
        });
        return updatedTravelers;
      });
    };
    
  
    const handleStudentModalClose = () => {
      setIsStudentModalOpen(false);
    };
  
    const handleVtcModalClose = () => {
      setIsVtcModalOpen(false);
    };
  
  
    const handleGetQuote = async () =>{
      const validationError = validateFormData(formData, vtcPlanType, addTravelers);
      if(!policyHolder.policyOwnerName){
        alert("Please seclet policy Owner")
        return;
      }
      if (validationError) {
        alert(validationError);
        return;
      }
      if(!formData.clientProvince){
        alert("Please choose province.")
        return;
      }
      const { policyOwnerAddress, policyOwnerEmail,policyOwnerCity,policyOwnerPhone,policyOwnerPostalCode} = policyHolder;
      const newErrors = { ...errors };
        if (policyOwnerAddress.length === 0) {
          alert('Please enter valid address')
          // newErrors.policyOwnerAddress = "";
          return;
        }
        if (policyOwnerEmail.length === 0) {
          alert('Please enter valid email')
          // newErrors.policyOwnerEmail = "";
          return
        }
        if (policyOwnerCity.length === 0) {
          alert('Please enter valid City')
          // newErrors.policyOwnerCity = "";
          return
        }
        if (policyOwnerPhone.length === 0) {
          alert('Please enter valid Phone')
          // newErrors.policyOwnerPhone = "";
          return
        }
        if (policyOwnerPostalCode.length === 0) {
          alert('Please enter valid Postal Code')
          // newErrors.policyOwnerPostalCode = "";
          return
        }
        const updatedTravelers = addTravelers.map((traveler) => ({
          nameOfInsured: traveler.name,
          gender: traveler.gender,
          dateOfBirth: traveler.dob,
          beneficiaryRelation: traveler.relation,
          beneficiaryName: traveler.benificiaryName,
          beneficiaryDOB: traveler.benificiaryDob,
          costWithPreExisting:traveler.costWithPreExisting,
          costWithoutPreExisting:traveler.costWithoutPreExisting
        }));
        setErrors(newErrors);
        const dataToSend = {
          email: policyHolder.policyOwnerEmail,
          firstDateOfCover: formData.firstDate,
          lastDateOfCover:formData.lastDate,
          policyEndDate: formData.lastDate,
          duration:parseInt(duration),
          country:1,
          province:formData.clientProvince,
          departureFromCountryOfOrigin: policyType === 'Studentvisa' ? formData.studentDepartureDate : formData.vtcDepartureDate,
          waitingPeriod:waitingPeriod,
          familyCoverage:familyCoverage===true?1:0,
          policyLimit:parseInt(policyLimit),
          deductible:dedecutibles,
          paymentFrequency:1,
          policyOwner:policyHolder.policyOwnerName,
          policyOwnerDateOfBirth:policyHolder.policyOwnerDob,
          policyOwnerEmail:policyHolder.policyOwnerEmail,
          policyOwnerAddress:policyHolder.policyOwnerAddress,
          policyOwnerPostalCode:policyHolder.policyOwnerPostalCode,
          policyOwnerCity:policyHolder.policyOwnerCity,
          policyOwnerPhone:policyHolder.policyOwnerPhone,
          planType:policyType==='Studentvisa'?studentPlanType:vtcPlanType,
          supervisa: superVisa,
          insuredTravellerDetailsRequest: updatedTravelers,
          policyType: policyType,
          amount: amount,
      };
      console.log(dataToSend,'dataTo Send')
  
        Api.PUT(`http://192.168.1.6:8080/Quote/updateQuote`).then((res)=>{
          console.log(res,'update res')
        })
      // dispatch(advisorGenerateQuote(dataToSend)).then(res => {
      //   const {status, data} = res
      //   console.log(data,'data')
      // })
  
    }
    
useEffect(()=>{
    getData()
},[])

const getData =()=>{
    Api.GET(`http://192.168.1.6:8080/Quote/getQuoteByQuotationNo/${id}`).then((res)=>{
        console.log(res,'res')
        setData([res.data])
    })
}



return (
    
    <>
     {data && data.map(ele=>(  
      <Box mt={'20px'}>
       <Box w={'95%'} m={'auto'}>
               <Flex justifyContent={'space-around'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={'20px'}>
                    <Box>
                      <Text fontWeight={'700'}>First Date of Cover</Text>
                      <Input name="firstDate" value={formData.firstDate || ele.firstDateOfCover} onChange={handleChange} w={'180px'} size='xs' type='date' border={'1px solid #d7d7d7'} min={new Date().toISOString().split('T')[0]} />
                    </Box>
                    <Box>
                      <Text fontWeight={'700'}>Last Date of Cover</Text>
                      <Input name="lastDate" value={formData.lastDate || ele.lastDateOfCover} onChange={handleChange} w={'180px'} size='xs' type='date' border={'1px solid #d7d7d7'} />
                    </Box>
                    <Tooltip hasArrow label='No. of Days policy will be active.' bg='gray.300' color='black' placement='top-start'>
                    <Box>
                      <Text fontWeight={'700'}>Duration</Text>
                      <Input w={'80px'} size='xs' backgroundColor={'#eaeaea'} value={`${duration || ele.duration} days`} border={'1px solid #d7d7d7'} readOnly/>
                    </Box>
                    </Tooltip>
                    <Box>
                      <Text fontWeight={'700'}>country</Text>
                        <Text>canada</Text>
                      {/* <Input name="clientCountry" value={formData.clientCountry} placeholder='Canada' w={'180px'} size='xs' border={'1px solid #d7d7d7'} readOnly/> */}
                    </Box>
                    <Box>
                      <Text fontWeight={'700'}>Province</Text>
                      <Select name="clientProvince" value={formData.clientProvince || ele.policyHolderProvince} onChange={handleChange} w={'180px'} size={'xs'} border={'1px solid #d7d7d7'}>
                        <option value="">Select</option>
                        <option value="Alberta">Alberta</option>
                        <option value="British Columbia">British Columbia</option>
                        <option value="Manitoba">Manitoba</option>
                        <option value="New Brunswick">New Brunswick</option>
                        <option value="Newfoundland & Labrador">Newfoundland & Labrador</option>
                        <option value="Northwest Territories">Northwest Territories</option>
                        <option value="Nova Scotia">Nova Scotia</option>
                        <option value="Nunavut">Nunavut</option>
                        <option value="Ontario">Ontario</option>
                        <option value="Prince Edward Island">Prince Edward Island</option>
                        <option value="Quebec">Quebec</option>
                        <option value="Saskatchewan">Saskatchewan</option>
                        <option value="Yukon">Yukon</option>
                      </Select>
                    </Box>
                  </Flex>



    {addTravelers.map((item, index) => {
              return (            
              <Box backgroundColor={'#f3f3f3'} border={'1px solid #80808026'} p={'20px'} m={'auto'} mt={'7px'} key={index} id={`Insured-details-${index}`} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
                {ele.listOfInsured.map(items=>(
                <>
                  <Flex gap={'20px'}>
                    <Box>
                      <Text fontWeight={'700'}>Name Of Insured</Text>
                      <Input background={'white'} w={'280px'} size='xs' border={'1px solid #d7d7d7'}
                       name="name"
                       value={item.name || items.insuredName}
                       onChange={(e) => {
                        const newName = e.target.value;
                        handleChangeTraveler(index, {
                          target: {
                            name: 'name',
                            value: newName === '' ? items.insuredName : newName
                          }
                        });
                      }}                      
                      />            
                    </Box>
                    <Box>
                      <Text fontWeight={'700'}>Gender</Text>
                      <Select w={'280px'} background={'white'} size={'xs'} border={'1px solid #d7d7d7'}
                       value={item.gender || items.insuredGender}
                       name="gender"
                       onChange={(e) => handleChangeTraveler(index, e)}>
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
                    </Box>
                    <Tooltip hasArrow label='Age must between 15 days and 89 years' bg='gray.300' color='black' placement='top-start'>
                    <Box>
                      <Text fontWeight={'600'}>Date of Birth</Text>
                      <Input type='date' background={'white'} w={'180px'} size='xs' border={'1px solid #d7d7d7'}name="dob"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.dob || items.insuredDob} 
                      max={getMaxSelectableDate()}
                      />            
                    </Box>
                      </Tooltip>
                  </Flex>
                  <Flex gap={'20px'} mt={'10px'}>
                    <Box>
                      <Text fontWeight={'700'}>Beneficiary Name</Text>
                      <Input background={'white'} w={'280px'} size='xs' border={'1px solid #d7d7d7'}
                      name="benificiaryName"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.benificiaryName || items.beneficiaryName}/>            
                    </Box>
                    <Box>
                      <Text fontWeight={'700'}>Relation</Text>
                      <Input background={'white'} w={'280px'} size='xs' border={'1px solid #d7d7d7'}
                      name="relation"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.relation || items.beneficiaryRelationToInsured}
                      />            
                    </Box>  
                    <Box>
                      <Text fontWeight={'600'}>Beneficiary DOB</Text>
                      <Input type='date' background={'white'} w={'180px'} size='xs' border={'1px solid #d7d7d7'} onChange={(e) => handleChangeTraveler(index, e)}
                      name="benificiaryDob"
                      value={item.benificiaryDob || items.beneficiaryDob}
                      max={getMaxSelectableDate()}/>            
                    </Box>
                  </Flex>
                  </>
                ))}
                  {index > 0 && (
                          <Flex justifyContent={'end'}>
                            <IoMdCloseCircle
                              style={{fontSize:'22px',color:buttonBackgroundColor}}
                              onClick={() => handleRemoveTraveller(index)}
                              // onClick={() => handleRemoveDependent(index)}
                            />
                          </Flex>
                        )}
                </Box>
                );
              })}
              <Flex fontFamily={'poppins'} fontSize={'12px'} gap={'10px'} justifyContent={'end'} cursor={'pointer'} color={'teal'} mt={'10px'} mb={'10px'}>
                <Flex alignItems={'center'}>
                  <IoMdAdd style={{fontWeight:'bold'}}/>
                    </Flex>
                  <Text onClick={handleAddTraveller}>Add a Spouse Or Dependent</Text>
              </Flex>


                <hr />
                <Box mt={'15px'}>
                <Flex gap={'60px'} fontSize={'13px'}>
                <Flex direction={'column'} alignItems={'center'}>
                <Text fontSize={'12px'}>Departure from country of origin</Text>
                <Input type='date' background={'white'} w={'180px'} size='xs' border={'1px solid #d7d7d7'}
                 name="vtcDepartureDate"
                 onChange={handleChange}
                 value={formData.vtcDepartureDate || ele.departureFromCountryOfOrigin}
                //  max={formData.firstDate}
                 />       
                </Flex>
                <Box>
                <Text>Waiting Period</Text>
                <input
                  name="waiting"
                  value={waitingPeriod || ele.waitingPeriod}
                  readOnly
                />
                  </Box>  
                <Flex direction={'column'} alignItems={'center'}>
                <Text>Family Coverage</Text>
                <input type='checkbox' size='md' name='1' colorScheme='green'
                 value={familyCoverage} onChange={handleFamilyCoverageChange} checked={familyCoverage}></input>
                </Flex>   
                <Flex direction={'column'} alignItems={'center'}>
                <Text>Super Visa</Text>
                <input type='checkbox' size='md' value={superVisa} onChange={handleSuperVisaChange} colorScheme='green' checked={formData.firstDate && superVisa} ></input>
                </Flex>
                {superVisa?(
                        <>
                      
                          <Box>
                          <Text mb={'5px'} fontSize={'12px'}>Student Policy Option</Text>
                            <Select size={'xs'} value={policyTypeSuperVisa} onChange={(event) =>        setPolicyTypeSuperVisa(event.target.value)}>
                              <option value="1">One lump sum</option>
                              <option value="2">Monthly</option>
                            </Select>
                          </Box>
                        </>
                      ):(<> <br /> </>)}
                </Flex>

                <Flex gap={'20px'} mt={'15px'}>
                  <Box>
                    <Text fontSize={'14px'} mt={'15px'} mb={'7px'}>Policy Limit</Text>
                    <Stack spacing={2} direction='row'>
                      {superVisa ? (
                        <>
                          <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                          $100000
                          </RadioOption>
                          <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                          $150000
                          </RadioOption>
                          <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                          $300000
                          </RadioOption>
                        </>
                      ) : (
                        <>
                          <RadioOption value='25000' isSelected={policyLimit === '25000'} onChange={handlePolicyLimitChange}>
                            $25000
                          </RadioOption>
                          <RadioOption value='50000' isSelected={policyLimit === '50000'} onChange={handlePolicyLimitChange}>
                            $50000
                          </RadioOption>
                          <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                            $100000
                          </RadioOption>
                          <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                            $150000
                          </RadioOption>
                          <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                            $300000
                          </RadioOption>
                        </>
                      )}
                    </Stack>

                  </Box>
                  <Box>
                    <Text fontSize={'14px'} mt={'15px'} mb={'7px'}>Select Plan Type</Text>
                    <Stack spacing={2} direction='row'>
                      <RadioOption value='Standard' isSelected={vtcPlanType === 'Standard'} onChange={handlePlanTypeChange}>
                        Standard</RadioOption>
                      <RadioOption value='Enhanced' isSelected={vtcPlanType === 'Enhanced'} onChange={handlePlanTypeChange}>
                        Enhanced</RadioOption>
                      <RadioOption value='Premium' isSelected={vtcPlanType === 'Premium'} onChange={handlePlanTypeChange}>
                       Premium </RadioOption>
                    </Stack>
                  </Box>
                </Flex>


                <Flex gap={'90px'} mt={'25px'} mb={'25px'}>
                <Box>
                    <Text fontSize={'14px'} >Deductible</Text>
                    <Stack spacing={2} direction='row'>
                      <RadioOption value='0' isSelected={dedecutibles === '0'} onChange={handleDeductiblesChange}>
                       $0</RadioOption>
                      <RadioOption value='100' isSelected={dedecutibles === '100'} onChange={handleDeductiblesChange}>
                       $100</RadioOption>
                      <RadioOption value='250' isSelected={dedecutibles === '250'} onChange={handleDeductiblesChange}>
                       $250 </RadioOption>
                       <RadioOption value='500' isSelected={dedecutibles === '500'} onChange={handleDeductiblesChange}>
                       $500</RadioOption>
                       <RadioOption value='1000' isSelected={dedecutibles === '1000'} onChange={handleDeductiblesChange}>
                       $1000</RadioOption>
                       <RadioOption value='3000' isSelected={dedecutibles === '3000'} onChange={handleDeductiblesChange}>
                       $3000</RadioOption>
                    </Stack>
                  </Box>
                  <Flex alignItems={'center'} justifyContent={'space-between'} gap={'40px'} mt={'15px'}>
                      <button style={{backgroundColor:'teal',color:'white',padding:'5px',borderRadius:'10px',fontSize:'12px'}} onClick={handleVtcCalculate}>Calculate Premium</button>
                      {/* <Text fontSize={'12px'} color={'blue'}>See Benefit Comparison</Text> */}
                  </Flex>
                
                </Flex>
              </Box>

          




              <hr />




  <Flex>
    <Box w={'60%'}>
  <TableContainer >
    <Table>
        <Thead>
            <Tr gap={'50px'}>
                <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>User <br/> Name</Th>
                <Th p={'7px'} fontSize={'10px'}>Plan <br/> Name</Th>
                <Th p={'7px'} fontSize={'10px'}>With <br/> Pre-existing</Th>
                <Th p={'7px'} fontSize={'10px'}>Without <br/> Pre-existing</Th>
            </Tr>
        </Thead>
        <Tbody>
          {vtcData?.listOfInsuredQutation?.map((ele, index) => (
                <Tr key={index} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                     {/* {
                      ele.listOfInsured.map((item)=>(<> */}
                    <Td p={'2px'}>{ele.name}</Td>
                    <Td p={'2px'}>{ele.planType}</Td>
                    <Td p={'2px'} display={'flex'}>
                        <input
                          type="radio"
                          name={`costOption-${index}`}
                          value={ele.costWithPreExisting}
                          checked={selectedCostOption[index] === ele.costWithPreExisting}
                          onChange={() => handleCostOptionChange(index, ele.costWithPreExisting,ele)}
                        />
                        <Text pl={'10px'}>
                        {ele.costWithPreExisting}
                        </Text>
                      </Td>
                      <Td p={'2px'}>
                        <Flex>
                        <input
                          type="radio"
                          name={`costOption-${index}`}
                          value={ele.costwithoutPreExisting}
                          checked={selectedCostOption[index] === ele.costwithoutPreExisting}
                          onChange={() => handleCostOptionChange(index, ele.costwithoutPreExisting,ele)}
                          />
                        <Text pl={'10px'}>
                        {ele.costwithoutPreExisting}
                        </Text>
                          </Flex>
                      </Td>
                      {/* </>
                                ))} */}
                </Tr>
            ))}
        </Tbody>
    </Table>
      </TableContainer>
      </Box>
      <Box w={'40%'}>
    <TableContainer>
          <Table>
              <Thead>
                  <Tr>
                      <Th p={'7px'} fontSize={'10px'}>Net</Th>
                      <Th p={'7px'} fontSize={'10px'}>Tax</Th>
                      <Th p={'7px'} fontSize={'10px'}>Adjustment</Th>
                      <Th p={'7px'} fontSize={'10px'}>Total</Th>
                  </Tr>
              </Thead>
              <Tbody>
                      <Tr fontSize={'12px'} p={'2px'} textAlign={'center'}>
                          <Td p={'2px'}>{amount || ele.quoteAmount}</Td>
                          <Td p={'2px'}>0.00 CAD	</Td>
                          <Td p={'2px'} textAlign={'center'}>0.00 CAD	</Td>
                          <Td p={'2px'} textAlign={'center'} fontWeight={'600'}>{amount || ele.quoteAmount}</Td>
                      </Tr>
              </Tbody>
          </Table>
      </TableContainer></Box>
  </Flex>
<Text color={'red'} fontSize={'11px'}>{message}</Text>


                <Text textAlign={'center'} mt={'20px'}>Policy Holder Details</Text>
                <Flex gap={'40px'} fontSize={'14px'} pb={'20px'} mt={'20px'}>
                <Box>
                  <Text>Policy Owner*</Text>
                  <Select w={'180px'} background={'white'} size={'xs'} border={'1px solid #d7d7d7'} name="policyOwnerName" value={policyHolder.policyOwnerName} onChange={handlePolicyOwnerChange}>
                    <option value="">Select Policy Owner</option>
                    {(formData.firstDate && formData.lastDate) && (vtcPlanType || studentPlanType) ? addTravelers.map((traveler, index) => (
                      <option key={index} value={traveler.name}>
                        {traveler.name}
                      </option>
                    )) : null}
                  </Select>
                </Box>
                <Box>
                  <Text>Policy Owner Date Of Birth*</Text>
                  <Input type='date' w={'180px'} size='xs' name="policyOwnerDob" value={policyHolder.policyOwnerDob} onChange={handlePolicyOwnerChange} border={'1px solid #d7d7d7'} />            
                </Box>
                <Box>
                  <Text>Policy Owner Email*</Text>
                  <Input placeholder='Email' w={'180px'} size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={policyHolder.policyOwnerEmail} onChange={handlePolicyOwnerChange}/>    
                  <Text color="red.500">{errors.policyOwnerEmail}</Text>        
                </Box>
                <Box>
                  <Text>Policy Owner Address*</Text>
                  <Input placeholder='Address' w={'180px'} size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={policyHolder.policyOwnerAddress} onChange={handlePolicyOwnerChange} />     
                  <Text color="red.500">{errors.policyOwnerAddress}</Text>       
                </Box>
              </Flex>
              <Flex gap={'40px'} fontSize={'14px'} pb={'20px'} mt={'20px'}>
                <Box>
                  <Text>Policy Owner Postal Code*</Text>
                  <Input placeholder='Postal code' w={'180px'} size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={policyHolder.policyOwnerPostalCode} onChange={handlePolicyOwnerChange}/>   
                  <Text color="red.500">{errors.policyOwnerPostalCode}</Text>         
                </Box>
                <Box>
                  <Text>Policy Owner City*</Text>
                  <Input placeholder='city' w={'180px'} size='xs' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={policyHolder.policyOwnerCity} onChange={handlePolicyOwnerChange} />   
                  <Text color="red.500">{errors.policyOwnerCity}</Text>         
                </Box>
                <Box>
                  <Text>Policy Owner Phone*</Text>
                  <Input placeholder='phone' w={'180px'} size='xs' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={policyHolder.policyOwnerPhone} onChange={handlePolicyOwnerChange} />            
                  <Text color="red.500">{errors.policyOwnerPhone}</Text>
                </Box>
              </Flex>
                <Button onClick={handleGetQuote} color={buttonColor} backgroundColor={buttonBackgroundColor}>Update Quote</Button>
              </Box>
      </Box>
     ))}
      <BeneficiaryDetailsModal isOpen={isStudentModalOpen} onClose={handleStudentModalClose} onSubmit={handleStudentModalSubmit} />
      <VTCDetailsModal isOpen={isVtcModalOpen} onClose={handleVtcModalClose} onSubmit={handleVtcModalSubmit} />
    </>
  )
}

export default ViewQuote
