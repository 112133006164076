import { toast } from "react-toastify";
import { ADVISOR_GENERATEQUOTE, ADVISOR_LOGIN, ADVISOR_PROFILE, ADVISOR_SIGNUP, CUSTOMER_LOGIN,CUSTOMER_SIGNUP, isLoading,ADVISOR_CALCULATEVTC, ADVISOR_GETQUOTES, ADVISOR_PROFILE_LIST, ADVISOR_SINGLE_QUOTE } from "../../../constants/actionConstants";
import { AdvisorLoginService, AdvisorSignupService, CustomerLoginService, CustomerSignupService} from "./service";

export const advisorSignupList = (params) => async (dispatch) => {
    console.log(params,'params')
    dispatch(isLoading(true));
    let signUpList = await AdvisorSignupService.advisorSignupList(params);
    const {status, message, data} = signUpList

    dispatch({
      type: ADVISOR_SIGNUP,
      payload: message,
      data,
    });

    dispatch(isLoading(false));

    return status
}

export const advisorLoginList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await AdvisorLoginService.advisorLoginList(params);
    const {status, message, data} = loginList

    if(status === 200) {
        dispatch({
            type: ADVISOR_LOGIN,
            payload: data
        })
        toast.success("Advisor Successfully Logged In")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}

// export const advisorProfile = () => async (dispatch) => {
//     dispatch(isLoading(true));
//     let profile = await AdvisorLoginService.advisorProfile();
//     const {status, message, data} = profile

//     dispatch({
//         type: ADVISOR_PROFILE_LIST,
//         payload: message,data
//     })

//     dispatch(isLoading(false));

//     return profile
// }
export const advisorProfile = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdvisorLoginService.advisorProfile(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADVISOR_PROFILE_LIST,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

export const advisorGenerateQuote = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let generateQuote = await AdvisorLoginService.advisorGenerateQuote(params);
    const {status, message, data} = generateQuote

    if(status === 200) {
        dispatch({
            type: ADVISOR_GENERATEQUOTE,
            payload: message,data
        })
        toast.success("Quote Generated Successfully")
    }else {
        toast.success(message)
    }


   

    dispatch(isLoading(false));

    return generateQuote
}

export const advisorGetQuotes = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getQuotes = await AdvisorLoginService.advisorGetQuotes(params);
    const {status, message, data} = getQuotes

    dispatch({
        type: ADVISOR_GETQUOTES,
        payload: message,data
    })

    dispatch(isLoading(false));

    return getQuotes
}

export const advisorSingleGetQuotes = (id, params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getQuotes = await AdvisorLoginService.advisorSingleGetQuotes(id, params); 
    const { status, message, data } = getQuotes;

    dispatch({
        type: ADVISOR_SINGLE_QUOTE,
        payload: { message, data }
    });

    dispatch(isLoading(false));

    return getQuotes;
}

export const advisorCalculateVtc = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let calculateVtc = await AdvisorLoginService.advisorCalculateVtc(params);
    const {status, message, data} = calculateVtc
    console.log(calculateVtc)
    if(status === 200) {
        dispatch({
            type: ADVISOR_CALCULATEVTC,
            payload: message,data
        })
        toast.success("Premium calculated pls check")
    }else {
        toast.error(message)
    }
    dispatch(isLoading(false));
    return calculateVtc
}