import React from "react";
import {Box,Flex,Image,Text,Button} from '@chakra-ui/react'
import { buttonBackgroundColor, buttonColor } from "../../../Utils/UniversalColor";
import Chatbot from "../../../Utils/Chat/Chatbot";
import { Link } from "react-router-dom";
import SideBox from "../../../Utils/SideBox/SideBox";

function CustomerHomePage(){
    return(
        <div style={{background:'#f0f0f0'}}>
            <Chatbot/>
            <SideBox/>
        <Box background={'#f0f0f0'} pt={'120px'} w={'80%'} m={'auto'} pb={'70px'}>
            <Flex justifyContent={'space-around'}>
                <Box background={'white'} w={'30%'}>
                    <Flex justifyContent={'center'} pt={'15px'}>
                        <Image w={'60px'} src="https://img.freepik.com/free-vector/international-passport-3d-vector-illustration-documents-traveling-abroad-cartoon-style-isolated-white-background-summer-tourism-vacation-travel-concept_778687-1721.jpg?t=st=1711104162~exp=1711107762~hmac=e01a7b17d795fc4ca924a7da010ff26390362ff1613a0a8539ff14c5cc53247c&w=740"/>
                    </Flex>
                    <Text textAlign={'center'} pt={'10px'}>
                        Super Visa Insurance
                    </Text>
                    <Box fontSize={'12px'} p={'20px'}>
                    <ul>
                        <li> Min of CAD 100,000 of Sum Insured for min duration of 365 days.</li>
                        <li> No Cancellation Penalty.</li>
                        <li>Age from 15 days to 90 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li>Just 90 days stability condition up to age 74.</li>
                        <li>Monthly Payments Available.</li>
                        <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li>
                    </ul>
                    </Box>
                    <Flex justifyContent={'center'} pb={'20px'}>
                    <Link to='/supervisa-getQuote'>
                    <Button backgroundColor={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    </Link>
                    </Flex>
                </Box>
                <Box background={'white'} w={'30%'}>
                    <Flex justifyContent={'center'} pt={'15px'}>
                        <Image w={'50px'} src="https://img.freepik.com/free-vector/meeting-point-location-pin-design_25030-78591.jpg?t=st=1711019429~exp=1711023029~hmac=a46be01bea14599c1449e4f18a995999f8be3ca97e190f3f135fb53e11f8379e&w=740"/>
                    </Flex>
                    <Text textAlign={'center'} pt={'10px'}>
                        Visitor Insurance
                    </Text>
                    <Box fontSize={'12px'} p={'20px'}>
                    <ul>
                        <li> Min of CAD 100,000 of Sum Insured for min duration of 365 days.</li>
                        <li> No Cancellation Penalty.</li>
                        <li>Age from 15 days to 90 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li>Just 90 days stability condition up to age 74.</li>
                        <li>Monthly Payments Available.</li>
                        <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li>
                    </ul>
                    </Box>
                    <Flex justifyContent={'center'} pb={'20px'}>
                    <Link to='/visitor-getquote'>
                    <Button backgroundColor={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    </Link>
                    </Flex>
                </Box>
                <Box background={'white'} w={'30%'}>
                    <Flex justifyContent={'center'} pt={'15px'}>
                        <Image w={'60px'} src="https://img.freepik.com/free-vector/academic-excellence-illustration_24877-52355.jpg?t=st=1711019454~exp=1711023054~hmac=6c7987360f9c7154270147fc984e9bc4655c5f3312fdda7ccd25235103e6cc2a&w=740"/>
                    </Flex>
                    <Text textAlign={'center'} pt={'10px'}>
                        Student Insurance
                    </Text>
                    <Box fontSize={'12px'} p={'20px'}>
                    <ul>
                        <li> Min of CAD 100,000 of Sum Insured for min duration of 365 days.</li>
                        <li> No Cancellation Penalty.</li>
                        <li>Age from 15 days to 90 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li>Just 90 days stability condition up to age 74.</li>
                        <li>Monthly Payments Available.</li>
                        <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li>
                    </ul>
                    </Box>
                    <Flex justifyContent={'center'} pb={'20px'}>
                    <Link to='/student-travel-getquote'>
                    <Button backgroundColor={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    </Link>
                    </Flex>
                </Box>
            </Flex>
        </Box>
        </div>
    );
};
export default CustomerHomePage;