import React, { useEffect, useState } from 'react';
import { primaryColor } from '../../../Utils/UniversalColor';
import {Flex,Box,Text,Input,Textarea} from '@chakra-ui/react'
import AdvisorHeader from '../../Common/Header/AdvisrHeader';
import CustomerHeader from '../../Common/Header/CustomerHeader';
import { useDispatch, useSelector } from 'react-redux';
import { advisorProfile } from '../Dependencies/action';

const AdvisorProfile = () => {
  const [activeTab, setActiveTab] = useState('personal');
  const [personalData, setPersonalData] = useState();
  const [licenseData, setLicenseData] = useState();
  const [corporationData, setCorporationData] = useState();
  const [referenceData, setReferenceData] = useState();

 



 


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSaveData = (data) => {
    switch (activeTab) {
      case 'personal':
        setPersonalData(data);
        break;
      case 'license':
        setLicenseData(data);
        break;
      case 'corporation':
        setCorporationData(data);
        break;
      case 'reference':
        setReferenceData(data);
        break;
      default:
        break;
    }
  };



  const handleInputChange = (e) => {
   
  };

  const renderForm = () => {
    switch (activeTab) {
      case 'personal':
        return <PersonalDetailsForm onSave={handleSaveData} />;
      case 'license':
        return <LicenseDetailsForm onSave={handleSaveData} />;
      case 'corporation':
        return <CorporationDetailsForm onSave={handleSaveData} />;
      case 'reference':
        return <ReferenceDetailsForm onSave={handleSaveData} />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* <CustomerHeader /> */}
    <div style={{ padding: "90px" }}>
      
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <br/>
          <Flex className="tablist" justifyContent={'space-around'} pb={'50px'}>
            <button style={{boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px",width:"200px",backgroundColor:"#23b5b7",color:'white', padding:'10px 0px 10px 0px', borderRadius:'10px'}} active={activeTab === 'personal'} onClick={() => handleTabClick('personal')}>1. Personal Details</button>
            <button style={{boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px",width:"200px",backgroundColor:"#23b5b7",color:'white', padding:'10px 0px 10px 0px', borderRadius:'10px'}} active={activeTab === 'license'} onClick={() => handleTabClick('license')}>2. License Details</button>
            <button style={{boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px",width:"200px",backgroundColor:"#23b5b7",color:'white', padding:'10px 0px 10px 0px', borderRadius:'10px'}} active={activeTab === 'corporation'} onClick={() => handleTabClick('corporation')}>3. Corporation Details</button>
            <button style={{boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px",width:"200px",backgroundColor:"#23b5b7",color:'white', padding:'10px 0px 10px 0px', borderRadius:'10px'}} active={activeTab === 'reference'} onClick={() => handleTabClick('reference')}>4. Reference Details</button>
          </Flex>
        </div>
      </div>
        <div style={{ flex: 2 }}>
        {/* <h1>Personal Details</h1> */}
          {renderForm()}
          <DisplayData activeTab={activeTab} data={{ personalData, licenseData, corporationData, referenceData }} />
        </div>
    </div>
    </>
  );
};

const Tab = ({ active, onClick, children }) => {
  return (
    <button className={`tab ${active ? 'active' : ''}`} onClick={onClick}>
      {children}
    </button>
  );
};

const DisplayData = ({ activeTab, data }) => {
  switch (activeTab) {
    case 'personal':
      return <pre>{JSON.stringify(data.personalData, null, 2)}</pre>;
    case 'license':
      return <pre>{JSON.stringify(data.licenseData, null, 2)}</pre>;
    case 'corporation':
      return <pre>{JSON.stringify(data.corporationData, null, 2)}</pre>;
    case 'reference':
      return <pre>{JSON.stringify(data.referenceData, null, 2)}</pre>;
    default:
      return null;
  }
};

const PersonalDetailsForm = ({ onSave }) => {
  const dispatch = useDispatch();
  const [profileData,setProfileData]=useState('')
  console.log("YAYY",profileData)

 
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: ''
  });
  useEffect(() => {
    dispatch(advisorProfile())
      .then((res) => {
      
        console.log("Advisor profile data fetched successfully");
        
        
        setProfileData(res);
        setFormData({
          firstName: res?.firstName || '',
          lastName: res?.lastName || '',
          email: res?.email || '',
          phone: res?.phone || '',
          address: res?.address || ''
        });
      })
      .catch((error) => {
        console.error("Error fetching advisor profile data:", error);
        
      });
  }, [dispatch]);

 
  console.log("formdata",formData)

  
  
      const [emailError, setEmailError] = useState('');

      const handleInputChange = (e) => {
        const { name, value } = e.target;
    setFormData({...formData,[name]: value});
      };
    
      const validateEmail = (email) => {
        // Basic email validation
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };
    
      const handleSave = () => {
        if (!validateEmail(formData.email)) {
          setEmailError('Invalid email format');
          return;
        }
        onSave(formData);
      };

  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'80%'} m={'auto'}>
      <Text fontSize={'22px'} style={{color:primaryColor, fontFamily:'poppins',paddingBottom:'10px'}}>Personal Details</Text>
      <hr />
      <Flex mt={'25px'} justifyContent={'space-around'}>
        <Box>
          <Text mb={'8px'}>First Name</Text>
          <Input  backgroundColor={'#f1f1f1'} color={'grey'} value={formData.firstName} onChange={handleInputChange} placeholder='First Name'/>
        </Box>
        <Box>
          <Text mb={'8px'}>Last Name</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} value={formData.lastName}
        onChange={handleInputChange} placeholder='Last Name'/>
        </Box>
        <Box>
          <Text mb={'8px'}>Email</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} value={formData.email}
        onChange={handleInputChange} placeholder='Email'/>
        </Box>
      </Flex>
      <Flex justifyContent={'space-around'} mt={'30px'}>
        <Box>
          <Text mb={'8px'}>Website</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
        <Box>
          <Text mb={'8px'}>Phone</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'}  value={formData.phone}
        onChange={handleInputChange} placeholder='Phone Number'/>
        </Box>
        <Box>
          <Text mb={'8px'}>Fax</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
      </Flex>
      <Flex pl={'40px'} mt={'20px'}>
      <Box>
          <Text mb={'8px'}>Address</Text>
          <Textarea backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
      </Flex>
    </Box>
  );
};


const LicenseDetailsForm = ({ onSave }) => {
  // Your license details form inputs
  const handleSave = (data) => {
    onSave(data);
  };

  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'80%'} m={'auto'}>
     <Text fontSize={'22px'} style={{color:primaryColor, fontFamily:'poppins',paddingBottom:'10px'}}>License Details</Text>
      <hr />
      <Flex mt={'25px'} justifyContent={'space-around'}>
        <Box>
          <Text mb={'8px'}>License No. *</Text>
          <Input  backgroundColor={'#f1f1f1'} color={'grey'} placeholder='Ravi'/>
        </Box>
        <Box>
          <Text mb={'8px'}>License Expiry Date *</Text>
          <Input type='date' backgroundColor={'#f1f1f1'} color={'grey'} placeholder='Sharma'/>
        </Box>
        <Box>
          <Text mb={'8px'}>E&O Policy No. *</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder='ravi@gmail.com'/>
        </Box>
        <Box>
          <Text mb={'8px'}>E&O Policy Exp. Date *</Text>
          <Input type='date' backgroundColor={'#f1f1f1'} color={'grey'} placeholder='ravi@gmail.com'/>
        </Box>
      </Flex>
      <Box mt={'40px'} pl={'20px'}>
        <Text>Available Copy of E&O Insurance *</Text>
        <Text mt={'50px'}>Note* Allowed File types are Image, Pdf, Docx and Doc</Text>
      </Box>
      {/* <button onClick={() => handleSave({ licenseNumber: '12345' })}>Save License Details</button> */}
    </Box>
  );
};

const CorporationDetailsForm = ({ onSave }) => {
  // Your corporation details form inputs
  const handleSave = (data) => {
    onSave(data);
  };

  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'80%'} m={'auto'}>
       <Text fontSize={'22px'} style={{color:primaryColor, fontFamily:'poppins',paddingBottom:'10px'}}>Corporation Details</Text>
      <hr />
      <Flex mt={'25px'} justifyContent={'space-around'}>
        <Box>
          <Text mb={'8px'}>Firm/Corporation/Prop *</Text>
          <Input  backgroundColor={'#f1f1f1'} color={'grey'} placeholder='Ravi'/>
        </Box>
        <Box>
          <Text mb={'8px'}>E&O updated with Corporation name *</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'}  placeholder='Sharma'/>
        </Box>
        <Box>
          <Text mb={'8px'}>Corporation E&O Expiry Date *</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder='ravi@gmail.com'/>
        </Box>
      </Flex>
      <Flex justifyContent={'space-around'} mt={'30px'}>
        <Box>
          <Text mb={'8px'}>Corporation Name *</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
        <Box>
          <Text mb={'8px'}>BIN No of the Corporation *</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder='+91 5254655682'/>
        </Box>
        <Box>
          <Text mb={'8px'}>Date Of Expiry *</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
      </Flex>
      <Box mt={'40px'} pl={'20px'}>
        <Text>Available Copy of E&O Insurance *</Text>
        <Text mt={'50px'}>Note* Allowed File types are Image, Pdf, Docx and Doc</Text>
      </Box>
      {/* <button onClick={() => handleSave({ companyName: 'ABC Corp' })}>Save Corporation Details</button> */}
    </Box>
  );
};

const ReferenceDetailsForm = ({ onSave }) => {
  // Your reference details form inputs
  const handleSave = (data) => {
    onSave(data);
  };

  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'80%'} m={'auto'}>
       <Text fontSize={'22px'} style={{color:primaryColor, fontFamily:'poppins',paddingBottom:'10px'}}>Reference Details</Text>
      <hr />
      <Flex mt={'25px'} justifyContent={'space-around'}>
        <Box>
          <Text mb={'8px'}>Reference Name 1</Text>
          <Input  backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
        <Box>
          <Text mb={'8px'}>Reference Name 2</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'}  placeholder=''/>
        </Box>
        <Box>
          <Text mb={'8px'}>Reference Name 3</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
      </Flex>
      <Flex mt={'25px'} justifyContent={'space-around'}>
        <Box>
          <Text mb={'8px'}>Reference Name 1</Text>
          <Input  backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
        <Box>
          <Text mb={'8px'}>Reference Name 2</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'}  placeholder=''/>
        </Box>
        <Box>
          <Text mb={'8px'}>Reference Name 3</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
      </Flex>
      <Flex mt={'25px'} justifyContent={'space-around'}>
        <Box>
          <Text mb={'8px'}>Reference Name 1</Text>
          <Input  backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
        <Box>
          <Text mb={'8px'}>Reference Name 2</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'}  placeholder=''/>
        </Box>
        <Box>
          <Text mb={'8px'}>Reference Name 3</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
      </Flex>
      <Flex mt={'25px'} justifyContent={'space-around'}>
        <Box>
          <Text mb={'8px'}>Reference Name 1</Text>
          <Input  backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
        <Box>
          <Text mb={'8px'}>Reference Name 2</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'}  placeholder=''/>
        </Box>
        <Box>
          <Text mb={'8px'}>Reference Name 3</Text>
          <Input backgroundColor={'#f1f1f1'} color={'grey'} placeholder=''/>
        </Box>
      </Flex>
      {/* <button onClick={() => handleSave({ referenceName: 'Jane Doe' })}>Save Reference Details</button> */}
    </Box>
  );
};

export default AdvisorProfile;
