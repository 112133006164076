import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Box, Button, Flex, Tooltip } from '@chakra-ui/react';
import CustomInput from '../../../Utils/Input/Index';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { FaRegEdit } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useDispatch } from 'react-redux';
import { customerAll } from './dependencies/action';


const Customers = () => {
  const [colDefs, setColDefs] = useState([  
  { field: "firstName",width:130,filter:true,},
  { field: "lastName",width:100,filter:true},
  {field:"name",width:100,filter:true},
  { field: "email",width:150,filter:true},
  { field: "phone",width:140 },
  { field: "address",width:120 },
  { field: "createdAt",width:100 },]);
  const [rowData, setRowData] = useState([
    { id: 1, firstName: "Dalbir", lastName:" Singh", email: 'dalbir@gmail.com', phone:'879464354691', address: 'Type A', createdAt: '26-01-24' },
    { id: 1, firstName: "Ravi", lastName:"Sharma", email: 'ravi@gmail.com', phone:'1454646465', address: 'Type A', createdAt: '85-25-2024' },
  ]);


  const dispatch=useDispatch()
  const [customerData,setCustomerData]=useState([])

  useEffect(() => {
    dispatch(customerAll())
      .then(res => {
        const { status, data } = res;
        
        setCustomerData(res)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [dispatch]);
  console.log("USERDATA",customerData)



  const pagination = true;
  const paginationPageSizeSelector = [2, 5, 1000];

  const downloadPDF = () => {
    const MyDoc = (
      <Document>
        <Page>
          <Text>Quotations</Text>
        </Page>
      </Document>
    );
  const blob = new Blob([MyDoc], { type: 'application/pdf' });
    // saveAs(blob, 'quotations.pdf');
  };
  
const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet();
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'quotations.xlsx');
    };
  


return (
  <Sidebar headingText={'Customers'}>
        
  <Box w={'90%'} m={'auto'} mt={'70px'}>
  <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
    <Flex justifyContent={'space-between'} mt={'10px'} mb={'25px'}>
        <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
        <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
          <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button></Tooltip>
          <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
          <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button></Tooltip>
        </div>
        <div>
          {/* <label htmlFor="search">Search:</label> */}
            <CustomInput/>
          {/* <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
        </div>
    </Flex>

    <Box>

    <div
          className="ag-theme-quartz"
          style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={customerData}
            columnDefs={colDefs}
            // frameworkComponents={{ actionCellRenderer }}
            domLayout='autoHeight'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>



    {/* <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <colgroup>
                <col style={{ width: '15%'   }} />
                <col style={{ width: '15%'    }} />
                <col style={{ width: '20%'    }} />
                <col style={{ width: '15%'    }} />
                <col style={{ width: '25%'    }} />
                <col style={{ width: '20%'    }} />
            </colgroup>
            <thead>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <th style={{ textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>FirstName</th>
                    <th style={{ textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Last Name</th>
                    <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Email </th>
                    <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Phone</th>
                    <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Address</th>
                    <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Created At</th>
                </tr>
            </thead>
            <tbody>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <td style={{  textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Ravi</td>
                    <td style={{  textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Advisor</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>ravi@upscaller.com	</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>9306454204	</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Completed</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>2024-04-02</td>
                </tr>
            </tbody>
             </table> */}
    </Box>

    </Box>
  </Box>
</Sidebar>
  )
}

export default Customers
