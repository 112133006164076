import React, { useState } from 'react'
import { Box,Flex,FormControl,Input,Button,FormLabel,Image,Text, Heading, color,InputGroup,InputRightElement, useToast } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { advisorSignupList } from '../Dependencies/action';

const AdvisorRegister = () => {
     const toast = useToast();
    const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleClick = () => setShow(!show);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleLogin = async() => {
    // try{
    const { firstName, lastName, email, password, confirmPassword } = values;
    let newErrors = { ...errors };
    if (firstName.length === 0) {
      newErrors.firstName = "Please enter your first name.";
    }
    if (lastName.length === 0) {
      newErrors.lastName = "Please enter your last name.";
    }
    if (email.length === 0 || !validateEmail(email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!validatePassword(password)) {
      newErrors.password = "Password should be at least 6 characters long.";
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    // dispatch(advisorSignupList(values));
    await dispatch(advisorSignupList(values));
  //   toast({
  //     title: "Account created.",
  //   });
  // }catch(error) {
  //    toast({
  //     title: "Invalid Details!",
  //   });
    //  window.location.reload();
  }

  

return (
    <Box m="auto">
    <Flex direction={['column','column','row','row']} pt={'60px'}>
        <Box w={['95%','95%','50%','50%']} p={'70px'} h={'100vh'} display={["none","none","block"]} color={'white'} background={'#272939'} backgroundImage={'https://travelmedicare.com/public/users/images/bg-01.jpg'} backgroundSize={'cover'} backgroundPosition={'center'}>
            {/* <Image src='https://experiortravel.com/public/users/images/bg-02.jpg'/> */}
            </Box>
        <Box p={5} w={['99%','95%','50%','50%']} pt={'40px'} textAlign={'center'}>
          <Flex justifyContent={'center'}>
          {/* <Image w={'130px'} src={'https://travelmedicare.com/public/users/images/logo.png'}/> */}
          </Flex>
          <Text fontFamily={'poppins'} mt={['30px','1px','10px','0px']} pb={['20px','20px','80px','40px']}>Advisor Sign Up</Text>
        <FormControl isRequired w={['70%']} m={'auto'}>
            <Flex justifyContent={'space-between'}>
            <Box w={'48%'}>
                 <FormLabel>First Name</FormLabel>
                 <Input type="text" placeholder='First Name' name='firstName' onChange={handleChange}/>
                 <Text textAlign="start" color="red.500">{errors.firstName}</Text>
            </Box>
            <Box w={'48%'}>
                 <FormLabel>Last Name</FormLabel>
                 <Input type="text" placeholder='Last Name' name='lastName' onChange={handleChange}/>
                 <Text textAlign="start" color="red.500">{errors.lastName}</Text>
            </Box>
            </Flex>
                <FormLabel>Email</FormLabel>
               
                <Input type="email" placeholder='Enter email' name='email' onChange={handleChange}/>
                <Text textAlign="start" color="red.500">{errors.email}</Text>
                <FormLabel pt={'10px'}>Password</FormLabel>
                
               <InputGroup size="md">
                    <Input
                      pr="4.5rem"
                      type={show ? "text" : "password"}
                      placeholder="Enter password"
                      name="password"
                      onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                      </Button>
                  </InputRightElement>
              </InputGroup>
              <Text textAlign="start" color="red.500">{errors.password}</Text>
              <FormLabel>Confirm Password</FormLabel>
              <Input type="password" placeholder='Confirm Password' name='confirmPassword' onChange={handleChange} />
              <Text textAlign="start" color="red.500">{errors.confirmPassword}</Text>
              <Button mt={4} width="100%" onClick={handleLogin} color={'#2acb55'} backgroundColor={'#e3feff'}>Sign up</Button>
          </FormControl>
          <Text mt={'35px'}>Already have an account</Text>
          <Link to={'/advisorLogin'} color='blue'>
              <Text>Login</Text>
          </Link>
      </Box>
  </Flex>
</Box>
  )
}

export default AdvisorRegister;
