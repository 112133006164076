import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import SideBox from "../../Utils/SideBox/SideBox";

const StudentTravel = () => {
  return (
    <>
    <SideBox/>
    <Box style={{ backgroundColor: "rgb(8, 129, 131)", paddingTop: "80px" }}>
      <Box
        style={{
          width: "70%",
          padding: "0px 10px 15px 10px",
          margin: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Flex
          style={{
            gap: "10px",
            justifyContent: "space-between",
            fontFamily: "poppins",
          }}
        >
          <Link to="/student-travel-getquote">
            <Box
              style={{
                backgroundColor: "white",
                border: "2px solid #FBE39B",
                color: "rgb(8, 129, 131)",
                fontWeight: 600,
                borderTopRadius: "10px",
                padding: "5px 10px",
                borderRadius: "50px",
                fontSize:'12px'
              }}
            >
              Get Quote
            </Box>
          </Link>
          <Link to="/student-travel-plan-compare">
            <Box
              style={{
                backgroundColor: "white",
                border: "2px solid #FBE39B",
                color: "rgb(8, 129, 131)",
                fontWeight: 600,
                borderTopRadius: "10px",
                padding: "5px 10px",
                borderRadius: "50px",
                fontSize:'12px'
              }}
            >
              Plan Comparison
            </Box>
          </Link>
          <Link to="/student-travel-policy-wording">
            <Box
              style={{
                backgroundColor: "white",
                border: "2px solid #FBE39B",
                color: "rgb(8, 129, 131)",
                fontWeight: 600,
                borderTopRadius: "10px",
                padding: "5px 10px",
                borderRadius: "50px",
                fontSize:'12px'
              }}
            >
              Policy Wording
            </Box>
          </Link>
          <Link to="/student-travel-claim-procedure">
            <Box
              style={{
                backgroundColor: "white",
                border: "2px solid #FBE39B",
                color: "rgb(8, 129, 131)",
                fontWeight: 600,
                borderTopRadius: "10px",
                padding: "5px 10px",
                borderRadius: "50px",
                fontSize:'12px'
              }}
            >
              Claim Procedure
            </Box>
          </Link>
          <Link to='/student-travel-contactus'>
          <Box
            style={{
              backgroundColor: "white",
              border: "2px solid #FBE39B",
              color: "rgb(8, 129, 131)",
              fontWeight: 600,
              borderTopRadius: "10px",
              padding: "5px 10px",
              borderRadius: "50px",
              fontSize:'12px'
            }}
            >
            Contact us
          </Box>
            </Link>
        </Flex>
      </Box>
    </Box>
    </>
  );
};

export default StudentTravel;
