import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import Loader from '../src/Components/Common/Loader/loadingAnimation'
import AppRoutes from "./routes";
import { I18nextProvider } from 'react-i18next';
import i18n from './Components/i18n'; // Import your i18n configuration
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ChakraProvider>
      <I18nextProvider i18n={i18n}>
        <Loader />
        <ToastContainer position="bottom-center" autoClose={5000} />
        <AppRoutes />
      </I18nextProvider>
    </ChakraProvider>
  );
}

export default App;
