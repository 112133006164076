import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Text,Flex, Box } from '@chakra-ui/react';

export const getMaxSelectableDate = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
};




export const BeneficiaryDetailsModal = ({ isOpen, onClose, onSubmit }) => {
  const handleSubmit = () => {
    onSubmit();
    onClose(); 
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered  zIndex={99999999} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={'14px'} fontFamily={'poppins'}>To be eligible for coverage you, on the effective date, must be:</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>1. s of age; and</Text>
          <Text> 2. ineligible for benefits under a government health insurance plan; and</Text>
          <Text> 3. residing in Canada on a temporary basis; and</Text>
          <Text> 4. one of the following:</Text>
          <></>
          <ul style={{fontSize:'14px',fontFamily:'poppins',paddingLeft:'20px',lineHeight:'22px'}}>
            <li> a student with proof of full-time admission in a recognized Canadian institution of learning; or</li>
            <li> a student completing post doctorate research in a recognized Canadian institution of learning; or</li>
            <li> the spouse or dependent child of the insured student and residing with them on a full-time basis; or</li>
            <li>the parent, legal guardian, teacher or chaperone of the insured student.
                </li>
          </ul>            
          <Text fontWeight={'600'} fontFamily={'poppins'} fontSize={'14px'} pt={'8px'}> I confirm that all travellers are eligible to purchase this policy</Text>
        </ModalBody>
        <ModalFooter>
          <Flex gap="50px" justifyContent='center'>
          <Button colorScheme="blue" onClick={handleSubmit}>Yes</Button>
          <Button mr={3} onClick={onClose}>No</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};


export const VTCDetailsModal = ({ isOpen, onClose, onSubmit }) => {
    const handleSubmit = () => {
      onSubmit();
      onClose();
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered zIndex={99999999} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'14px'} fontFamily={'poppins'}>To be eligible for coverage you, on the effective date, must be:</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box fontFamily={'Open Sans,sans-serif'} fontSize={'14px'}>
            <Text pt={'5px'}>1. Be a visitor to Canada or a person in Canada under a valid work or student visa, a Canadian or an immigrant not eligible for benefits under a government health insurance plan; and</Text>
            <Text pt={'5px'}>2. be at least 15 days of age and less than 90 years of age (less than 70 years of age for Premium plan); and</Text>
            <Text pt={'5px'}> 3. not be travelling against the advice of a physician and/or not have been diagnosed with a terminal illness; and</Text>
            <Text pt={'5px'}>4. not be experiencing new or undiagnosed signs or symptoms and/or know of any reason to seek medical attention; and</Text>
            <Text pt={'5px'}>5. not require assistance with the activities of daily living(dressing, bathing, eating, using the toilet or getting in or out of a bed or chair).</Text>
            <Text pt={'9px'} fontWeight={'600'}>I confirm that all travellers are eligible to purchase this policy</Text>   
            </Box>                    
          </ModalBody>
          <ModalFooter>
            <Flex gap="50px" justifyContent='center'>
              <Button colorScheme="blue" onClick={handleSubmit}>Yes</Button>
              <Button mr={3} onClick={onClose}>No</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  