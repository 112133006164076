import {
  SET_LOGGED_IN,
  SET_USER,
  isLoading,
} from "../constants/actionConstants";
import { storage } from "./store/storage";

export const setLogin = (params) => async (dispatch) => {
  const user = storage.getUser();
  dispatch({
    type: SET_USER,
    payload: user,
  });
  if(user && user.token) {
    dispatch({
      type: SET_LOGGED_IN,
      payload: true,
    });
  }
  
  
};