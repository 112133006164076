import { ADMIN_USERS_LIST, AGA_MGA_ALL } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const AgaMgaAllService = {

    agaMgaAll() {
        return Api.GET(AGA_MGA_ALL).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },


}