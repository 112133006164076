import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { MdDoubleArrow } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { storage } from '../../../dependencies/store/storage';
import { useSelector } from 'react-redux';




function Footer() {
  const role = storage.getUserRole();
  const { user } = useSelector((state) => state.user)
  const [isAdmin, setIsAdmin] = useState(false);
  const { t } = useTranslation()

  // const location = useLocation();
  // let path = location.pathname.split("/");
  // path = path && path[1];

  // useEffect(() => {
  //   if (path === "admin") {
  //     setIsAdmin(true);
  //   }
  // }, [path]);


  return (
    <>
      {!role && (
        <Box backgroundColor={'#064d98'} pt={'30px'} pb={'15px'} color={'white'}>
          <Flex w={'85%'} m={'auto'} pb={'30px'}>
            <Box w={'30%'} m={'auto'} fontSize={'14px'} borderRight={'1px solid red'}>
              <Text fontFamily={'Assistant,sans-serif'} fontSize={'16px'}>{t('Contact')} </Text>
              <Box>
                <Flex p={'7px'} fontSize={'14px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <FaLocationDot />
                  </Flex>
                  <Text alignSelf={'center'}>7895 Tranmere Dr Unit #16 Suite 3 Mississauga ON L5S 1V9</Text>
                </Flex>
                <Flex p={'7px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <FaPhoneAlt />
                  </Flex>
                  <Text alignSelf={'center'}>1844-844-3272</Text>
                </Flex>
                <Flex p={'7px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <MdOutlineMail />
                  </Flex>
                  <Text alignSelf={'center'}>info@travelmedicare.com</Text>
                </Flex>
              </Box>
            </Box>

            <Box w={'20%'} m={'auto'} borderRight={'1px solid red'}>
              <Text fontFamily={'Assistant,sans-serif'}>Discover TM</Text>
              <Box fontSize={'14px'}>
                <Flex p={'7px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <MdDoubleArrow />
                  </Flex>
                  <Link to='/about-us'>
                    <Text alignSelf={'center'}>About Us</Text>
                  </Link>
                </Flex>
                <Flex p={'7px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <MdDoubleArrow />
                  </Flex>
                  <Link to='/enquiry'>
                    <Text alignSelf={'center'}>Enquiry</Text>
                  </Link>
                </Flex>
                <Flex p={'7px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <MdDoubleArrow />
                  </Flex>
                  <Link to='/privacy_policy'>
                    <Text alignSelf={'center'}>Privacy Policy</Text>
                  </Link>
                </Flex>
                <Flex p={'7px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <MdDoubleArrow />
                  </Flex>
                  <Link to='/terms_condition'>
                    <Text alignSelf={'center'}>Terms & Condition</Text>
                  </Link>
                </Flex>
              </Box>
            </Box>

            <Box w={'20%'} m={'auto'} borderRight={'1px solid red'}>
              <Text fontFamily={'Assistant,sans-serif'}>Other</Text>
              <Box fontSize={'14px'}>
                <Flex p={'7px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <MdDoubleArrow />
                  </Flex>
                  <Text alignSelf={'center'}>News & Blogs</Text>
                </Flex>
                <Flex p={'7px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <MdDoubleArrow />
                  </Flex>
                  <Text alignSelf={'center'}>Claims & Refund</Text>
                </Flex>
                <Flex p={'7px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <MdDoubleArrow />
                  </Flex>
                  <Text alignSelf={'center'}>Become a Partner</Text>
                </Flex>
                <Flex p={'7px'} gap={'15px'}>
                  <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                    <MdDoubleArrow />
                  </Flex>
                  <Text alignSelf={'center'}>Affiliate Program</Text>
                </Flex>
              </Box>
            </Box>


            <Box w={'20%'} m={'auto'}>
              <Text fontFamily={'Assistant,sans-serif'} pb={'10px'}>Social Media</Text>
              <Flex justifyContent={'space-around'} w={'70%'} fontSize={'22px'} pb={'15px'}>

                <FaFacebook />
                <a href="https://www.instagram.com/travelmedicare/" target='_blank'>
                  <FaInstagramSquare />
                </a>
                <FaTwitter />
                <FaLinkedin />
              </Flex>
              <Flex>
                <Image h={'50%'} src='https://verify.authorize.net/anetseal/images/secure90x72.gif' />
              </Flex>
            </Box>


          </Flex>

          <Box borderTop={'1px solid white'}>
            <Text fontSize={'14px'} pt={'5px'} pl={'20px'} fontFamily={'Assistant'}>Copyright  2019 Travel medicare. All Rights Reserved</Text>
          </Box>
        </Box>

      )}
    </>
  )
}

export default Footer