export const API_URL = () => process.env.REACT_APP_API_BASE;

export const SENT_LOGIN_OTP = `${API_URL()}/api/client-login`
export const LOGIN_OTP_VERIFY = `${API_URL()}/api/client-login-verify`
export const SERVICE_LIST = `${API_URL()}/service-url`
export const CUSTOMER_SIGNUP=`${API_URL()}/users/createuser`
export const CUSTOMER_LOGIN = `${API_URL()}/api/v1/login`;
export const CUSTOMER_PROFILE = `${API_URL()}/users/getUser`;
export const ADVISOR_PROFILE = `${API_URL()}/users/getUser`;
export const ADVISOR_CALCULATE_VTC = `${API_URL()}/Quote/calculatePremium`;
export const ADVISOR_GENERATE_QUOTE = `${API_URL()}/Quote/generateQuote`;
export const ADVISOR_GET_QUOTES = `${API_URL()}/Quote/getListOfQuotationBeanByUser`;
export const CUSTOMER_EDIT_PROFILE = `${API_URL()}/api/v1/users/updateCustomer`;
export const ADVISOR_SIGNUP = `${API_URL()}/users/createAdvisor`;
export const ADVISOR_LOGIN = `${API_URL()}/api/v1/advisorlogin`;
export const SUPERVISA_QUICKQUOTE = `${API_URL()}/quickQuoteOfSuperVisa`;
export const VISITORVISA_QUICKQUOTE = `${API_URL()}/quickQuote`;
export const STUDENTVISA_QUICKQUOTE = `${API_URL()}/quickQuoteOfStudent`;
export const CUSTOMERGETQUOTE=`${API_URL()}/Quote/getListOfQuotationBeanByUser`;
export const SUPERADMIN_LOGIN=`${API_URL()}/api/v1/superAdminLogin`;
export const ADMIN_CREATE=`${API_URL()}/users/createAdminUser`;
export const MENU_LIST=`${API_URL()}/menu/getListOfMenuName`;
export const ADMIN_LOGIN=`${API_URL()}/api/v1/adminLogin`;
export const ADVISOR_SINGLE_QUOTE=`${API_URL()}/Quote/getQuoteByQuotationNo`;
export const CUSTOMER_SINGLE_QUOTE=`${API_URL()}/Quote/getQuoteByQuotationNo`;
export const ALL_PAGES_CONTENT_DETAILS=`${API_URL()}/pages/getListOfPages`;
export const ADMIN_PAGES_GET_BY_ID=`${API_URL()}/pages/getPageById`;
export const ADMIN_PAGES_EDIT_BYID=`${API_URL()}/pages/updatePage`;
export const ADMIN_USERS_LIST=`${API_URL()}/users/getListofAdmin`;
export const AGA_MGA_ALL=`${API_URL()}/users/getListofAdvisors`;
export const CUSTOMER_LIST=`${API_URL()}/users/getListofCustomer`;





// export const SENT_LOGIN_OTP = `${API_URL()}/api/client-login`
// export const LOGIN_OTP_VERIFY = `${API_URL()}/api/client-login-verify`
// export const SERVICE_LIST = `${API_URL()}/service-url`