// RadioOption.js
import React from 'react';
import { Box } from '@chakra-ui/react';

const RadioOption = ({ value, isSelected, onChange, children }) => {
  const handleChange = () => {
    onChange(value);
    console.log("Option clicked:", value);
  };

  return (
    <Box
      as='label'
      cursor='pointer'
      borderWidth='1px'
      borderRadius='md'
      boxShadow='md'
      fontSize='13px'
      bg={isSelected ? 'teal.600' : 'white'}
      color={isSelected ? 'white' : 'gray.700'}
      borderColor={isSelected ? 'teal.600' : 'gray.300'}
      _hover={{
        bg: 'gray.100',
      }}
      onClick={handleChange}
      px={5}
      py={0}
    >
      {children}
    </Box>
  );
};

export default RadioOption;
