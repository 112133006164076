import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import {Flex, Box,Select, Button} from '@chakra-ui/react'
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaDownload } from "react-icons/fa6";
import './style.css'
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Sidebar';



const MonthlyPolicies = () => {
    const pagination = true;
    const paginationPageSizeSelector = [2, 5, 1000];
    const navigate=useNavigate()

    const downloadCellRenderer = (params) => {
     const handleDownload = () => {
       navigate('/admin/download-documents')
       console.log('Download clicked for row:', params.data);
     };
   return(
     <div style={{ display: 'flex', justifyContent: 'center',marginTop:'10px'}}>
     <FaDownload color='grey' onClick={handleDownload} style={{ cursor: 'pointer' }} />
   </div>
   )
 };
 
   const viewTranscations = (params)=>{
     const handleView = () => {
       navigate(`/admin/viewPolicyPaymentTranscation/${params.data.policyNo}`)
       console.log('Download clicked for row:', params.data);
     };
     const handleDeclineView = ()=>{
       navigate(`/admin/viewDeclinedTranscationList/${params.data.policyNo}`)
     }
   return(
     <div className='view'>
       <Button onClick={handleView} _hover={{color:'white'}} mb={'3px'} background={'#4caf50'} color={'white'} size={'xs'}>View</Button>
       <Button onClick={handleDeclineView} _hover={{color:'white'}} background={'red'} color={'white'} size={'xs'}>Decline</Button>
   </div>
   )
   }
 
   const handleAction = (params)=>{
     const handleActions = () => {
       console.log('Download clicked for row:', params.data);
     };
   return(
     <div style={{ display: 'flex', justifyContent: 'center',direction:'column',marginTop:'5px'}}>
       <Button background={'#9c27b0'} color={'white'} size={'xs'}>View</Button>
   </div>
   )
   }

   const handleEndorsement = (params)=>{
     const handleActions = () => {
       navigate(`/admin/policyEndorsements/${params.data.policyNo}`)
       console.log('Download clicked for row:', params.data);
     };
   return(
     <div style={{ display: 'flex', justifyContent: 'center',direction:'column',marginTop:'5px'}}>
       <Button onClick={handleActions} background={'#00bcd4'} color={'white'} size={'xs'}>View</Button>
   </div>
   )
   }

    const [colDefs, setColDefs] = useState([
     { field: "employeeName",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:280},
     { field: "designation", wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true },
     { field: "policyNo",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:330},
     { field: "dateOfIssue",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:220 },
     { field: "effectiveDate",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:220 },
     { field: "expireDate",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:220 },
     { field: "customerName",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:300},
     { field: "policyAmount",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
     { field: "status",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
     { field: "endorsements",wrapHeaderText: true,headerClass:'header',width:175,cellRenderer:handleEndorsement,cellClass: 'cell-class-endorsement',autoHeight:true, },
     { field: "download",headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,cellRenderer: downloadCellRenderer,width:140,wrapHeaderText: true},
     { field: "paymentTranscations",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellRenderer:viewTranscations,autoHeight:true},
     { field: "Action",headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,cellRenderer:handleAction},
   ]);

     const [rowData, setRowData] = useState([
       {
         id: 19,
         employeeName: "Dalbir Singh",
         designation: "MGA",
         product: "STC",
         policyNo: "2021PTM82264",
         dateOfIssue: "06-Oct-2021",
         customerName: "Abdel Raouf",
         customerEmail: "abdo106@outlook.com",
         customerPhno: "4372306486",
         tripType: "Single Trip",
         policyAmount: "$526",
         status: "Matured",
         docDownload: "View",
         endorsements: "View  Declined List",
         paymentTransactions: "View"
       },
       {
         id: 32,
         employeeName: "Dalbir Singh",
         designation: "MGA",
         product: "VTC",
         policyNo: "2022PTM821128",
         dateOfIssue: "09-Dec-2022",
         customerName: "Madan Lal",
         customerEmail: "ajay.madhar306@gmail.com",
         customerPhno: "4375591000",
         tripType: "Super Visa",
         policyAmount: "$1584",
         status: "Active",
         docDownload: "ViewRequest",
         endorsements: "View  Declined List",
         paymentTransactions: "View"
       },
       {
         id: 27,
         employeeName: "Dattatrya Mankari",
         designation: "Advisor",
         product: "VTC",
         policyNo: "2022PTM842104",
         dateOfIssue: "03-Jun-2022",
         customerName: "Ved Parkash Mangla",
         customerEmail: "amish.mangla@gmail.com",
         customerPhno: "4167291671",
         tripType: "Single Trip",
         policyAmount: "$1051",
         status: "Matured",
         docDownload: "View",
         endorsements: "View  Declined List",
         paymentTransactions: "View"
       },
       {
         id: 14,
         employeeName: "-",
         designation: "-",
         product: "STC",
         policyNo: "2021PTM6257",
         dateOfIssue: "31-Aug-2021",
         customerName: "Aniket Sawant",
         customerEmail: "aniket.sawant1095@gmail.com",
         customerPhno: "2265030450",
         tripType: "Single Trip",
         policyAmount: "$90",
         status: "Matured",
         docDownload: "View",
         endorsements: "View  Declined List",
         paymentTransactions: "View"
       },
       {
         id: 33,
         employeeName: "-",
         designation: "-",
         product: "VTC",
         policyNo: "2023PTM245132",
         dateOfIssue: "27-Jan-2023",
         customerName: "Aniket Sawant",
         customerEmail: "aniket.sawant1095@gmail.com",
         customerPhno: "2265030450",
         tripType: "Single Trip",
         policyAmount: "$147",
         status: "Matured",
         docDownload: "View",
         endorsements: "View  Declined List",
         paymentTransactions: "View"
       },
       {
         id: 31,
         employeeName: "-",
         designation: "-",
         product: "VTC",
         policyNo: "2022PTM345120",
         dateOfIssue: "07-Aug-2022",
         customerName: "Aniket Sawant",
         customerEmail: "aniket.sawant1095@gmail.com",
         customerPhno: "2265030450",
         tripType: "Single Trip",
         policyAmount: "$87",
         status: "Matured",
         docDownload: "View",
         endorsements: "View  Declined List",
         paymentTransactions: "View"
       },
       {
         id: 39,
         employeeName: "-",
         designation: "-",
         product: "VTC",
         policyNo: "2023PTM989159",
         dateOfIssue: "30-Jul-2023",
         customerName: "Aniket Sawant",
         customerEmail: "aniket.sawant1095@gmail.com",
         customerPhno: "2265030450",
         tripType: "Single Trip",
         policyAmount: "$167",
         status: "Matured",
         docDownload: "View",
         endorsements: "View  Declined List",
         paymentTransactions: "View"
       },
       {
         id: 23,
         employeeName: "-",
         designation: "-",
         product: "VTC",
         policyNo: "2022PTM61787",
         dateOfIssue: "16-Apr-2022",
         customerName: "Aniket Sawant",
         customerEmail: "aniket.sawant1095@gmail.com",
         customerPhno: "2265030450",
         tripType: "Single Trip",
         policyAmount: "$122",
         status: "Matured",
         docDownload: "View",
         endorsements: "View  Declined List",
         paymentTransactions: "View"
       },
       {
         id: 20,
         employeeName: "-",
         designation: "-",
         product: "VTC",
         policyNo: "2022PTM21380",
         dateOfIssue: "21-Feb-2022",
         customerName: "Aniket Anil Sawant",
         customerEmail: "aniket.sawant1095@gmail.com",
         customerPhno: "2265030450",
         tripType: "Single Trip",
         policyAmount: "$64",
         status: "Matured",
         docDownload: "View",
         endorsements: "View  Declined List",
         paymentTransactions: "View"
       },
       {
         id: 63,
         employeeName: "-",
         designation: "-",
         product: "VTC",
         policyNo: "2024PTM351215",
         dateOfIssue: "24-Jan-2024",
         customerName: "Aniket Sawant",
         customerEmail: "aniket.sawant1095@gmail.com",
         customerPhno: "2265030450",
         tripType: "Single Trip",
         policyAmount: "$155",
         status: "Matured",
         docDownload: "View",
         endorsements: "View  Declined List",
         paymentTransactions: "View"
       }
     ]
     );

   
const downloadPDF = () => {
const MyDoc = (
   <Document>
   <Page>
       <Text>Quotations</Text>
   </Page>
   </Document>
);

const blob = new Blob([MyDoc], { type: 'application/pdf' });
saveAs(blob, 'quotations.pdf');
};

const downloadExcel = () => {
   const worksheet = XLSX.utils.json_to_sheet(rowData);
   const workbook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
   XLSX.writeFile(workbook, 'quotations.xlsx');
};

const downloadCSV = () => {
   const csvContent = [
   Object.keys(rowData[0]).join(','), // Header row
   ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
   ].join('\n');
   
   const blob = new Blob([csvContent], { type: 'text/csv' });
   const url = window.URL.createObjectURL(blob);
   const link = document.createElement('a');
   link.href = url;
   link.setAttribute('download', 'quotations.csv');
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
};


return (
    <Sidebar headingText={'Monthly Policies'}>
      <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
        <Flex>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color:'#999999' }}>Select Product</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
              <option value="">All Products</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color:'#999999' }}>Select Role</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
              <option value="">Select Roles</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color:'#999999' }}>Select User</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
              <option value="">Select Users</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
        </Flex>
        <br />
        </div>

        <Box w={'95%'} m={'auto'} background={'white'} p={'10px'}>
        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" />
          </div>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
           <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            width='100%'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
        </Box>
    </Sidebar>
  )
}

export default MonthlyPolicies
