import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Image, Select, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";
import { useSelector } from "react-redux";
import HeaderDropdown from "../../../Utils/Dropdown-hover/HoverButton";
import {
  buttonBackgroundColor,
  buttonColor,
} from "../../../Utils/UniversalColor";
import { MdCall } from "react-icons/md";

// window.googleTranslateElementInit = () => {
//   new window.google.translate.TranslateElement(
//       {
//           pageLanguage: 'en',
//           includedLanguages: 'en,es,fr,hi,pa',
//           layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
//           autoDisplay: false
//       },
//       'google_translate_element'
//   );
// };

function CustomerHeader() {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const role = storage.getUserRole();
  const { user } = useSelector((state) => state.user);
  // const [isAdmin, setIsAdmin] = useState(false);

  // const location = useLocation();
  // let path = location.pathname.split("/");
  // path = path && path[1];

  // useEffect(() => {
  //   if (path === "admin") {
  //     setIsAdmin(true);
  //   }
  // }, [path]);
  // Function to handle language change

  // const handleLanguageChange = (event) => {
  //   const language = event.target.value;
  //   setSelectedLanguage(language);
  //   i18n.changeLanguage(language);
  // };

  //   useEffect(() => {
  //     // Initialize Google Translate widget
  //     const script = document.createElement('script');
  //     script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //     script.async = true;
  //     document.body.appendChild(script);

  //     return () => {
  //         document.body.removeChild(script);
  //     };
  // }, []);

  // Function to initialize Google Translate widget
  // window.googleTranslateElementInit = () => {
  //     new window.google.translate.TranslateElement(
  //         {
  //             pageLanguage: 'en', // Default language
  //             includedLanguages: 'en,es,fr,hi,pa', // List of languages to include in the dropdown
  //             layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
  //             autoDisplay: false
  //         },
  //         'google_translate_element'
  //     );
  // };

  return (
    <Box>
      {!role && (
        <Flex
          zIndex={"9999999"}
          background={"white"}
          position={"fixed"}
          w={"100%"}
          p={"10px"}
          justifyContent={"space-between"}
          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
        >
          <Box w={"18%"}>
            <Link to={"/"}>
              <Image
                w={"70%"}
                src="https://travelmedicare.com/public/users/images/logo.png"
              />
            </Link>
          </Box>
          <Flex
            gap={"40px"}
            fontWeight={"600"}
            fontSize={"16px"}
            fontFamily={"poppins"}
            justifyContent={"center"}
            mt={"6px"}
          >
            <Link to="/">
              <Box>{t("Home")}</Box>
            </Link>
            <Link to={"/enquiry"}>
              <Box>{t("Contact Us")}</Box>
            </Link>
          </Flex>
          <Flex gap={"40px"} alignItems={"center"}>
            <Link to={"/advisorLogin"}>
              <Button
                fontSize={"14px"}
                color={buttonColor}
                backgroundColor={buttonBackgroundColor}
              >
                {t("Advisor")}
              </Button>
            </Link>
            <Link to={"/customerLogin"}>
              <Button
                fontSize={"14px"}
                color={buttonColor}
                backgroundColor={buttonBackgroundColor}
              >
                {t("Customer Login")}
              </Button>
            </Link>
          </Flex>
        </Flex>
      )}

      {role === ROLES.CUSTOMER && (
        <>
          <Flex
            zIndex={"9999999"}
            background={"white"}
            position={"fixed"}
            w={"100%"}
            p={"10px"}
            justifyContent={"space-between"}
            boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
          >
            <Box w={"18%"}>
              <Link to={"/"}>
                <Image
                  w={"70%"}
                  src="https://travelmedicare.com/public/users/images/logo.png"
                />
              </Link>
            </Box>
            <Flex
              w={"80%"}
              gap={"40px"}
              fontWeight={"600"}
              fontSize={"14px"}
              fontFamily={"poppins"}
              justifyContent={"center"}
              mt={"8px"}
            >
              <Link to="/customer-dashboard">
                <Box>{t("Get Quote")}</Box>
              </Link>
              <Link to={"/my-quotes"}>
                <Box>{t("My Quote")}</Box>
              </Link>
              <Link to={"/customer-policies"}>
                <Box>{t("My Policy")}</Box>
              </Link>
              <Link to={"/customer-profile"}>
                <Box>{t("My Account")}</Box>
              </Link>
            </Flex>
            <Flex w={"20%"} gap={"10px"} alignItems={"center"}>
              <MdCall />
              <Text fontWeight={"600"} fontFamily={"poppins"}>
                1844-844-3272
              </Text>
            </Flex>
          </Flex>
        </>
      )}

      {role === ROLES.ADVISOR && (
        <>
          <Flex
            zIndex={"9999999"}
            background={"white"}
            position={"fixed"}
            w={"100%"}
            p={"10px"}
            justifyContent={"space-between"}
            boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
          >
            <Box w={"18%"}>
              <Link to={"/"}>
                <Image
                  w={"70%"}
                  src="https://travelmedicare.com/public/users/images/logo.png"
                />
              </Link>
            </Box>
            <Flex
              w={"80%"}
              gap={"40px"}
              fontWeight={"600"}
              fontSize={"14px"}
              fontFamily={"poppins"}
              justifyContent={"center"}
              mt={"8px"}
            >
              <Link to="/advisor-generate-quote">
                <Box>{t("Generate Quote")}</Box>
              </Link>
              <Link to={"/advisor-profile"}>
                <Box>{t("My Account")}</Box>
              </Link>
              <HeaderDropdown />
            </Flex>
            <Flex w={"20%"} gap={"10px"} alignItems={"center"}>
              <MdCall />
              <Text fontWeight={"600"} fontFamily={"poppins"}>
                1844-844-3272
              </Text>
            </Flex>
          </Flex>
        </>
      )}

      {/* {role && !isAdmin && (
        <>
         <Flex
          zIndex={"9999999"}
          background={"white"}
          position={"fixed"}
          w={"100%"}
          p={"10px"}
          justifyContent={"space-between"}
          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
        >
          <Box w={"18%"}>
            <Link to={"/"}>
              <Image
                w={"70%"}
                src="https://travelmedicare.com/public/users/images/logo.png"
              />
            </Link>
          </Box>
          <Flex
            gap={"40px"}
            fontWeight={"600"}
            fontSize={"16px"}
            fontFamily={"poppins"}
            justifyContent={"center"}
            mt={"6px"}
          >
            <Link to="/">
              <Box>{t("Home")}</Box>
            </Link>
            <Link to={"/enquiry"}>
              <Box>{t("Contact Us")}</Box>
            </Link>
          </Flex>
          <Flex gap={"40px"} alignItems={"center"}>
            <Link to={"/advisorLogin"}>
              <Button
                fontSize={"14px"}
                color={buttonColor}
                backgroundColor={buttonBackgroundColor}
              >
                {t("Advisor")}
              </Button>
            </Link>
            <Link to={"/customerLogin"}>
              <Button
                fontSize={"14px"}
                color={buttonColor}
                backgroundColor={buttonBackgroundColor}
              >
                {t("Customer Login")}
              </Button>
            </Link>
          </Flex>
        </Flex>
          <Flex w={"20%"} gap={"10px"} alignItems={"center"}>
            <MdCall />
            <Text fontWeight={"600"} fontFamily={"poppins"}>
              1844-844-3272
            </Text>
          </Flex>
        </>
      )} */}

      {/* 
      <Flex
        zIndex={"9999999"}
        background={"white"}
        position={"fixed"}
        w={"100%"}
        p={"10px"}
        justifyContent={"space-between"}
        boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
      >
        <Box w={"18%"}>
          <Link to={"/"}>
            <Image
              w={"70%"}
              src="https://travelmedicare.com/public/users/images/logo.png"
            />
          </Link>
        </Box>

        {!role && (
          <>        
          <Flex gap={'40px'} fontWeight={'600'} fontSize={'16px'} fontFamily={'poppins'} justifyContent={'center'} mt={'6px'}>
          <Link to='/'>
            <Box>{t('Home')}</Box>
          </Link>
          <Link to={'/enquiry'}>
            <Box>{t('Contact Us')}</Box>
          </Link>
            </Flex>
            <Flex gap={'40px'} alignItems={'center'}>
            <Link to={'/advisorLogin'}>
              <Button fontSize={'14px'} color={buttonColor} backgroundColor={buttonBackgroundColor}>{t('Advisor')}</Button>
            </Link>
            <Link to={'/customerLogin'}>
              <Button fontSize={'14px'} color={buttonColor} backgroundColor={buttonBackgroundColor}>{t('Customer Login')}</Button>
            </Link>
          </Flex>
          </>
        )}

        {role === ROLES.CUSTOMER && (
          <>
        <Flex
          w={"80%"}
          gap={"40px"}
          fontWeight={"600"}
          fontSize={"14px"}
          fontFamily={"poppins"}
          justifyContent={"center"}
          mt={"8px"}
        >
          <Link to="/customer-dashboard">
            <Box>{t("Get Quote")}</Box>
          </Link>
            <Link to={"/my-quotes"}>
              <Box>{t("My Quote")}</Box>
            </Link>
          <Link to={"/customer-policies"}>
            <Box>{t("My Policy")}</Box>
          </Link>
          <Link to={"/customer-profile"}>
            <Box>{t("My Account")}</Box>
          </Link>
        </Flex>
        <Flex w={'20%'} gap={'10px'} alignItems={'center'}>
        <MdCall />
          <Text fontWeight={'600'} fontFamily={'poppins'}>1844-844-3272</Text>
        </Flex>
          </> 
          )}

        {role === ROLES.ADVISOR && (
          <>
                <Flex
                  w={"80%"}
                  gap={"40px"}
                  fontWeight={"600"}
                  fontSize={"14px"}
                  fontFamily={"poppins"}
                  justifyContent={"center"}
                  mt={"8px"}
                >
                  <Link to='/advisor-generate-quote'>
                    <Box>{t('Generate Quote')}</Box>
                  </Link>
                  <Link to={'/advisor-profile'}>
                    <Box>{t('My Account')}</Box>
                  </Link>
                  <HeaderDropdown />
                </Flex>
                <Flex w={'20%'} gap={'10px'} alignItems={'center'}>
                <MdCall />
                  <Text fontWeight={'600'} fontFamily={'poppins'}>1844-844-3272</Text>
                </Flex>
                </>
          )}

        {role === ROLES.AGA && (
                  <>
                        <Flex
                          w={"80%"}
                          gap={"40px"}
                          fontWeight={"600"}
                          fontSize={"14px"}
                          fontFamily={"poppins"}
                          justifyContent={"center"}
                          mt={"8px"}
                        >
                          <Link to='/advisor-generate-quote'>
                            <Box>{t('Generate Quote')}</Box>
                          </Link>
                          <Link to={'/advisor-profile'}>
                            <Box>{t('My Account')}</Box>
                          </Link>
                          <HeaderDropdown />
                          <Link to={'/advisor-profile'}>
                            <Box>{t('Team')}</Box>
                          </Link>
                        </Flex>
                        <Flex w={'20%'} gap={'10px'} alignItems={'center'}>
                        <MdCall />
                          <Text fontWeight={'600'} fontFamily={'poppins'}>1844-844-3272</Text>
                        </Flex>
                        </>
          )}




      </Flex> */}
    </Box>
  );
}

export default CustomerHeader;
