import { CUSTOMER_EDIT_PROFILE, CUSTOMER_LOGIN, CUSTOMER_PROFILE, CUSTOMER_SIGNUP } from "../../../constants/ApplicationUrl";
import Api from "../../../dependencies/utils/Api";
import axios from "axios";


export const CustomerSignupService = {
    async customerSignupList(params) {
        console.log(params,'params2');
        try {
            const response = await Api.POST(CUSTOMER_SIGNUP, params);
            console.log(response,'response');
            const { data: { message, status, data } = {} } = response;
            if (status) {
                return { message, status, data };
            } else {
                return { message, status };
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
};


export const CustomerLoginService = {

    customerLoginList(params) {
        return Api.POST(CUSTOMER_LOGIN, params).then((response) => {
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    customerProfile() {
        return Api.GET(CUSTOMER_PROFILE).then((response) => {
            console.log(response,'response')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    customerEditProfile(params) {
        return Api.POST(CUSTOMER_EDIT_PROFILE, params).then((response) => {
            const { data, status} = response
            console.log(response,'reso')
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
};
