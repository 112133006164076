import React from 'react'
import Sidebar from '../../Sidebar'

const ClaimRequests = () => {



return (
    <Sidebar headingText={'Claim Requests'}>
      
    </Sidebar>
  )
}

export default ClaimRequests
