import { ADMIN_USER_GET_LIST, isLoading } from "../../../../constants/actionConstants";
import { AdminUserService } from "./service";

export const adminUserList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminUserService.adminUserList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADMIN_USER_GET_LIST,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };