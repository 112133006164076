import React, { useEffect, useState } from 'react';
import { Box, Tabs, TabList, Tab, TabPanel, TabPanels, Flex, Text, Button,Input,Select,TableContainer,Table,Thead,Tr,Td,Th,Tbody, Tooltip} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Api from '../../../dependencies/utils/Api';
import { advisorSingleGetQuotes } from '../Dependencies/action';
import { useDispatch, useSelector } from 'react-redux';

const AdvisorViewSingleQuote = () => {
    const { id } = useParams();
    const [data,setData]=useState([]);
    // const data = useSelector(state => state.advisorSingleQuote.data);
    console.log(id,'id')
    const dispatch = useDispatch();




useEffect(() => {
    if (id) {
        dispatch(advisorSingleGetQuotes(id))
            .then(response => {
                setData([response.data]); 
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
}, [dispatch, id]);


return (
    <Box pt={'60px'} pb={'20px'} backgroundColor={'grey'}>
    <Tabs boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' backgroundColor={'white'} isFitted variant='enclosed' w={'80%'} m={'auto'}>
      <TabList border={'2px solid grey'} borderTop={'none'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          paddingTop: '5px',
          color: 'black',
          fontSize: '16px',
          fontWeight: '800',
          fontFamily: 'poppins',
        }}>
        <Tab>Quick Quote</Tab>
        <Tab>Confirm & Pay</Tab>
      </TabList>
      <TabPanels>
        <TabPanel border={'2px solid grey'} borderTop={'none'}>
            {data && data.map(ele=>(  
                    <Box w={'95%'} m={'auto'}>
                    <Flex justifyContent={'space-around'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={'20px'}>
                            <Box>
                            <Text fontWeight={'700'}>First Date of Cover</Text>
                            <Input color={'grey'} name="firstDate" w={'230px'} value={ele.firstDateOfCover} size='sm' type='date' border={'1px solid #d7d7d7'} readOnly />
                            </Box>
                            <Box>
                            <Text fontWeight={'700'}>Last Date of Cover</Text>
                            <Input color={'grey'} name="lastDate" value={ele.lastDateOfCover} w={'230px'} size='sm' type='date' border={'1px solid #d7d7d7'} />
                            </Box>
                            <Box>
                            <Text fontWeight={'700'}>Duration</Text>
                            <Input color={'grey'} w={'80px'} value={ele.duration} size='sm' backgroundColor={'#eaeaea'} border={'1px solid #d7d7d7'} readOnly/>
                            </Box>
                            <Box>
                            <Text fontWeight={'700'}>Client country</Text>
                            <Input color={'grey'} name="clientCountry" value={'Canada'} placeholder='Canada' w={'180px'} size='sm' border={'1px solid #d7d7d7'} readOnly/>
                            </Box>
                            <Box>
                            <Text fontWeight={'700'}>Province</Text>
                            <Input color={'grey'} name="clientCountry" value={ele.policyHolderProvince} placeholder='Canada' w={'180px'} size='sm' border={'1px solid #d7d7d7'} readOnly/>
                            </Box>
                        </Flex>

                {ele.listOfInsured.map(item=>(
                    <Box backgroundColor={'#f3f3f3'} border={'1px solid #80808026'} p={'20px'} m={'auto'} mt={'7px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
                        <Flex gap={'20px'}>
                            <Box>
                            <Text fontWeight={'700'}>Name Of Insured</Text>
                            <Input color={'grey'} value={item.insuredName} readOnly background={'white'} w={'280px'} size='xs' border={'1px solid #d7d7d7'}
                            name="name"/>            
                            </Box>
                            <Box>
                            <Text fontWeight={'700'}>Gender</Text>
                            <Input color={'grey'} value={item.insuredGender} readOnly background={'white'} w={'280px'} size='xs' border={'1px solid #d7d7d7'}
                            name="name"/>  
                            </Box>
                            {/* <Tooltip hasArrow label='Age must between 15 days and 89 years' bg='gray.300' color='black' placement='top-start'> */}
                            <Box>
                            <Text fontWeight={'600'}>Date of Birth</Text>
                            <Input color={'grey'} type='date' background={'white'} w={'180px'} size='xs' border={'1px solid #d7d7d7'} readOnly
                            value={item.insuredDob} 
                            />            
                            </Box>
                            {/* </Tooltip> */}
                        </Flex>
                        <Flex gap={'20px'} mt={'10px'}>
                            <Box>
                            <Text fontWeight={'700'}>Beneficiary Name</Text>
                            <Input color={'grey'} background={'white'} w={'280px'} size='xs' border={'1px solid #d7d7d7'}
                            name="benificiaryName" readOnly
                            value={item.beneficiaryName}/>            
                            </Box>
                            <Box>
                            <Text fontWeight={'700'}>Relation</Text>
                            <Input color={'grey'} background={'white'} w={'280px'} size='xs' border={'1px solid #d7d7d7'}
                            name="relation" value={item.beneficiaryRelationToInsured} readOnly
                            />            
                            </Box>  
                            <Box>
                            <Text fontWeight={'600'}>Beneficiary DOB</Text>
                            <Input readOnly color={'grey'} type='date' background={'white'} w={'180px'} size='xs' border={'1px solid #d7d7d7'} value={item.beneficiaryDob}
                            name="benificiaryDob"
                            />            
                            </Box>
                        </Flex>
                        </Box>
                    ))}                       
                    <hr />
                    <Flex fontSize={'12px'} mt={'20px'} mb={'10px'} gap={'5%'}>
                        <Box>
                            <Text>Arrival Date in Canada</Text>
                            <Input name="clientCountry" value={ele.firstDateOfCover} placeholder='Canada' w={'180px'} size='xs' border={'1px solid #d7d7d7'} readOnly/>
                        </Box>
                        <Box>
                        <Text>Waiting Period</Text>
                            <input
                            name="waiting"
                            value={ele.waitingPeriod}
                            readOnly
                            />
                        </Box> 
                        <Box>
                        <Text>Product</Text>
                            <input
                            name="waiting"
                            value={ele.product}
                            readOnly
                            />
                        </Box> 
                        <Flex direction={'column'} alignItems={'center'}>
                        <Text>Family Coverage</Text>
                        <input type='checkbox' readOnly size='md' name='1' colorScheme='green' checked={ele.familyCoverage===1?true:false}
                        ></input>
                        </Flex>  
                        <Flex direction={'column'} alignItems={'center'}>
                        <Text>Super Visa</Text>
                        <input type='checkbox' readOnly size='md' name='1' colorScheme='green' checked={ele.superVisa===1?true:false}
                        ></input>
                        </Flex>  
                    </Flex>

                    <Flex fontSize={'12px'} mt={'20px'} mb={'10px'} gap={'5%'}>
                        <Box>
                            <Text>Policy Limit</Text>
                            <Text w={'180px'} size='xs'> {ele.policyLimit}</Text>
                        </Box>
                        <Box>
                        <Text>Deductible</Text>
                        <Text w={'180px'} size='xs'> {'value'}</Text>
                        </Box>  
                    </Flex>
                    <Button size={'xs'} mb={'20px'} backgroundColor={'teal'} color={'white'} fontFamily={'poppins'} _hover={{color:'white'}}>See Benfit Comparison</Button>
                    <hr />
                    <Flex>
                    <Box w={'50%'}>
                    <TableContainer >
                    <Table>
                        <Thead>
                            <Tr gap={'50px'}>
                                <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>User Name</Th>
                                <Th p={'7px'} fontSize={'10px'}>Plan Name</Th>
                                <Th p={'7px'} fontSize={'10px'}>With Pre-existing</Th>
                                <Th p={'7px'} fontSize={'10px'}>Without Pre-existing</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                ele.listOfInsured.map((item)=>(
                                <Tr color={'grey'} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                    <Td p={'2px'}>{item.insuredName}</Td>
                                    <Td p={'2px'}>{item.insuredPlan}</Td>
                                    <Td p={'2px'} display={'flex'}>
                                    <Text pl={'10px'}>{item.costWithPreExisting}</Text>
                                    </Td>
                                    <Td p={'2px'}>
                                    <Text pl={'10px'}>{item.costWithPreExistingWithoutAdj}</Text>
                                    </Td>
                                </Tr>
                                ))
                            }
                        </Tbody>
                    </Table>
                    </TableContainer>
                    </Box>
                    <Box w={'50%'}>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th p={'7px'} fontSize={'10px'}>Period</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Net</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Tax</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Adjustment</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Total</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                    <Tr color={'grey'} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                        <Td p={'2px'}>{`${ele.firstDateOfCover} - ${ele.lastDateOfCover}`}</Td>
                                        <Td p={'2px'}>{ele.quoteAmount}</Td>
                                        <Td p={'2px'}>0.00 CAD	</Td>
                                        <Td p={'2px'} textAlign={'center'}>0.00 CAD	</Td>
                                        <Td p={'2px'} textAlign={'center'} fontWeight={'600'}>{ele.quoteAmount}</Td>
                                    </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer></Box>
                    </Flex>
            {/* <Text color={'red'} fontSize={'11px'}>{message}</Text> */}
                        <Text textAlign={'center'} mt={'20px'}>Policy Holder Details</Text>
                        <Flex fontWeight={'700'} gap={'40px'} fontSize={'12px'} fontFamily={'Open Sans,sans-serif'} pb={'20px'} mt={'20px'}>
                        <Box>
                        <Text pb={'3px'}>Policy Owner*</Text>
                        <Input color={'grey'} value={ele.policyHolderName} w={'220px'} size='sm' name="policyOwnerDob" border={'1px solid #d7d7d7'} />  
                        </Box>
                        <Box>
                        <Text pb={'3px'}>Policy Owner Date Of Birth*</Text>
                        <Input color={'grey'} type='date' value={ele.policyHolderDob} readOnly w={'220px'} size='sm' border={'1px solid #d7d7d7'} />            
                        </Box>
                        <Box>
                        <Text pb={'3px'}>Policy Owner Email*</Text>
                        <Input color={'grey'} value={ele.policyHolderEmail} readOnly w={'220px'} size='sm' border={'1px solid #d7d7d7'} />    
                        </Box>
                        <Box>
                        <Text pb={'3px'}>Policy Owner Address*</Text>
                        <Input color={'grey'} value={ele.policyHolderAddress} w={'220px'} size='sm' border={'1px solid #d7d7d7'}/>     
                        <Text color="red.500"></Text>       
                        </Box>
                    </Flex>
                    <Flex fontWeight={'700'} fontSize={'12px'} fontFamily={'Open Sans,sans-serif'} gap={'40px'} pb={'20px'} mt={'10px'}>
                        <Box>
                        <Text pb={'3px'}>Policy Owner Postal Code*</Text>
                        <Input color={'grey'} value={ele.policyHolderPostalCode} w={'220px'} size='sm' border={'1px solid #d7d7d7'}/>   
                        </Box>
                        <Box>
                        <Text pb={'3px'}>Policy Owner City*</Text>
                        <Input color={'grey'} value={ele.policyHolderCity} w={'220px'} size='sm' border={'1px solid #d7d7d7'} />   
                        </Box>
                        <Box>
                        <Text pb={'3px'}>Policy Owner Phone*</Text>
                        <Input color={'grey'} value={ele.policyHolderPhone} w={'220px'} size='sm' border={'1px solid #d7d7d7'} />            
                        </Box>
                    </Flex>
                    </Box>))}
        </TabPanel>
        <TabPanel>
          <p>two!</p>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </Box>
  )
}

export default AdvisorViewSingleQuote
