import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import {Flex, Box,Select, Tooltip} from '@chakra-ui/react'
import { useDispatch } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import { primaryColor } from '../../../Utils/UniversalColor';
import CustomInput from '../../../Utils/Input/Index';
import { useNavigate } from 'react-router-dom';
import {advisorGetQuotes} from '../../Advisor/Dependencies/action'


const MyQuotation = () => {
  const [product, setProduct] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const [quotesData,setQuotesData]=useState([])
  const [colDefs, setColDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const navigate=useNavigate()

  useEffect(() => {
    getAllQuotes()
    setColDefs([
      { field: "product",filter:true},
      { field: "quotationNo" },
      { field: "dateOfQuote" },
      { field: "tripType" },
      { field: "customerName" },
      { field: "amount" },
      { field: "Action" ,
       cellRenderer: props => {
        return <>
        <Tooltip hasArrow label='You can view, send ,edit and cancel quote here.' bg='gray.300' color='black' placement='right-start' w={'180px'}>
         <Select onChange={(e) => handleChange(e, props)} size={'sm'} style={{backgroundColor:'#96ffff',border:'1px solid black'}}>     
          <option value="">Select</option>
          <option value="view">View Quote</option>
          <option value="send">Send Quote</option>
          <option value="edit">Edit Quote</option>
          <option value="cancel">Cancel Quote</option>
          </Select>
        </Tooltip>
        </>;
    } }
    ]);
  }, []);

  const handleChange = (e, props) => {
    const { data } = props;
    if(e.target.value==='view'){
      navigate(`/customer-view-quote/${data.quotationNo}`)
    }
    if(e.target.value==='send'){
      alert('Quote has been sent successfully')
    }
    if(e.target.value==='edit'){
      navigate(`/customer-edit-quote/${data.quotationNo}`);
    }
    if(e.target.value==='cancel'){
      alert("Quote has been cancelled.")
    }
    console.log("Selected value:", e.target.value);
    console.log("Row data:", data);
  };
  


  const getAllQuotes = () => {
    dispatch(advisorGetQuotes()).then((res) => {
      if (res.status === 200) {
        if (Array.isArray(res.data)) {
          setQuotesData(res.data);
          const formattedData = res.data.map((quote, index) => ({
            id: index + 1,
            // employeeName: 'Ravi Sharma',
            // designation: 'Advisor',
            product: quote.product,
            quotationNo: quote.quotationNo,
            dateOfQuote: quote.createdAt,
            tripType: quote.tripType,
            customerName: quote.policyHolderName,
            amount: quote.quoteAmount,
          }));
          setRowData(formattedData);
        } else {
          console.error('Response data is not an array:', res.data);
        }
      }
    });
  };
  


  const downloadPDF = () => {
    const MyDoc = (
      <Document>
        <Page>
          <Text>Quotations</Text>
        </Page>
      </Document>
    );

    const blob = new Blob([MyDoc], { type: 'application/pdf' });
    saveAs(blob, 'quotations.pdf');
  };

  const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rowData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'quotations.xlsx');
    };

    const downloadCSV = () => {
      const csvContent = [
        Object.keys(rowData[0]).join(','), // Header row
        ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
      ].join('\n');
      
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'quotations.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const pagination = true;
    const paginationPageSizeSelector = [2, 5, 1000];

  return (
    <div>
      <div style={{ paddingTop: '80px', width: '80%', margin: 'auto', marginBottom: '90px' }}>
        <h3 style={{ color: primaryColor, fontSize: '22px', fontFamily: 'poppins', paddingBottom: '20px' }}>My Quotes</h3>
        <Flex>
          <Box>
            <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
          </Box>
          <Box>
            <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
          </Box>
        </Flex>
        <br />

        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
          <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button></Tooltip>
            <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button></Tooltip>
            <Tooltip hasArrow label='Download sheet csv' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</button></Tooltip>
          </div>
          <div>
            {/* <label htmlFor="search">Search:</label> */}
              <CustomInput/>
            {/* <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
          </div>
        </Flex>
        {rowData.length > 0 ? (
          <div className='ag-theme-quartz' style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              // frameworkComponents={{ actionCellRenderer }}
              domLayout='autoHeight'
              paginationPageSizeSelector={paginationPageSizeSelector}
              pagination={pagination}
              onGridReady={(params) => {
                params.api.sizeColumnsToFit();
              }}
            />
          </div>
        ) : (
          <div>No quotes available</div>
        )}

      </div>
    </div>
  );
};

export default MyQuotation;