import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { FaRegEye } from 'react-icons/fa';
import { Box, Flex } from '@chakra-ui/react';
import EditableModal from './Modal';
import { useDispatch } from 'react-redux';
import { adminPagesAll } from './dependencies/action';

const Pages = () => {
  const [modalContent, setModalContent] = useState(null);
  const [pageData,setPageData]=useState([])
  const [modalId,setModalId]=useState()
  const dispatch=useDispatch()

  const handleMenuClick = (content) => {
    setModalId(content.id)
    setModalContent(content.pageTitle);
  };
  useEffect(() => {
    dispatch(adminPagesAll())
      .then(res => {
        const { status, data } = res;
        
        setPageData(res)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [dispatch]);

console.log("pageData",pageData)
const handleEdit=()=>{

}

  return (
    <>
    <Sidebar headingText={'Pages'}>
      <Box w={'60%'} m={'auto'} mt={'90px'} background={'white'} padding={'25px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <colgroup>
            <col style={{ width: '45%' }} />
            <col style={{ width: '45%' }} />
            <col style={{ width: '10%' }} /> {/* New column for action */}
          </colgroup>
          <thead>
            <tr style={{ borderBottom: '1px solid #dddddd' }}>
              <th style={{ textAlign: 'left', padding: '10px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Pages</th>
              <th style={{ padding: '10px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Action</th>
              <th style={{ padding: '10px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Edit</th> {/* New column header */}
            </tr>
          </thead>
          <tbody>
            {pageData.map((elem) => (
              <tr key={elem.id} style={{ borderBottom: '1px solid #dddddd' }}>
                <td style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{elem.pageTitle}</td>
                <td style={{ padding: '10px', fontSize: '22px', fontFamily: 'Open Sans, sans-serif' }}>
                  <Flex color={'#4caf50'} justifyContent={'center'} alignItems='center'>
                    <FaRegEye onClick={() => handleMenuClick(elem)} />
                  </Flex>
                </td>
                <td style={{ padding: '10px', fontSize: '22px', fontFamily: 'Open Sans, sans-serif' }}> {/* New column */}
                  <Flex color={'#4caf50'} justifyContent={'center'} alignItems='center'>
                    <button onClick={() => handleEdit(elem)}>Edit</button> {/* Action button */}
                  </Flex>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Sidebar>
    {modalContent && (
      <EditableModal onClose={() => setModalContent(null)} initialContent={modalContent} pageData={modalContent} id={modalId}/>
    )}
  </>
  );
};

export default Pages;
