import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import AdvisrHeader from '../../Common/Header/AdvisrHeader';
// import {Flex, Box,Select} from '@chakra-ui/react'
import { useDispatch } from "react-redux";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { primaryColor } from '../../../Utils/UniversalColor';
import { FaDownload } from "react-icons/fa6";
import {Flex,Box,Text,Input,Textarea} from '@chakra-ui/react'
import AdvisorHeader from '../../Common/Header/AdvisrHeader';


const AdvisorCommission = () => {
  const [activeTab, setActiveTab] = useState('my-commission');
  const [personalData, setPersonalData] = useState();
  const [licenseData, setLicenseData] = useState();
  const [corporationData, setCorporationData] = useState();
  const [referenceData, setReferenceData] = useState(); 


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSaveData = (data) => {
    switch (activeTab) {
      case 'my-commission':
        setPersonalData(data);
        break;
      case 'pending-commission':
        setLicenseData(data);
        break;
      case 'settled-commission':
        setCorporationData(data);
        break;
      default:
        break;
    }
  };

  const renderForm = () => {
    switch (activeTab) {
      case 'my-commission':
        return <MyCommission onSave={handleSaveData} />;
      case 'pending-commission':
        return <PendingCommission onSave={handleSaveData} />;
      case 'settled-commission':
        return <SettledCommission onSave={handleSaveData} />;
      default:
        return null;
    }
  };

  return (
    <>
      <AdvisorHeader/>
    <div style={{ padding: "90px" }}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <br/>
          <Flex className="tablist" justifyContent={'space-around'} pb={'50px'}>
            <button style={{boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px",width:"200px",backgroundColor:"#23b5b7",color:'white', padding:'10px 0px 10px 0px', borderRadius:'10px'}} active={activeTab === 'my-commission'} onClick={() => handleTabClick('my-commission')}>My Commission</button>
            <button style={{boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px",width:"200px",backgroundColor:"#23b5b7",color:'white', padding:'10px 0px 10px 0px', borderRadius:'10px'}} active={activeTab === 'pending-commission'} onClick={() => handleTabClick('pending-commission')}>Pending Commission</button>
            <button style={{boxShadow:" rgba(0, 0, 0, 0.35) 0px 5px 15px",width:"200px",backgroundColor:"#23b5b7",color:'white', padding:'10px 0px 10px 0px', borderRadius:'10px'}} active={activeTab === 'settled-commission'} onClick={() => handleTabClick('settled-commission')}>Settled Commission</button>
          </Flex>
        </div>
      </div>
        <div style={{ flex: 2 }}>
        {/* <h1>Personal Details</h1> */}
          {renderForm()}
          <DisplayData activeTab={activeTab} data={{ personalData, licenseData, corporationData, referenceData }} />
        </div>
    </div>
    </>
  );
};

const Tab = ({ active, onClick, children }) => {
  return (
    <button className={`tab ${active ? 'active' : ''}`} onClick={onClick}>
      {children}
    </button>
  );
};

const DisplayData = ({ activeTab, data }) => {
  switch (activeTab) {
    case 'my-commission':
      return <pre>{JSON.stringify(data.personalData, null, 2)}</pre>;
    case 'pending-commission':
      return <pre>{JSON.stringify(data.licenseData, null, 2)}</pre>;
    case 'settled-commission':
      return <pre>{JSON.stringify(data.corporationData, null, 2)}</pre>;
    default:
      return null;
  }
};

const MyCommission = () => {
  const [product, setProduct] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  
  const [colDefs, setColDefs] = useState([
    { field: "customerName" },
    { field: "policyNo" },
    { field: "insuredName" },
    { field: "tripType" },
    { field: "issueDate" },
    { field: "commissionAmount", filter: 'agNumberColumnFilter' },
    { field: "correctionAmount" },
    { field: "comm" },
    { field: "desc" },
    { field: "settledStatus" }
  ]);

  const [rowData, setRowData] = useState([
    { id: 1, customerName: 'John Doe',policyNo:'1235684652',insuredName:'Ravi Sharma',tripType:'Super visa',issueDate:"19-Aprail-24",commissionAmount:"200$",correctionAmount:"12$",comm:"5%",desc:'Desc',settledStatus:"pending"},
    { id: 1, customerName: 'Sam Willision',policyNo:'1235684652',insuredName:'Ravi Sharma',tripType:'Super visa',issueDate:"19-Aprail-24",commissionAmount:"200$",correctionAmount:"12$",comm:"5%",desc:'Desc',settledStatus:"pending"},
  ]);


  const downloadPDF = () => {
    const MyDoc = (
      <Document>
        <Page>
          <Text>Quotations</Text>
        </Page>
      </Document>
    );

    const blob = new Blob([MyDoc], { type: 'application/pdf' });
    saveAs(blob, 'quotations.pdf');
  };

  const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rowData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'quotations.xlsx');
    };

    const downloadCSV = () => {
      const csvContent = [
        Object.keys(rowData[0]).join(','),
        ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(','))
      ].join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'quotations.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

return (
  <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'90%'} m={'auto'}>
 <div>
        <Flex justifyContent={'space-between'}>
          <Box>
          <Text fontSize={'22px'} style={{color:primaryColor, fontFamily:'poppins',paddingBottom:'10px'}}>My Commission</Text>
          </Box>
          <Flex>
          <Box>
            <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
          </Box>
          <Box>
            <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
          </Box>
          </Flex>
        </Flex>
        <br />
        <hr />
        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</button>
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
      </div>
    </Box>
  );
};


const PendingCommission = ({ onSave }) => {
  const [product, setProduct] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  
  const [colDefs, setColDefs] = useState([
    { field: "customerName" },
    { field: "policyNo" },
    { field: "insuredName" },
    { field: "tripType" },
    { field: "issueDate" },
    { field: "commissionAmount", filter: 'agNumberColumnFilter' },
    { field: "correctionAmount" },
    { field: "comm" },
    { field: "desc" },
    { field: "settledStatus" }
  ]);

  const [rowData, setRowData] = useState([
    { id: 1, customerName: 'John Doe',policyNo:'1235684652',insuredName:'Ravi Sharma',tripType:'Super visa',issueDate:"19-Aprail-24",commissionAmount:"200$",correctionAmount:"12$",comm:"5%",desc:'Desc',settledStatus:"pending"},
    { id: 1, customerName: 'Sam Willision',policyNo:'1235684652',insuredName:'Ravi Sharma',tripType:'Super visa',issueDate:"19-Aprail-24",commissionAmount:"200$",correctionAmount:"12$",comm:"5%",desc:'Desc',settledStatus:"pending"},
  ]);


  const downloadPDF = () => {
    const MyDoc = (
      <Document>
        <Page>
          <Text>Quotations</Text>
        </Page>
      </Document>
    );

    const blob = new Blob([MyDoc], { type: 'application/pdf' });
    saveAs(blob, 'quotations.pdf');
  };

  const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rowData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'quotations.xlsx');
    };

    const downloadCSV = () => {
      const csvContent = [
        Object.keys(rowData[0]).join(','),
        ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(','))
      ].join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'quotations.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

return (
  <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'90%'} m={'auto'}>
 <div>
        <Flex justifyContent={'space-between'}>
          <Box>
          <Text fontSize={'22px'} style={{color:primaryColor, fontFamily:'poppins',paddingBottom:'10px'}}>Pending Commission</Text>
          </Box>
          <Flex>
          <Box>
            <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
          </Box>
          <Box>
            <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
          </Box>
          </Flex>
        </Flex>
        <br />
        <hr />
        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</button>
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
      </div>
    </Box>
  );
};

const SettledCommission = ({ onSave }) => {
  const [product, setProduct] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  
  const [colDefs, setColDefs] = useState([
    { field: "customerName" },
    { field: "policyNo" },
    { field: "insuredName" },
    { field: "tripType" },
    { field: "issueDate" },
    { field: "commissionAmount", filter: 'agNumberColumnFilter' },
    { field: "correctionAmount" },
    { field: "comm" },
    { field: "desc" },
    { field: "settledStatus" }
  ]);

  const [rowData, setRowData] = useState([
    { id: 1, customerName: 'John Doe',policyNo:'1235684652',insuredName:'Ravi Sharma',tripType:'Super visa',issueDate:"19-Aprail-24",commissionAmount:"200$",correctionAmount:"12$",comm:"5%",desc:'Desc',settledStatus:"pending"},
    { id: 1, customerName: 'Sam Willision',policyNo:'1235684652',insuredName:'Ravi Sharma',tripType:'Super visa',issueDate:"19-Aprail-24",commissionAmount:"200$",correctionAmount:"12$",comm:"5%",desc:'Desc',settledStatus:"pending"},
  ]);


  const downloadPDF = () => {
    const MyDoc = (
      <Document>
        <Page>
          <Text>Quotations</Text>
        </Page>
      </Document>
    );

    const blob = new Blob([MyDoc], { type: 'application/pdf' });
    saveAs(blob, 'quotations.pdf');
  };

  const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rowData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'quotations.xlsx');
    };

    const downloadCSV = () => {
      const csvContent = [
        Object.keys(rowData[0]).join(','),
        ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(','))
      ].join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'quotations.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

return (
  <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'90%'} m={'auto'}>
 <div>
        <Flex justifyContent={'space-between'}>
          <Box>
          <Text fontSize={'22px'} style={{color:primaryColor, fontFamily:'poppins',paddingBottom:'10px'}}>Settled Commission</Text>
          </Box>
          <Flex>
          <Box>
            <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
          </Box>
          <Box>
            <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
          </Box>
          </Flex>
        </Flex>
        <br />
        <hr />
        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</button>
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
      </div>
    </Box>
  );
};


export default AdvisorCommission;
