import React from 'react'
import Sidebar from '../../../Sidebar'
import { FaDownload } from "react-icons/fa6";
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';



const PolicyDocs = () => {
    const navigate=useNavigate()


    const handleBack = () => {
        navigate(-1);
      }


return (
    <Sidebar headingText={'Download Documents'}>
        <Box background='white' w={'80%'} m={'auto'} p={'20px'} mt={'50px'}>
            <Button size={'sm'} background={'#9c27b0'} color='white' mt={'50px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
            <Text mt={'30px'} mb={'30px'} color={'grey'} fontSize={'22px'} fontFamily={'Open Sans,sans-serif'}>Download document for Policy : 2024PTM351215</Text>
            <Flex w={'90%'} m={'auto'} mt={'50px'} mb={'70px'} gap={'40px'}>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Terms&Eligibility</Text>
                    <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                    <FaDownload />
                    </Flex>
                </Box>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Policy</Text>
                    <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                    <FaDownload />
                    </Flex>
                </Box>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Wallet Card</Text>
                    <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                    <FaDownload />
                    </Flex>
                </Box>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Claim Doc</Text>
                    <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                    <FaDownload />
                    </Flex>
                </Box>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Policy Doc</Text>
                    <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                    <FaDownload />
                    </Flex>
                </Box>
            </Flex>
        </Box>
    </Sidebar>
  )
}

export default PolicyDocs
