import React, { useState } from 'react';
import Sidebar from '../Sidebar';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { FaUsers } from "react-icons/fa";
import { TbUserSquareRounded } from "react-icons/tb";
import { TbUsersGroup } from "react-icons/tb";
import { IoDocumentsOutline } from "react-icons/io5";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Link } from 'react-router-dom';


const AdminDashboard = () => {


const [colDefs, setColDefs] = useState([
  { field: "employeeName", width:100},
  { field: "endorsementRequestedDate", width:180},
  { field: "desgintation", width:170 },
  { field: "policyNo" },
  { field: "dateOfQuote", width:180 },
  { field: "endorsementType", width:180 },
  { field: "customerName" },
  { field: "Action" }
]);

const [rowData, setRowData] = useState([
  { id: 1, employeeName: "Dalbir Singh", Designation:"MGA", endorsementRequestedDate:'15-Aprail-2024', endorsementType: 'Type A', customerName: 'John Doe', download: '#' },
  { id: 2,  employeeName: "Dalbir Singh", Designation:"MGA",endorsementRequestedDate:'15-Aprail-2024', endorsementType: 'Type B', customerName: 'Jane SmiTh', download: '#' },
]);



  return (
    <Sidebar headingText="Dashboard">
      <Box paddingBottom={'50px'}>
        <Flex w={'95%'} m={'auto'} mt={'50px'} justifyContent={'space-between'}>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
          <Link to='/admin/policies'>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>All Policies</Text>
                <Text fontWeight={'600'}>381</Text>
              </Box>
            </Flex>
            <hr />
          </Link>
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'  cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
          <Link to='/admin/mga/aga/advisor'>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
            <Flex fontSize={'26px'}>
            <TbUsersGroup />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>All Users</Text>
                <Text fontWeight={'600'}>91</Text>
              </Box>
            </Flex>
            </Link>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Link to="">
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <FaUsers />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>All MGA</Text>
                <Text fontWeight={'600'}>32</Text>
              </Box>
            </Flex>
            </Link>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
            <Flex fontSize={'26px'}>
            <TbUserSquareRounded />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>All AGA</Text>
                <Text fontWeight={'600'}>93</Text>
              </Box>
            </Flex>
            <hr />
          </Box>
        </Flex>
        <Flex w={'95%'} m={'auto'} mt={'30px'} justifyContent={'space-between'}>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>All Advisors</Text>
                <Text fontWeight={'600'}>381</Text>
              </Box>
            </Flex>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>Refund Requests</Text>
                <Text fontWeight={'600'}>381</Text>
              </Box>
            </Flex>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'13px'} fontFamily={'poppins'}>Subscriptions</Text>
                <Text fontWeight={'600'}>381</Text>
              </Box>
            </Flex>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>Endorsements</Text>
                <Text fontWeight={'600'}>381</Text>
              </Box>
            </Flex>
            <hr />
          </Box>
        </Flex>

      <Box _hover={{backgroundColor:'white'}} borderRadius={'10px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} w={'95%'} m={'auto'} mt={'50px'}>
      <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton  _hover={{backgroundColor:'white'}}>
                  <Box fontSize={'18px'} p={'5px'} as='span' flex='1' textAlign='left'>
                  Policy Endorsement Pending List <span style={{color:'red'}}>(2)</span>  <br />
                  <Text fontSize={'12px'}>
                  pending for approval
                  </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
                  <AccordionPanel pb={4}>
                  <div className="ag-theme-quartz"
                      style={{width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
                    >
                      <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        domLayout='autoHeight'
                        onGridReady={(params) => {
                          params.api.sizeColumnsToFit();
                        }}                   
                        />
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
      </Box>
        <Box>
          <Flex w={'95%'} m={'auto'} justifyContent={'space-between'}>
            <Box w={'49%'} _hover={{backgroundColor:'white'}} mt={'50px'}>
            <Accordion borderRadius={'10px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} defaultIndex={[1]} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton  _hover={{backgroundColor:'white'}}>
                  <Box fontSize={'14px'} p={'5px'} as='span' flex='1' textAlign='left'>
                  Policy Subscription - Pending Verification <span style={{color:'red'}}>(1)</span>  <br />
                  <Text fontSize={'12px'}>
                  April Month - Pending for verification
                  </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
                  <AccordionPanel pb={4}>
                  <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '30%' }} />
                </colgroup>
                <thead>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <th style={{ textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Policy No</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Customer Name</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Installment Date</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Amount + Installment Charges (CAD)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <td style={{  textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>2023PTM41160</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Satendra Manishankar Dube	</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>2024-04-02	</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>311+10</td>
                    </tr>
                </tbody>
                 </table>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>

            <Box w={'49%'} _hover={{backgroundColor:'white'}} mt={'50px'}>
            <Accordion borderRadius={'10px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} defaultIndex={[1]} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton  _hover={{backgroundColor:'white'}}>
                  <Box fontSize={'14px'} p={'5px'} as='span' flex='1' textAlign='left'>
                  Policy Renewal List - 30 Days <span style={{color:'red'}}>(2)</span>  <br />
                  <Text fontSize={'12px'}>
                  2024-04-30 - 2024-05-30
                  </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
                  <AccordionPanel pb={4}>
                  <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '30%' }} />
                </colgroup>
                <thead>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <th style={{ textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Policy No</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Customer Name</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Installment Date</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Amount + Installment Charges (CAD)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <td style={{  textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>2023PTM41160</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Satendra Manishankar Dube	</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>2024-04-02	</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>311+10</td>
                    </tr>
                </tbody>
                 </table>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Flex>
        </Box>

      </Box>
    




    </Sidebar>
  );
};

export default AdminDashboard;
