// import { CUSTOMER_SIGNUP } from "../../../constants/ApplicationUrl"
// import { customerSignupList } from "./action"
// import Api from "../../../dependencies/utils/Api"

// export const CustomerSignupService = {
//     customerSignupList(params) {
//         return Api.POST(CUSTOMER_SIGNUP).then((response) => {
//             const { data: {message, status, data} = {}} = response
//             if(status) {
//                 return {message, status, data}
//             }else {
//                 return {message, status}
//             }
//         })
//     },
// }
import { ADVISOR_CALCULATE_VTC, ADVISOR_GENERATE_QUOTE, ADVISOR_GET_QUOTES, ADVISOR_LOGIN, ADVISOR_PROFILE, ADVISOR_SIGNUP, ADVISOR_SINGLE_QUOTE, CUSTOMER_LOGIN, CUSTOMER_SIGNUP } from "../../../constants/ApplicationUrl";
import { SupervisaQuicknoteList } from "./action";
import Api from "../../../dependencies/utils/Api";
import axios from "axios";

export const AdvisorSignupService = {
    async advisorSignupList(params) {
        console.log(params,'params2');
        try {
            const response = await axios.post(ADVISOR_SIGNUP, params);
            console.log(response,'advisorsignup');
            const { data: { message, status, data } = {} } = response;
            if (status) {
                return { message, status, data };
            } else {
                return { message, status };
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
};


export const AdvisorLoginService = {
    advisorLoginList(params) {
        return Api.POST(ADVISOR_LOGIN, params).then((response) => {
            console.log("advisorlogin",response)
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    advisorProfile() {
        return Api.GET(ADVISOR_PROFILE).then((response) => {
            // console.log(response,'responserviceadvisor')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    // advisorCalculateVtc(params) {
    //     return Api.POST(ADVISOR_CALCULATE_VTC,params).then((response) => {
    //         const { data, status} = response
    //         if(status === 200) {
    //             return {data, status}
    //         }else {
    //             const {data: {message} = {}} = response
    //             return {message, status}
    //         }
    //     })
    // },

    advisorCalculateVtc(params) {
        return Api.POST(ADVISOR_CALCULATE_VTC, params)
          .then((response) => {
            const { data, status } = response;
            if (status === 200) {
              return { data, status };
            } else {
              const { data: { message } = {} } = response;
              return { message, status };
            }
          })
          .catch((error) => {
            // Handle API request errors here
            console.error("Error in advisorCalculateVtc:", error);
            return { message: "An error occurred while processing your request.", status: 500 };
          });
      },
      

    advisorGenerateQuote(params) {
        return Api.POST(ADVISOR_GENERATE_QUOTE,params).then((response) => {
            console.log(response,'response')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    advisorGetQuotes(params) {
        return Api.GET(ADVISOR_GET_QUOTES,params).then((response) => {
            console.log(response,'response')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    advisorSingleGetQuotes(id, params) {
        return Api.GET(`${ADVISOR_SINGLE_QUOTE}/${id}`, params).then((response) => {
            console.log(response,'response')
            const { data, status } = response;
            if (status === 200) {
                return { data, status };
            } else {
                const { data: { message } = {} } = response;
                return { message, status };
            }
        });
    }
}