import React from 'react'
import Sidebar from '../Sidebar'
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Button, Image } from '@chakra-ui/react'
import { FaRegEdit } from "react-icons/fa";


const InsurancePlans = () => {


  return (
    <Sidebar headingText={"Insurance Plans"}>


      <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background='white' w='95%' m='auto' mt={'90px'} p={'20px'}>
      <Tabs background='white' isFitted variant='enclosed'>
          <TabList mb='1em'>
            <Tab fontSize={'14px'} fontFamily={'poppins'}>VTC Product Plan</Tab>
            <Tab fontSize={'14px'} fontFamily={'poppins'}>Student Product Plan</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>

              <Box>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
            </colgroup>
            <thead>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Plan Name</th>
                    <th style={{   textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Sum Insured</th>
                    <th style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                    <th style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Edit</th>
                    <th style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Plan Table</th>
                </tr>
            </thead>
            <tbody>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Standard	</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>25000,50000,100000	</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Active</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'20px',fontFamily:'Open Sans, sans-serif' }}>
                    <FaRegEdit />
                    </td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>view </td>
                </tr>

                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Enhanced</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>25000,50000,100000	</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Active</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'20px',fontFamily:'Open Sans, sans-serif' }}>
                    <FaRegEdit />
                    </td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>view </td>
                </tr>

                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Premium</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>25000,50000,100000	</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Active</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'20px',fontFamily:'Open Sans, sans-serif' }}>
                    <FaRegEdit />
                    </td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>view </td>
                </tr>
            </tbody>
             </table>
              </Box>




            </TabPanel>
            <TabPanel>
            <Box>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <colgroup>
                <col style={{ width: '22%' }} />
                <col style={{ width: '22%' }} />
                <col style={{ width: '22%' }} />
                <col style={{ width: '22%' }} />
                <col style={{ width: '12%' }} />
            </colgroup>
            <thead>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Plan Type</th>
                    <th style={{   textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Standard</th>
                    <th style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Enhanced</th>
                    <th style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Premium</th>
                    <th style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Daily	</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>$1.45	</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>$1.7	</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>	$2</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'20px',fontFamily:'Open Sans, sans-serif' }}>
                    <FaRegEdit />
                    </td>
                </tr>

                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Annual</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>$1.45	</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>$1.7	</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>	$2</td>
                    <td style={{   textAlign: 'left', padding: '10px',fontSize:'20px',fontFamily:'Open Sans, sans-serif' }}>
                    <FaRegEdit />
                    </td>
                </tr>
            </tbody>
             </table>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>  
      </Box>


    </Sidebar>
  )
}

export default InsurancePlans
