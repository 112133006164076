import React, { useEffect, useState } from 'react'
import {Button,Checkbox,Input,Select,Box,Flex,Text,TableContainer,Table,Thead,Tr,Th,Td,Tbody,Stack, Textarea } from "@chakra-ui/react";
import Api from '../../../dependencies/utils/Api';


const ViewQuote = ({id}) => {
    console.log(id,'quote id')
    const [data,setData]=useState([])
    console.log(id,'id')

useEffect(()=>{
    getData()
},[])

const getData =()=>{
    Api.GET(`http://192.168.1.6:8080/Quote/getQuoteByQuotationNo/${id}`).then((res)=>{
        console.log(res,'res')
        setData([res.data])
    })
}



return (
    <>
    {data && data.map(ele=>(  
    <div style={{backgroundColor:"rgb(251 251 251)", width: "100%", padding: "10px",paddingTop:'0px',textAlign:'left', border:'2px solid #c4c4c4'}}>
    <div style={{ display: "flex",flexDirection: "column",alignItems: "center",width:'99%',margin:'auto'}}>
        <div
            style={{
            width: "100%",
            margin: "auto",
            padding: "18px",
            }}
        >
            <Flex justifyContent={'space-between'} borderBottom={'2px solid #088183'} mb={'15px'} pb={'12px'} alignItems={'center'}>
            <p style={{ fontFamily: "poppins", fontSize: "16px", fontWeight: 400}}>Trip Details</p>
            <Button backgroundColor={'teal'} color={'white'} size={'sm'} _hover={{color:'white'}}>Proceed</Button>
            </Flex>
            <Box fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'}>
            <Flex gap={'40px'} fontSize={'13px'} pb={'20px'}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>First Date of Cover</span>
                <Input w={'180px'} value={ele.firstDateOfCover} readOnly size='xs' type='date' border={'1px solid #d7d7d7'} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Last Date of Cover</span>
                <Input value={ele.lastDateOfCover} readOnly w={'180px'} size='xs' type='date' border={'1px solid #d7d7d7'}/>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Duration</span>
                <Input w={'80px'} value={ele.duration} readOnl size='xs' backgroundColor={'#eaeaea'} border={'1px solid #d7d7d7'} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Country</span>
                <p> Canada</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Province</span>
                <p>{ele.policyHolderProvince}</p>
            </div>
            </Flex>
            <Flex gap={'40px'} fontSize={'14px'}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Departure from country of origin</span>
                <Input value={ele.departureFromCountryOfOrigin} readOnly w={'180px'} size='xs' type='date' border={'1px solid #d7d7d7'} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Waiting Period</span>
                <input
                value={ele.waitingPeriod}
                readOnly
                />
            </div>
            <Box >
                <Text>Family Coverage</Text>
            <Checkbox checked={ele.familyCoverage===0?true:false} readOnly pt={'7px'} size="md" colorScheme="green"></Checkbox>
            </Box>
            </Flex>
            </Box>
        </div>
    </div>


    <div style={{
        width: "100%",
        margin: "auto",
        padding: "18px",}}>
    <p style={{fontFamily: "poppins",fontSize: "14px",fontWeight: 400,marginBottom: "20px",borderBottom: "2px solid #088183",}}>Traveller Details</p>
    {ele.listOfInsured.map(item=>(
    <Box fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'}>
        <Flex gap={'40px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={'15px'}>
            <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: "8px" }}>Name Of Insured</span>
                <Input value={item.insuredName} readOnly w={'180px'} size='xs' border={'1px solid #d7d7d7'} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Gender</span>
                <Input color={'grey'} value={item.insuredGender} readOnly background={'white'} w={'180px'} size='xs' border={'1px solid #d7d7d7'}/> 
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Date of Birth</span>
                <Input value={item.insuredDob} readOnly w={'180px'} size='xs' type='date' border={'1px solid #d7d7d7'} />
            </div>
        </Flex>
        <Flex gap={'40px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
        <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ marginBottom: "8px" }}>Beneficiary Name</span>
            <Input readOnly value={item.beneficiaryName} w={'180px'} size='xs' border={'1px solid #d7d7d7'} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: "8px" }}>Relation</span>
            <Input value={item.beneficiaryRelationToInsured} readOnly w={'180px'} size='xs' border={'1px solid #d7d7d7'} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ marginBottom: "8px" }}>Beneficiary DOB</span>
            <Input value={item.beneficiaryDob} readOnly w={'180px'} size='xs' type='date' border={'1px solid #d7d7d7'} />
        
        </div>
        {/* {index > 0 && (
        <Flex justifyContent={'end'}>
                <IoMdCloseCircle
                    style={{fontSize:'22px',color:buttonBackgroundColor}}
                    onClick={() => handleRemoveTraveller(index)}
                    />
                </Flex>
            )} */}
        </Flex>
    </Box>
     ))}
    </div>


    <div style={{width: "100%",margin: "auto",padding: "18px",paddingBottom:'10px'}}>    
    <p style={{fontFamily: "poppins",fontSize: "14px",fontWeight: 400,marginBottom: "20px",borderBottom: "2px solid #088183",}}>Plan Details</p>
     <Flex gap={'20px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'} pb={'20px'}>
        <Box>
            <Text>Policy Limit</Text>
            <Text w={'180px'} size='xs'> {ele.policyLimit}</Text>
        </Box>
        <Box>
          <Text>Deductible</Text>
          <Text w={'180px'} size='xs'> {'value'}</Text>
        </Box>  
        <Box>
            <Text>Plan Type</Text>
            <Text w={'180px'} size='xs'> {'sum insured'}</Text>
        </Box>
        <Box>
          <Text>Super Visa</Text>
          <Text w={'180px'} size='xs'> {ele.superVisa===0?'YES':'NO'}</Text>
        </Box>  
        </Flex>
        {/* <Flex mt={'20px'} gap={'20px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'} pb={'20px'}>
        <Box>
            <Text>Policy Limit</Text>
            <Text w={'180px'} size='xs'> {'sum insured'}</Text>
            </Box>
            <Box>
            <Text>Deductible</Text>
            <Text w={'180px'} size='xs'> {'value'}</Text>
            </Box>  
        </Flex> */}
        <hr />
    </div>


    <Flex w={'95%'} m={'auto'} mb={'15px'} justifyContent={'space-between'} >
    <Box w={'55%'}>
    <TableContainer >
    <Table>
        <Thead>
            <Tr gap={'50px'}>
                <Th p={'7px'} fontSize={'10px'} fontWeight={'600'} fontFamily={'poppins'}>User Name</Th>
                <Th p={'7px'} fontSize={'10px'}>Plan Name</Th>
                <Th p={'7px'} fontSize={'10px'}>With <br/> Pre-existing</Th>
                <Th p={'7px'} fontSize={'10px'}>Without <br/> Pre-existing</Th>
            </Tr>
        </Thead>
        <Tbody>
            {
                ele.listOfInsured.map((item)=>(
                <Tr color={'grey'} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                    <Td p={'2px'}>{item.insuredName}</Td>
                    <Td p={'2px'}>{item.insuredPlan}</Td>
                    <Td p={'2px'} display={'flex'}>
                    <Text pl={'10px'}>{item.costWithPreExisting}</Text>
                    </Td>
                    <Td p={'2px'}>
                    <Text pl={'10px'}>{item.costWithPreExistingWithoutAdj}</Text>
                    </Td>
                </Tr>
                ))
            }
        </Tbody>
    </Table>
    </TableContainer>
    </Box>
    <Box w={'40%'}>
    <TableContainer>
        <Table>
            <Thead>
                <Tr>
                    <Th p={'7px'} fontSize={'10px'}>Net</Th>
                    <Th p={'7px'} fontSize={'10px'}>Tax</Th>
                    <Th p={'7px'} fontSize={'10px'}>Adjustment</Th>
                    <Th p={'7px'} fontSize={'10px'}>Total</Th>
                </Tr>
            </Thead>
            <Tbody>
                    <Tr color={'grey'} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                        <Td p={'2px'}>{ele.quoteAmount}</Td>
                        <Td p={'2px'}>0.00 CAD	</Td>
                        <Td p={'2px'} textAlign={'center'}>0.00 CAD	</Td>
                        <Td p={'2px'} textAlign={'center'} fontWeight={'600'}>{ele.quoteAmount}</Td>
                    </Tr>
            </Tbody>
        </Table>
    </TableContainer></Box>
    </Flex>


   <div style={{width: "100%",margin: "auto",padding: "18px",}}>  
        <p style={{fontFamily: "poppins",fontSize: "14px",fontWeight: 400,marginBottom: "20px",borderBottom: "2px solid #088183",}}>Contact Details</p>
            <Flex gap={'40px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} mt={'20px'} pl={'15px'}>
            <Box>
              <Text>Policy Owner*</Text>
              <Input color={'grey'} value={ele.policyHolderName} w={'200px'} size='xs' name="policyOwnerDob" border={'1px solid #d7d7d7'} />  
            </Box>
            <Box>
              <Text>Policy Owner Date Of Birth*</Text>
              <Input type='date' w={'200px'} size='xs' value={ele.policyHolderDob} readOnly border={'1px solid #d7d7d7'} />            
            </Box>
            <Box>
              <Text>Policy Owner Email*</Text>
              <Input placeholder='Email' w={'180px'} value={ele.policyHolderEmail} readOnly size='xs' border={'1px solid #d7d7d7'}/>    
            </Box>
            </Flex>
            <Flex gap={'40px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={'20px'} mt={'10px'} pl={'15px'}>
            <Box>
              <Text>Policy Owner Postal Code*</Text>
              <Input value={ele.policyHolderPostalCode} readOnly w={'200px'} size='xs' border={'1px solid #d7d7d7'}/>   
            </Box>
            <Box>
              <Text>Policy Owner City*</Text>
              <Input value={ele.policyHolderCity} readOnly placeholder='city' w={'200px'} size='xs' border={'1px solid #d7d7d7'} />   
            </Box>
            <Box>
              <Text>Policy Owner Phone*</Text>
              <Input value={ele.policyHolderPhone} readOnly placeholder='phone' w={'180px'} size='xs' border={'1px solid #d7d7d7'}/>            
            </Box>
            </Flex>
            <Box fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'}>
              <Text>Policy Owner Address*</Text>
              <Textarea value={ele.policyHolderAddress} readOnly w={'200px'} size='xs' border={'1px solid #d7d7d7'}/>     
            </Box>
            <Flex gap='40px'>
            </Flex></div>
    </div>
    ))}
    </>
  )
}

export default ViewQuote
