import React, { useEffect, useState } from 'react'
import CustomerHeader from '../../Common/Header/CustomerHeader'
import {Box,Flex,Image,Button,Text,useDisclosure,Input,Textarea} from '@chakra-ui/react'
import { buttonBackgroundColor, buttonColor, primaryColor } from '../../../Utils/UniversalColor'
import { useDispatch } from "react-redux";
import { customerEditProfile, customerProfile } from '../Dependencies/action';
import {Modal,ModalOverlay,ModalContent,ModalHeader,ModalFooter,ModalBody,ModalCloseButton,} from '@chakra-ui/react'
import { storage } from '../../../dependencies/store/storage';
import SideBox from '../../../Utils/SideBox/SideBox';


const CustomerProfile = () => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [profileData,setProfileData]=useState([])
  console.log(profileData,'data')
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();    
    console.log(formData)
    dispatch(customerEditProfile(formData)).then(res => {
      const {status, data} = res
      console.log(res)
    })
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    });
  };

useEffect(()=>{
      dispatch(customerProfile()).then(res => {
        const {status, data} = res
        console.log(res)
        if(status === 200) {
            setProfileData([data])
        }
        
      })
},[])


const handleLogout = ()=>{
  storage.clear()
  window.location.href='/'
}



return (
    <div>
      {/* <CustomerHeader/> */}
      <SideBox/>
      <Box w={'70%'} m={'auto'} pt={'120px'} mb={'60px'}>
      <Flex justifyContent={'space-between'} gap={'15px'}>
        <Box><Text fontSize={'20px'} color={primaryColor}>Profile Information</Text></Box>
        <Flex gap={'40px'}>
              <Button onClick={onOpen} color={buttonColor} backgroundColor={buttonBackgroundColor}>Edit</Button>
              <Button onClick={handleLogout} color={buttonColor} backgroundColor={buttonBackgroundColor}>Logout</Button>
        </Flex>
            </Flex>
        <Flex justifyContent={'space-around'} borderTop={'2px solid teal'} mt={'10px'}>
          <Box w={'30%'} mt={'50px'}>
          <Image borderRadius={'50%'} border={'1px solid grey'} w={'200px'} src='https://www.whitechapelgallery.org/wp-content/uploads/2020/07/blank-head-profile-pic-for-a-man.jpg'/>
            </Box> 
            {
              profileData && profileData.map((ele)=>(
                <>                
                <Box textAlign={'left'} w={'60%'}>
                  <Box pl={'30px'}>
                    <Flex pt={'30px'} gap={'40px'}>
                      <Box>
                        <Text>First Name</Text>
                        <Input backgroundColor={'#f1f1f1'} color={'grey'} value={ele.firstName} />
                      </Box>
                      <Box>
                        <Text> Last Name</Text>
                        <Input backgroundColor={'#f1f1f1'} color={'grey'} value={ele.lastName} />
                      </Box>
                    </Flex>
                    <Flex pt={'20px'} gap={'40px'}>
                      <Box>
                        <Text>Email</Text>
                        <Input backgroundColor={'#f1f1f1'} color={'grey'} value={ele.email}/>
                      </Box>
                      <Box>
                        <Text>Phone</Text>
                        <Input backgroundColor={'#f1f1f1'} color={'grey'} value={ele.phone} />
                      </Box>
                    </Flex>
                    <Box>
                  <Text mt={'10px'}>Address</Text>
                  <Textarea backgroundColor={'#f1f1f1'} color={'grey'}>{ele.address}</Textarea>
                    </Box>
                  </Box>
                </Box>
                </>
              ))
            }
        </Flex>
      </Box>


      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Profile</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <form onSubmit={handleSubmit}>
        <Text>First Name</Text>
        <Input 
          type="text" 
          id="firstName" 
          name="firstName" 
          value={formData.firstName} 
          onChange={handleChange} 
          required 
        /> <br />
      <Text>Last Name</Text>
        <Input 
          type="text" 
          id="lastName" 
          name="lastName" 
          value={formData.lastName} 
          onChange={handleChange} 
          required 
        /> <br />
        <label htmlFor="phone">Phone Number:</label><br />
        <Input 
          type="tel" 
          id="phone" 
          name="phone" 
          // placeholder="Format: 123-456-7890"
          value={formData.phone} 
          onChange={handleChange} 
          required 
        /><br /><br />
        <Flex justifyContent={'center'}>
        <Button mr={3} onClick={onClose}>
              Close
            </Button>
        <Button type='submit' colorScheme='teal' >Submit</Button>
        </Flex>
      </form>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default CustomerProfile
