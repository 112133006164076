import React from 'react'
import Sidebar from '../Sidebar'

const RefundRequests = () => {
  return (
    <Sidebar headingText={'Refund Requests'}>
      
    </Sidebar>
  )
}

export default RefundRequests
