import React from 'react'
import Sidebar from '../../Sidebar'

const AssignAdmin = () => {
    



return (
    <Sidebar headingText={'Assign Admin'}>
      
    </Sidebar>
  )
}

export default AssignAdmin
