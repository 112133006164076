import React, { useState } from 'react';
import Sidebar from '../Sidebar';
import { Box, Button, Flex, Image, Input, Text } from '@chakra-ui/react';


const AdminProfile = () => {


    return (
        <Sidebar headingText="Profile">
          <Box>
            <Flex w={'90%'} m={'auto'} mt={'60px'} justifyContent={'space-between'}>
                <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} p={'15px'}>
                    <Text>Edit Profile</Text>
                    <Text fontSize='12px'> Compolete your profile</Text>
                    <Box fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} p={'20px'} pb={'10px'}>
                        <Flex gap={'20px'} pb={'15px'}>
                            <Box>
                            <Text>username</Text>
                            <Input placeholder='Ravi' border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                            <Box>
                            <Text>Email</Text>
                            <Input placeholder='ravi@gmail.com' border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                            <Box>
                            <Text>Phone</Text>
                            <Input placeholder='12345678962' border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                        </Flex>
                        <Flex gap={'20px'} mt={'10px'} mb={'10px'}>
                        <Box>
                            <Text>Password</Text>
                            <Input placeholder='*******' border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                            <Box>
                            <Text>Confirm password</Text>
                            <Input placeholder='*******' border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                        </Flex>
                        <Flex justifyContent={'end'}>
                        <Button size={'sm'} fontSize={'14px'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'} mt={'15px'}>Update Profile</Button>
                        </Flex>
                    </Box>
                </Box>


                <Box w={'30%'} textAlign={'center'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} p={'15px'}>
                    <Flex alignItems={'center'} justifyContent={'center'} mt={'-40px'}>
                        <Image w={'70px'} src='https://travelmedicare.com/public/assets/admin-img/user.png' />
                    </Flex>
                        <Text pt={'15px'}>Admin</Text>
                        <Text fontFamily={'poppins'} pt={'10px'} fontSize={'14px'}>Ravi Sharma</Text>
                        <Text fontFamily={'Open Sans,sans-serif'} pt={'10px'} fontSize={'12px'}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters...</Text>
                        <Flex justifyContent={'center'}>
                        <Button size={'xs'} fontSize={'14px'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'} mt={'15px'}>Follow</Button>
                        
                    </Flex>
                </Box>


            </Flex>
          </Box>



        </Sidebar>
    );
}

export default AdminProfile;
