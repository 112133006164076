import React from 'react'
import { useLocation } from 'react-router-dom';

const GetQuickQuote = () => {
    const location = useLocation();
    const data = location && location.state ? location.state.data : null;
    console.log(data)


return (
    <div>
      
    </div>
  )
}

export default GetQuickQuote
