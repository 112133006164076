import {  AGA_MGA_GET_ALL, isLoading } from "../../../../constants/actionConstants";
import {  AgaMgaAllService } from "./service";

export const agaMgaAll = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AgaMgaAllService.agaMgaAll(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: AGA_MGA_GET_ALL,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };