import { Box, Button, Checkbox, Flex, Heading, Image, Input, Text, Textarea } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { buttonBackgroundColor, buttonColor, primaryBackgroundColor, primaryColor } from '../../Utils/UniversalColor'
import { FaArrowRight, FaStar} from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { IoIosGlobe } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import axios from 'axios';
import SideBox from '../../Utils/SideBox/SideBox';


const Enquiry = () => {
    const latitude = 28.453432537750142;
    const longitude = 77.06834227116423;
    const [submitStatus, setSubmitStatus] = useState(null);
  
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      message: '',
      permission: true, 
    });
  
    const [formErrors, setFormErrors] = useState({});
  
  
    useEffect(() => {
      document.title = 'Contact Us | Connect Today - Upscaller Awaits Your Contact. | Upscaller';
      const metaDescription = document.createElement('meta');
      metaDescription.name = 'Contact us - Upscaller Development Excellence';
      metaDescription.content = 'Get in touch with us for top-notch upscaler development services. Our expert team is ready to elevate your business. Contact Upscaller for customized solutions and seamless collaboration.';
      document.head.appendChild(metaDescription);
    
      return () => {
        document.head.removeChild(metaDescription);
      };
    }, []);
  
    const handleClick = () => {
      const googleMapsURL = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(googleMapsURL, '_blank');
    };
  
    const validateForm = () => {
      const errors = {};
  
      // Validate name
      if (!formData.name.trim()) {
        errors.name = 'Name is required';
      }
  
      // Validate email
      if (!formData.email.trim()) {
        errors.email = 'Email is required';
      } else if (!isValidEmail(formData.email)) {
        errors.email = 'Enter a valid email address';
      }
  
      // Validate phone
      if (!formData.phone.trim()) {
        errors.phone = 'Phone is required';
      } else if (!isValidPhone(formData.phone)) {
        errors.phone = 'Enter a valid phone number';
      }
  
      // Validate message
      if (!formData.message.trim()) {
        errors.message = 'Message is required';
      }
  
      // Validate permission checkbox
      if (!formData.permission) {
        errors.permission = 'You must grant permission to submit the form';
      }
  
      setFormErrors(errors);
      return Object.keys(errors).length === 0; // Return true if there are no errors
    };
  
    const isValidEmail = (email) => {
      // Use a regular expression to validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const isValidPhone = (phone) => {
      // Use a regular expression to validate phone number format
      const phoneRegex = /^[0-9]{10}$/;
      return phoneRegex.test(phone);
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      if (validateForm()) {
        try {
          const response = await axios.post(`'baseUrl'/contact/submit`, formData);
          setSubmitStatus('success');
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
            permission: true,
          });
        } catch (error) {
          console.error('Error:', error);
          setSubmitStatus('error');
        }
      } else {
        console.log('Form has validation errors');
      }
    };



return (
  <>
  <SideBox/>
    <Box pb={'130px'} pt={'60px'}>
        <Box color={'white'}>
            <Box backgroundColor={primaryBackgroundColor}>
            <Box w={'80%'} m={'auto'} pt={'30px'} pb={'30px'} h={'400px'}>
            <Text fontFamily={'sans-serif'} fontSize={'16px'}>At Travelmedicare, it is our constant endeavour to provide great customer experience. In case you require assistance, we have created multiple ways to reach out to us. We commit to resolving your queries to your satisfaction.</Text>
            </Box>  
            </Box>
            <Flex w={'85%'} m={'auto'} mt={'-250px'} justifyContent={'space-around'}>
                <Flex boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' background={'white'} p={'40px'} color={'black'} gap={'40px'} borderRadius={'20px'} w={'60%'}>
                <Image h={'180px'} src='https://static.pbcdn.in/cdn/images/contact-banner.svg'/>
                <Box fontFamily={'poppins'}>
                    <Heading fontSize={'20px'} color={primaryColor} fontFamily={'Assistant,sans-serif'}>My Account</Heading>
                    <Text pt={'7px'} fontSize={'16px'} fontFamily={'poppins'}>Fastest one stop service gateway</Text>

                    <Flex fontSize={'14px'} justifyContent={'space-between'} mt={'30px'} gap={'32px'}>
                        <Box>
                        <Flex gap={'15px'}>
                        <Flex alignItems={'center'}>
                        <FaStar />
                        </Flex>
                        <Text>Download policy</Text>
                        </Flex>
                        <Flex gap={'15px'} mt={'15px'}>
                        <Flex alignItems={'center'}>
                        <FaStar />
                        </Flex>
                        <Text>Share Feedback</Text>
                        </Flex>
                        </Box>
                        <Box>
                        <Flex gap={'15px'}>
                        <Flex alignItems={'center'}>
                        <FaStar />
                        </Flex>
                        <Text>Track Policy Status</Text>
                        </Flex>
                        <Flex gap={'15px'} mt={'15px'}>
                        <Flex alignItems={'center'}>
                        <FaStar />
                        </Flex>
                        <Text>Raise a query</Text>
                        </Flex>
                        </Box>
                    </Flex>
                <Button mt={'25px'} backgroundColor={buttonBackgroundColor} color={buttonColor} _hover={{backgroundColor:buttonBackgroundColor,color:buttonColor}}>Login to my Account</Button>
                </Box>
                </Flex>

            <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' background={'white'} p={'20px'} color={'black'} borderRadius={'20px'} >
                <Text fontSize={'16px'} fontFamily={'sans-serif'}>Need Help?</Text>
                <Text>Choose how you like to connect with us.</Text>
                <Flex fontFamily={'Assistant,sans-serif'} borderBottom={'1px solid rgba(42,63,85,.26)'} justifyContent={'space-between'} p={'10px'} pt={'20px'}>
                <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/individual.svg'/></Flex>
                    <Text>Request a call back</Text>
                    <Flex color={primaryColor} justifyContent={'center'} alignItems={'center'}>
                    <FaArrowRight />
                </Flex>
                    </Flex>
                    <Flex fontFamily={'Assistant,sans-serif'} borderBottom={'1px solid rgba(42,63,85,.26)'} justifyContent={'space-between'} p={'10px'} pt={'15px'}>
                <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/individual.svg'/></Flex>
                    <Text>Chat With Us</Text>
                    <Flex color={primaryColor} justifyContent={'center'} alignItems={'center'}>
                    <FaArrowRight />
                </Flex>
                    </Flex>
                    <Flex fontFamily={'Assistant,sans-serif'} borderBottom={'1px solid rgba(42,63,85,.26)'} justifyContent={'space-between'} p={'10px'} pt={'15px'}>
                <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/individual.svg'/></Flex>
                <Box>
                    <Text fontSize={'12px'}>Connect on whatspp</Text>
                    <Text>9306454204</Text>
                </Box>
                    <Flex fontSize={'20px'} color={primaryColor} justifyContent={'center'} alignItems={'center'}>
                    <FaArrowRight />
                </Flex>
                    </Flex>
            </Box>
            </Flex>
        </Box>


<Box >
    <Flex w={'80%'} m={'auto'} mt={'120px'} color={'#707070'} fontSize={'13px'} justifyContent={'space-between'}>
        <Box>
        <Flex justifyContent={'center'} pb={'15px'}>
        <FaLocationDot color={primaryColor} fontSize={'28px'}/>
        </Flex>
        <Text textAlign={'center'}>Quickinsure Insurance Brokers Pvt. Ltd. <br />
        5th Floor, Galore Tech IT Park, Bavdhan, Pune-411021</Text>
        </Box>
        <Box>
        <Flex justifyContent={'center'} pb={'15px'}>
        <MdCall color={primaryColor} fontSize={'28px'}/> </Flex>
        <Text>+91 9306454204</Text>
        </Box>
        <Box>
        <Flex justifyContent={'center'} pb={'15px'}>
        <MdEmail color={primaryColor} fontSize={'28px'} /> </Flex>
        <Text>support@travelmedicare.com</Text>
        </Box>
        <Box>
        <Flex justifyContent={'center'} pb={'15px'}>
        <IoIosGlobe color={primaryColor} fontSize={'28px'} /> </Flex>
        <Text>www.travelmedicare.com</Text>
        </Box>
    </Flex>
</Box>

<Box w={'85%'} m={'auto'} mt={'120px'}>
    <Flex>
        <Box>
            <Image src='https://t3.ftcdn.net/jpg/05/17/80/98/360_F_517809818_Z6GTKrBUpHyOdsvC3Fyt05Qe1Tm5oOr4.jpg' />
        </Box>
        <Box borderRadius={10} textAlign={'left'} w={['95%', '60%', '60%', '60%']} m={'auto'} h={'auto'} pb={'20px'} mt={'10px'}>
              <Box w={['92%', '92%', '62%', '92%']} m={'auto'} mt={'30px'}>
                <Box w={['90%', '90%', '60%', '82%']} m='auto' mt={'15px'}>
                  <form onSubmit={handleSubmit}>
                    <Flex>
<Box w={'50%'}> 
                    <label>
                      Name
                      <br />
                      <Input
                        type="text"
                        name="name"
                        w={'95%'}
                        placeholder="Name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                      {formErrors.name && (
                          <Text color="red" fontSize="sm">
                          {formErrors.name}
                        </Text>
                      )}
                    </label>
                    </Box>
                    <Box w={'50%'}>
                    <label>
                      Phone
                      <br />
                      <Input
                        type="text"
                        mb={'10px'}
                        w={'95%'}
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        />
                      {formErrors.phone && (
                          <Text color="red" fontSize="sm">
                          {formErrors.phone}
                        </Text>
                      )}
                    </label>
                    </Box>
                      </Flex>
                    <label>
                      Email <br />
                      <Input
                        mb={'15px'}
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                      {formErrors.email && (
                        <Text color="red" fontSize="sm">
                          {formErrors.email}
                        </Text>
                      )}
                    </label>
                    <label>
                      Message <br />
                      <Textarea
                        mb={'15px'}
                        type="text"
                        name="message"
                        placeholder="Enter your message"
                        value={formData.message}
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                      />
                      {formErrors.message && (
                        <Text color="red" fontSize="sm">
                          {formErrors.message}
                        </Text>
                      )}
                    </label>
                    {/* <div> */}
                      {/* <label>
                        <Checkbox
                          paddingRight={'10px'}
                          marginTop={'5px'}
                          fontSize={['12px', '16px', '16px', '12px']}
                          type="checkbox"
                          name="permission"
                          checked={formData.permission}
                          onChange={(e) => setFormData({ ...formData, permission: e.target.checked })}
                        />

                      </label>
                      {formErrors.permission && (
                        <Text color="red" fontSize="sm">
                          {formErrors.permission}
                        </Text>
                      )} */}
                    {/* </div> */}
                    <div>
                      <Button
                        mt={'10px'}
                        background={buttonBackgroundColor}
                        color={buttonColor}
                        minH={'2.5rem'}
                        p={'.4375rem 1.5rem .5625rem'}
                        borderRadius={'1.25rem'}
                        type='submit'
                       
                      >
                        Submit
                      </Button>
                      {submitStatus === 'success' && (
                        <Text color="green" pt={'10px'}>
                         Thank you, {formData.name.split(' ')[0]}! Your submission was successful.
                        </Text>
                      )}
                      {submitStatus === 'error' && (
                        <Text color="red" pt={'10px'}>
                          Submission failed. Please try again.
                        </Text>
                      )}
                    </div>
                  </form>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
    </Box>
    </>
  )
}

export default Enquiry
