import React, { useEffect, useState } from 'react'
import {Button,Checkbox,Input,Select,Box,Flex,Text,TableContainer,Table,Thead,Tr,Th,Td,Tbody,Stack, Textarea,Tooltip } from "@chakra-ui/react";
import Api from '../../../dependencies/utils/Api';
import { VTCDetailsModal, getMaxSelectableDate } from '../../Advisor/AdvisorQuote/Validation';
import { IoMdAdd, IoMdCloseCircle } from 'react-icons/io';
import { buttonBackgroundColor } from '../../../Utils/UniversalColor';
import RadioOption from '../../../Utils/RadioCard';



const EditSuperVisaQuote = ({id}) => {
    const [data,setData]=useState([])
    const [waitingPeriod,setWaitingPeriod]=useState('')
    const [familyCoverage,setFamilyCoverage]=useState(false)
    const [policyLimit, setPolicyLimit] = useState('');
    const [deductile,setDeductile]=useState ('')
    const [paymentFrequency,setPaymentFrequency]=('')
    const [duration,setDuration]=useState(0)
    const [PlanType,setPlanType]=useState('Standard')
    const [isVtcModalOpen, setIsVtcModalOpen] = useState(false);
    const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState('1')
    const [formData,setFormData]=useState({
        firstDateOfCover:'',
        lastDateOfCover:'',
        clientCountry: 'canada',
        clientProvince: '',
        departureFromCountryOfOrigin:'',
        quotationNo:{id},
        policyOwner: '',
        policyOwnerDateOfBirth: '',
        policyOwnerEmail: '',
        policyOwnerAddress: '',
        policyOwnerPostalCode: '',
        policyOwnerCity: '',
        policyOwnerPhone: ''
    })
    const [addTravelers, setAddTravelers] = useState([
        {
        name: "",
        gender: "",
        dob: " ",
        relation: "",
        benificiaryDob: "",
        benificiaryName: "",
        },
    ]);
    
useEffect(()=>{
    Api.GET(`http://192.168.1.6:8080/Quote/getQuoteByQuotationNo/${id}`).then((res)=>{
        console.log(res,'res')
        setData([res.data])
    })
},[])


useEffect(() => {
    const startDate = new Date(formData.firstDateOfCover);
    const endDate = new Date(startDate);
    if (formData.firstDateOfCover) {
      endDate.setFullYear(startDate.getFullYear() + 1);
      setFormData((prevData) => ({
        ...prevData,
        lastDateOfCover: endDate.toISOString().split("T")[0],
      }));
    }
    setDuration(365)
  }, [formData.firstDateOfCover]);

const handleCheckbox = () => {
    setFamilyCoverage(true);
};

  const handleChangeTraveler = (index, e) => {
    const { name, value } = e.target;
    if (name === "dob" || name === "benificiaryDob") {
        const selectedDate = new Date(value);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 15);
        if (selectedDate >= minDate) {
            alert("Users must be at least 15 days older than today.");
            return;
        }
    }
    setAddTravelers((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = { ...updatedState[index], [name]: value };
        return updatedState;
    });
  };

  const handleAddTraveller = () => {
    if (addTravelers.length < 3) {
      setAddTravelers([
        ...addTravelers,
        {
          name: "",
          gender: "",
          dob: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
          // costWithoutPreExisting:"",
          // costWithPreExisting:""
        },
      ]);
    } else {
        console.log('r')
    //   toast({ title: "you can not add more than 3 family members." });
    }
  };
  
  const handleRemoveTraveller = (index) => {
    const updatedTravelers = [...addTravelers];
    updatedTravelers.splice(index, 1);
    setAddTravelers(updatedTravelers);
  };

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "firstDateOfCover") {
        const startDate = new Date(value);
        const departureMaxDate = new Date(startDate);
        departureMaxDate.setDate(departureMaxDate.getDate());
        const formattedMaxDate = departureMaxDate.toISOString().split("T")[0];
        setFormData((prevData) => ({
          ...prevData,
          lastDateOfCover: formattedMaxDate,
        }));
    }
  
    if (name === "departureFromCountryOfOrigin") {
      if (!formData.firstDateOfCover) {
        alert("Please select the first date of cover");
        setFormData({ ...formData, departureFromCountryOfOrigin: "" });
        return;
    }
    }
  };

const handlePolicyLimitChange = (value) => {
    setPolicyLimit(value);
};
const handleDeductiblesChange = (value) => { 
  setDeductile(value);
};
const handlePlanTypeChange = (value) => { 
  setPlanType(value);
};

const handleCalculate = ()=>{
    setIsVtcModalOpen(true);
}





const handleVtcModalSubmit = async () => {
        const updatedTravelers = addTravelers.map((traveler) => ({
          insuredName: traveler.name,
          insuredGender: traveler.gender,
          insuredDOB: traveler.dob,
          insuredRelation: traveler.relation,
          beneficiaryName: traveler.benificiaryName,
          beneficiaryDob: traveler.benificiaryDob,
        }));
      const dataToLog = {
          policyStartDate: formData.firstDateOfCover,
          policyEndDate: formData.lastDateOfCover,
          deductible:parseInt(deductile),
          sumInsured:parseInt(policyLimit),
          planType:PlanType,
          departureDate: formData.departureFromCountryOfOrigin,
          supervisa: true,
          insuredDetailsRequests: updatedTravelers,
          policyType:'Supervisa',
          familyPolicy:familyCoverage,
          paymentFrequency:policyTypeSuperVisa
      };
      console.log(dataToLog)
        // dispatch(advisorCalculateVtc(dataToLog)).then(res => {
        //   const {status, data} = res
        //   setData(data)
        //   console.log(res,'data')
        //   updateAddTravelers(data.listOfInsuredQutation)
        // })
  };

  const handleVtcModalClose = ()=>{
    setIsVtcModalOpen(false)
  }


return (
    <> <Text>Edit SuperVisa Quote</Text>
    {data && data.map(ele=>(  
    <div style={{backgroundColor:"rgb(251 251 251)", width: "100%", padding: "10px",paddingTop:'0px',textAlign:'left', border:'2px solid #c4c4c4'}}>
    <div style={{ display: "flex",flexDirection: "column",alignItems: "center",width:'99%',margin:'auto'}}>
        <div
            style={{
            width: "100%",
            margin: "auto",
            padding: "18px",
            }}
        >
            <Flex justifyContent={'space-between'} borderBottom={'2px solid #088183'} mb={'15px'} pb={'12px'} alignItems={'center'}>
            <p style={{ fontFamily: "poppins", fontSize: "16px", fontWeight: 400}}>Trip Details</p>
            <Button backgroundColor={'teal'} color={'white'} size={'sm'} _hover={{color:'white'}}>Proceed</Button>
            </Flex>
            <Box fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'}>
            <Flex gap={'40px'} fontSize={'13px'} pb={'20px'}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>First Date of Cover</span>
                <Input w={'180px'} value={ele.firstDateOfCover} name='firstDateOfCover' onChange={handleChange}  size='xs' type='date' border={'1px solid #d7d7d7'} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Last Date of Cover</span>
                <Input value={ele.lastDateOfCover}   w={'180px'} size='xs' type='date' border={'1px solid #d7d7d7'}/>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Duration</span>
                <Input w={'80px'} value={ele.duration} size='xs' backgroundColor={'#eaeaea'} border={'1px solid #d7d7d7'} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Country</span>
                <p> Canada</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Province</span>
                <p>{ele.policyHolderProvince}</p>
            </div>
            </Flex>
            <Flex gap={'40px'} fontSize={'14px'}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Departure from country of origin</span>
                <Input name='departureFromCountryOfOrigin' value={ele.departureFromCountryOfOrigin} w={'180px'} size='xs' onChange={handleChange} type='date' border={'1px solid #d7d7d7'} />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Waiting Period</span>
                <input
                value={ele.waitingPeriod}
                 
                />
            </div>
            <Box >
                <Text>Family Coverage</Text>
            <Checkbox onChange={handleCheckbox} checked={ele.familyCoverage===0?true:false} pt={'7px'} size="md" colorScheme="green"></Checkbox>
            </Box>
            </Flex>
            </Box>
        </div>
    </div>


    <div style={{
        width: "100%",
        margin: "auto",
        padding: "18px",}}>
    <p style={{fontFamily: "poppins",fontSize: "14px",fontWeight: 400,marginBottom: "20px",borderBottom: "2px solid #088183",}}>Traveller Details</p>

    {addTravelers.map((item, index) => {
              return (                
              <Box backgroundColor={'#f3f3f3'} border={'1px solid #80808026'} p={'20px'} m={'auto'} mt={'7px'} key={index} id={`Insured-details-${index}`} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
                  <Flex gap={'20px'}>
                    <Box>
                      <Text fontWeight={'700'}>Name Of Insured</Text>
                      <Input background={'white'} w={'280px'} size='xs' border={'1px solid #d7d7d7'}
                       name="name"
                       value={item.name}
                       onChange={(e) => handleChangeTraveler(index, e)}
                      />            
                    </Box>
                    <Box>
                      <Text fontWeight={'700'}>Gender</Text>
                      <Select w={'280px'} background={'white'} size={'xs'} border={'1px solid #d7d7d7'}
                       value={item.gender}
                       name="gender"
                       onChange={(e) => handleChangeTraveler(index, e)}>
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
                    </Box>
                    <Tooltip hasArrow label='Age must between 15 days and 89 years' bg='gray.300' color='black' placement='top-start'>
                    <Box>
                      <Text fontWeight={'600'}>Date of Birth</Text>
                      <Input type='date' background={'white'} w={'180px'} size='xs' border={'1px solid #d7d7d7'}name="dob"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.dob} 
                      max={getMaxSelectableDate()}
                      />            
                    </Box>
                      </Tooltip>
                  </Flex>
                  <Flex gap={'20px'} mt={'10px'}>
                    <Box>
                      <Text fontWeight={'700'}>Beneficiary Name</Text>
                      <Input background={'white'} w={'280px'} size='xs' border={'1px solid #d7d7d7'}
                      name="benificiaryName"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.benificiaryName}/>            
                    </Box>
                    <Box>
                      <Text fontWeight={'700'}>Relation</Text>
                      <Input background={'white'} w={'280px'} size='xs' border={'1px solid #d7d7d7'}
                      name="relation"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.relation}
                      />            
                    </Box>  
                    <Box>
                      <Text fontWeight={'600'}>Beneficiary DOB</Text>
                      <Input type='date' background={'white'} w={'180px'} size='xs' border={'1px solid #d7d7d7'} onChange={(e) => handleChangeTraveler(index, e)}
                      name="benificiaryDob"
                      value={item.benificiaryDob}
                      max={getMaxSelectableDate()}/>            
                    </Box>
                  </Flex>
                  {index > 0 && (
                          <Flex justifyContent={'end'}>
                            <IoMdCloseCircle
                              style={{fontSize:'22px',color:buttonBackgroundColor}}
                              onClick={() => handleRemoveTraveller(index)}
                              // onClick={() => handleRemoveDependent(index)}
                            />
                          </Flex>
                        )}
                </Box>
                );
              })}
              <Flex fontFamily={'poppins'} fontSize={'12px'} gap={'10px'} justifyContent={'end'} cursor={'pointer'} color={'teal'} mt={'10px'} mb={'10px'}>
                <Flex alignItems={'center'}>
                  <IoMdAdd style={{fontWeight:'bold'}}/>
                    </Flex>
                  <Text onClick={handleAddTraveller}>Add a Spouse Or Dependent</Text>
              </Flex>
    </div>

    <Flex gap={'70px'} mt={'15px'} mb={'20px'}>
                  <Box>
                    <Text fontSize={'14px'} mt={'15px'} mb={'7px'}>Policy Limit</Text>
                    <Stack spacing={4} direction='row'>
                          <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                          $100000
                          </RadioOption>
                          <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                          $150000
                          </RadioOption>
                          <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                          $300000
                          </RadioOption>
                    </Stack>
                  </Box>
                  <Box>
                    <Text fontSize={'14px'} mt={'15px'} mb={'7px'}>Select Plan Type</Text>
                    <Stack spacing={4} direction='row'>
                      <RadioOption value='Standard' isSelected={PlanType === 'Standard'} onChange={handlePlanTypeChange}>
                        Standard</RadioOption>
                      <RadioOption value='Enhanced' isSelected={PlanType === 'Enhanced'} onChange={handlePlanTypeChange}>
                        Enhanced</RadioOption>
                      <RadioOption value='Premium' isSelected={PlanType === 'Premium'} onChange={handlePlanTypeChange}>
                       Premium </RadioOption>
                    </Stack>
                  </Box>
                  </Flex>
                  
                  {
                    PlanType === 'Premium'? 
                    <Box mb={'30px'}>
                    <Text fontSize={'14px'} >Deductible</Text>
                    <Stack spacing={4} direction='row'>
                      <RadioOption value='500' isSelected={deductile === '500'} onChange={handleDeductiblesChange}>
                      $500</RadioOption>
                      <RadioOption value='1000' isSelected={deductile === '1000'} onChange={handleDeductiblesChange}>
                      $1000</RadioOption>
                      <RadioOption value='3000' isSelected={deductile === '3000'} onChange={handleDeductiblesChange}>
                      $3000</RadioOption>
                    </Stack>
                  </Box>:
                      <Box mb={'30px'}>
                      <Text fontSize={'14px'} >Deductible</Text>
                      <Stack spacing={4} direction='row'>
                        <RadioOption value='0' isSelected={deductile === '0'} onChange={handleDeductiblesChange}>
                        $0</RadioOption>
                        <RadioOption value='100' isSelected={deductile === '100'} onChange={handleDeductiblesChange}>
                        $100</RadioOption>
                        <RadioOption value='250' isSelected={deductile === '250'} onChange={handleDeductiblesChange}>
                        $250 </RadioOption>
                        <RadioOption value='500' isSelected={deductile === '500'} onChange={handleDeductiblesChange}>
                        $500</RadioOption>
                        <RadioOption value='1000' isSelected={deductile === '1000'} onChange={handleDeductiblesChange}>
                        $1000</RadioOption>
                        <RadioOption value='3000' isSelected={deductile === '3000'} onChange={handleDeductiblesChange}>
                        $3000</RadioOption>
                      </Stack>
                    </Box>
                  }
                  <Flex alignItems={'center'} gap={'50px'}>
                  <Box mb={'50px'} w={'40%'}>
                  <Text mb={'5px'} fontSize={'12px'}>Student Policy Option</Text>
                            <Select size={'xs'} value={policyTypeSuperVisa} onChange={(event) => setPolicyTypeSuperVisa(event.target.value)}>
                              <option value="1">One lump sum</option>
                              <option value="2">Monthly</option>
                            </Select>
                  </Box>
                    <Box>
                    <button style={{backgroundColor:'teal',color:'white',padding:'5px',borderRadius:'10px',fontSize:'12px'}} onClick={handleCalculate}>Calculate Premium</button>
                    </Box>
                  </Flex>

    {/* <div style={{width: "100%",margin: "auto",padding: "18px",paddingBottom:'10px'}}>    
    <p style={{fontFamily: "poppins",fontSize: "14px",fontWeight: 400,marginBottom: "20px",borderBottom: "2px solid #088183",}}>Plan Details</p>
     <Flex gap={'20px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'}>
        <Box>
            <Text>Policy Limit</Text>
            <Text w={'180px'} size='xs'> {'sum insured'}</Text>
        </Box>
        <Box>
          <Text>Deductible</Text>
          <Text w={'180px'} size='xs'> {'value'}</Text>
        </Box>  
        <Box>
            <Text>Plan Type</Text>
            <Text w={'180px'} size='xs'> {'sum insured'}</Text>
        </Box>
        <Box>
          <Text>Super Visa</Text>
          <Text w={'180px'} size='xs'> {ele.superVisa===0?'YES':'NO'}</Text>
        </Box>  
        </Flex>
        <Flex mt={'20px'} gap={'20px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'} pb={'20px'}>
        <Box>
            <Text>Policy Limit</Text>
            <Text w={'180px'} size='xs'> {'sum insured'}</Text>
            </Box>
            <Box>
            <Text>Deductible</Text>
            <Text w={'180px'} size='xs'> {'value'}</Text>
            </Box>  
        </Flex>
        <hr />
    </div> */}


    <Flex w={'95%'} m={'auto'} mb={'15px'} justifyContent={'space-between'} >
    <Box w={'55%'}>
    <TableContainer >
    <Table>
        <Thead>
            <Tr gap={'50px'}>
                <Th p={'7px'} fontSize={'10px'} fontWeight={'600'} fontFamily={'poppins'}>User Name</Th>
                <Th p={'7px'} fontSize={'10px'}>Plan Name</Th>
                <Th p={'7px'} fontSize={'10px'}>With <br/> Pre-existing</Th>
                <Th p={'7px'} fontSize={'10px'}>Without <br/> Pre-existing</Th>
            </Tr>
        </Thead>
        <Tbody>
            {
                ele.listOfInsured.map((item)=>(
                <Tr color={'grey'} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                    <Td p={'2px'}>{item.insuredName}</Td>
                    <Td p={'2px'}>{item.insuredPlan}</Td>
                    <Td p={'2px'} display={'flex'}>
                    <Text pl={'10px'}>{item.costWithPreExisting}</Text>
                    </Td>
                    <Td p={'2px'}>
                    <Text pl={'10px'}>{item.costWithPreExistingWithoutAdj}</Text>
                    </Td>
                </Tr>
                ))
            }
        </Tbody>
    </Table>
    </TableContainer>
    </Box>
    <Box w={'40%'}>
    <TableContainer>
        <Table>
            <Thead>
                <Tr>
                    <Th p={'7px'} fontSize={'10px'}>Net</Th>
                    <Th p={'7px'} fontSize={'10px'}>Tax</Th>
                    <Th p={'7px'} fontSize={'10px'}>Adjustment</Th>
                    <Th p={'7px'} fontSize={'10px'}>Total</Th>
                </Tr>
            </Thead>
            <Tbody>
                    <Tr color={'grey'} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                        <Td p={'2px'}>{ele.quoteAmount}</Td>
                        <Td p={'2px'}>0.00 CAD	</Td>
                        <Td p={'2px'} textAlign={'center'}>0.00 CAD	</Td>
                        <Td p={'2px'} textAlign={'center'} fontWeight={'600'}>{ele.quoteAmount}</Td>
                    </Tr>
            </Tbody>
        </Table>
    </TableContainer></Box>
    </Flex>


   <div style={{width: "100%",margin: "auto",padding: "18px",}}>  
        <p style={{fontFamily: "poppins",fontSize: "14px",fontWeight: 400,marginBottom: "20px",borderBottom: "2px solid #088183",}}>Contact Details</p>
            <Flex gap={'40px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} mt={'20px'} pl={'15px'}>
            <Box>
              <Text>Policy Owner*</Text>
              <Input color={'grey'} value={ele.policyHolderName} w={'200px'} size='xs' name="policyOwnerDob" border={'1px solid #d7d7d7'} />  
            </Box>
            <Box>
              <Text>Policy Owner Date Of Birth*</Text>
              <Input type='date' w={'200px'} size='xs' value={ele.policyHolderDob}   border={'1px solid #d7d7d7'} />            
            </Box>
            <Box>
              <Text>Policy Owner Email*</Text>
              <Input placeholder='Email' w={'180px'} value={ele.policyHolderEmail}   size='xs' border={'1px solid #d7d7d7'}/>    
            </Box>
            </Flex>
            <Flex gap={'40px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={'20px'} mt={'10px'} pl={'15px'}>
            <Box>
              <Text>Policy Owner Postal Code*</Text>
              <Input value={ele.policyHolderPostalCode}   w={'200px'} size='xs' border={'1px solid #d7d7d7'}/>   
            </Box>
            <Box>
              <Text>Policy Owner City*</Text>
              <Input value={ele.policyHolderCity}   placeholder='city' w={'200px'} size='xs' border={'1px solid #d7d7d7'} />   
            </Box>
            <Box>
              <Text>Policy Owner Phone*</Text>
              <Input value={ele.policyHolderPhone}   placeholder='phone' w={'180px'} size='xs' border={'1px solid #d7d7d7'}/>            
            </Box>
            </Flex>
            <Box fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'}>
              <Text>Policy Owner Address*</Text>
              <Textarea value={ele.policyHolderAddress}   w={'200px'} size='xs' border={'1px solid #d7d7d7'}/>     
            </Box>
            <Flex gap='40px'>
            </Flex></div>
    </div>
    ))}

<VTCDetailsModal isOpen={isVtcModalOpen} onClose={handleVtcModalClose} onSubmit={handleVtcModalSubmit} />
    </>
  )
}

export default EditSuperVisaQuote
