import React, { useState } from 'react'
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Table, Thead, Th, Tr, Td, Tbody, TableContainer, Button, useToast, Select, Text } from '@chakra-ui/react'

import { useDispatch, useSelector } from 'react-redux';
import { primaryColor, primaryBackgroundColor, buttonColor, buttonBackgroundColor } from "../../Utils/UniversalColor";
import { StudentVisa, SuperVisa, VisitorVisa } from './Dependencies/action';
import { getMaxSelectableDate } from '../Advisor/AdvisorQuote/Validation';
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';




const NewCode = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const SuperVisadata = useSelector((state) => state.superVisaQuote);
  const Visitorquotedata = useSelector((state) => state.vistorQuote);
  // console.log("Visitorquotedata", Visitorquotedata)
  const Studentquotedata = useSelector((state) => state.studentQuote);
  console.log("studentquickqoute", Studentquotedata)


  const groupedData = {
    Standard: [],
    Enhanced: [],
    Premium: []
  };

  SuperVisadata.superVisaQuote.forEach(item => {
    item.list.forEach(listItem => {
      groupedData[listItem.planType].push(listItem);
    });
  });

  const [formData, setFormData] = useState({
    policyStartDate: "",
    policyEndDate: "",
    insuredDetailsRequests: [{ insuredDOB: "" }],
    email: "",
  });

  const [errors, setErrors] = useState({
    policyStartDate: "",
    policyEndDate: "",
    insuredDOB: "",
    email: "",
  });

  const [data, setData] = useState([]);
  const [numTravelers, setNumTravelers] = useState(1);
  const [travelerAges, setTravelerAges] = useState(Array(1).fill(""));
  const [showTable, setShowTable] = useState(false);
  const [policyForm, setPolicyForm] = useState("Super Visa");
  const [showSuperVisaTable,setShowSuperVisaTable]=useState(false)
  const [showVisitorTable,setShowVisitorTable]=useState(false)
  const [showStudentTable,setShowStudentTable]=useState(false)
  const navigate = useNavigate();

  const handleGetQuickQuote = (data) => {
    console.log(data,'passed')
    // Programmatically navigate to GetQuickQuote component with data
    navigate('/get-quick-quote', { state: { data: data } });
  }

  // const superVisaDataExists = groupedData && groupedData["Super Visa"] && groupedData["Super Visa"].length > 1;
  //   if (!superVisaDataExists) {
  //     return null;
  //   }

  const policyStartDate = new Date(formData.policyStartDate);
  const newEnd = formData.policyStartDate
    ? new Date(policyStartDate.setFullYear(policyStartDate.getFullYear() + 1))
    : null;

  // const policyEndDate=new Date(formData.EndDate)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [e.target.name]: "" });
    if (name.startsWith("travelerAge")) {
      const index = parseInt(name.substring(11));
      const newTravelerAges = [...travelerAges];
      newTravelerAges[index] = value;
      setTravelerAges(newTravelerAges);
    } else {
      setFormData({
        ...formData,
        [name]: value,
        policyEndDate: newEnd,

      });
    }
  };

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChangeVisitor = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("travelerAge")) {
      const index = parseInt(name.substring(11));
      const newTravelerAges = [...travelerAges];
      newTravelerAges[index] = value;
      setTravelerAges(newTravelerAges);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  const handleChangeStudent = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("travelerAge")) {
      const index = parseInt(name.substring(11));
      const newTravelerAges = [...travelerAges];
      newTravelerAges[index] = value;
      setTravelerAges(newTravelerAges);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit1 = async (name) => {
    try {
      const { policyStartDate, policyEndDate, email } = formData;
      console.log(formData, 'data')
      let newErrors = { ...errors };
      if (policyStartDate.length === 0) {
        newErrors.policyStartDate = "Please enter your first name.";
      }
      if (policyEndDate === 0) {
        newErrors.policyEndDate = "Please enter a valid end date";
      }
      if (email.length === 0 || !validateEmail(email)) {
        newErrors.email = "Please enter a valid email address.";
      }
      if (formData.insuredDetailsRequests.some(traveler => !traveler.insuredDOB)) {
        newErrors.insuredDOB = "Please enter a valid date of birth for all travelers";
      } else {
        newErrors.insuredDOB = "";
      }
      if (Object.values(newErrors).some((error) => error !== "")) {
        setErrors(newErrors);
        return;
      }

      let res;
      if (
        name === "Supervisa" ||
        name === "Visitorvisa" ||
        name === "StudentVisa"
      ) {
        await dispatch(
          name === "Supervisa" ? SuperVisa(formData) : StudentVisa(formData)

        );
      }

      setFormData({
        policyStartDate: "",
        policyEndDate: "",
        insuredDetailsRequests: [{ insuredDOB: "" }],
        email: "",
      });
      setShowSuperVisaTable(true)
      toast({
        title: "Super Visa Quickquote generated",
      });
      setErrors({
        policyStartDate: "",
        policyEndDate: "",
        email: "",
        insuredDetailsRequests: "",
      });
    } catch (error) {
      toast({
        title: "Invalid Details",
      });
      // window.location.reload();
    }
  };
  const handleSubmit2 = async (name) => {
    try {
      const { policyStartDate, policyEndDate, email } = formData;
      console.log(formData, 'vistiro')
      let newErrors = { ...errors };
      if (policyStartDate.length === 0) {
        newErrors.policyStartDate = "Please enter a valid date";
      }
      if (policyEndDate.length === 0) {
        newErrors.policyEndDate = "Please enter a valid date";
      }
      if (email.length === 0) {
        newErrors.email = "Please enter a valid email";
      }
      if (Object.values(newErrors).some((error) => error !== "")) {
        setErrors(newErrors);
        return;
      }

      let res;
      if (
        name === "Supervisa" ||
        name === "Visitorvisa" ||
        name === "StudentVisa"
      ) {
        await dispatch(
          name === "Visitorvisa" ? VisitorVisa(formData) : StudentVisa(formData)

        );
      }

      setFormData({
        policyStartDate: "",
        policyEndDate: "",
        insuredDetailsRequests: [{ insuredDOB: "" }],
        email: "",
      });
      setShowVisitorTable(true)
      toast({
        title: "Visitor Insuarance Quickquote generated",
      });
    } catch (error) {
      toast({
        title: "Invalid Details",
      });
      // window.location.reload();
    }
  };
  const handleSubmit3 = async (name) => {
    try {
      const { policyStartDate, policyEndDate, email,insuredDetailsRequests } = formData;
      console.log(formData, 'student')
      let newErrors = { ...errors };
      if (policyStartDate.length === 0) {
        newErrors.policyStartDate = "Please enter a valid date";
      }
      if (policyEndDate.length === 0) {
        newErrors.policyEndDate = "Please enter a valid date";
      }
      if (email.length === 0) {
        newErrors.email = "Please enter a valid email";
      }
      if (insuredDetailsRequests.length === 0) {
        newErrors.insuredDetailsRequests = "Please enter a valid DOB";
      }
      if (Object.values(newErrors).some((error) => error !== "")) {
        setErrors(newErrors);
        return;
      }

      let res;
      if (
        name === "Supervisa" ||
        name === "Visitorvisa" ||
        name === "StudentVisa"
      ) {
        await dispatch(
          name === "StudentVisa" ? StudentVisa(formData) : SuperVisa(formData)

        );
      }

      setFormData({
        policyStartDate: "",
        policyEndDate: "",
        insuredDetailsRequests: [{ insuredDOB: "" }],
        email: "",
      });
      setShowStudentTable(true)
      toast({
        title: "Student Insurance Quickquote generated",
      });
    } catch (error) {
      toast({
        title: "Invalid Details",
      });
      // window.location.reload();
    }
  };


  const handleAddTraveler = (e) => {
    e.preventDefault();
    if (numTravelers < 5) {
      setNumTravelers(numTravelers + 1);
      setTravelerAges([...travelerAges, ""]);
      setFormData({
        ...formData,
        insuredDetailsRequests: [
          ...formData.insuredDetailsRequests,
          { insuredDOB: "" },
        ],
      });
    }
  };
  const handleTravelerDOBChange = (index, dob) => {
    const newInsuredDetailsRequests = [...formData.insuredDetailsRequests];
    newInsuredDetailsRequests[index].insuredDOB = dob;
    if (dob) {
      const selectedDate = new Date(dob);
      const minDate = new Date();
      minDate.setDate(minDate.getDate() - 15);
      if (selectedDate >= minDate) {
        alert("Users must be at least 15 days older than today.");
        return;
      }
    }
    setFormData({
      ...formData,
      insuredDetailsRequests: newInsuredDetailsRequests,
    });
    // handleSubmit1("Supervisa")
  };

  const handleRemoveTraveler = (e) => {
    e.preventDefault();
    if (numTravelers > 1) {
      setNumTravelers(numTravelers - 1);
      setTravelerAges(travelerAges.slice(0, -1));
    }
  };

  const handleTravelerAgeChange = (index, age) => {
    const newTravelerAges = [...travelerAges];
    newTravelerAges[index] = age;
    setTravelerAges(newTravelerAges);
  };

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  const handlePolicyForm = (form) => {
    setPolicyForm(form);
  };

  const deductibleDiscounts = {
    0: "Base",
    100: 5,
    500: 15,
    1000: 20,
    3000: 30
  };

  const calculateDiscount = (costWithPreExisting, costWithoutPreExisting, deductibleBeans) => {
    let discount = 0;
    const cost = parseInt(costWithPreExisting || costWithoutPreExisting);

    // Check if the cost is greater than or equal to each threshold
    for (const amount of Object.keys(deductibleDiscounts)) {
      if (cost >= parseInt(amount)) {
        discount = deductibleDiscounts[amount];
      }
    }

    return discount;
  };


  const groupedVisitorData = {
    Standard: [],
    Enhanced: [],
    Premium: []
  };

  Visitorquotedata.vistorQuote.forEach(item => {
    item.list.forEach(listItem => {
      groupedVisitorData[listItem.planType].push(listItem);
    });
  });




  // Function to calculate discount
  // const calculateDiscount = (costWithPreExisting, costWithoutPreExisting, deductibleBeans) => {
  //   let discount = 0;
  //   if (costWithPreExisting === 100 && costWithoutPreExisting === 100) {
  //     deductibleBeans.forEach(bean => {
  //       if (bean.amount === "100") {
  //         discount = parseInt(bean.standardDiscount);
  //       }
  //     });
  //   }
  //   return discount;
  // };

  return (
    <div>
      <Box pt={'90px'} pb={'80px'}>
        <Tabs>
          <TabList
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: "20px",
              padding: "5px",
              backgroundColor: "#088183",
              color: "white",
              borderTopRadius: '20px',
              // borderBottomRadius: "1px",
              width: '430px',
              marginLeft: '20px'
            }}
          >
            <Tab
              _selected={{
                color: "black",
                fontSize: "12px",
                fontWeight: "500",
                // width: "80%",
                borderRadius: "10px",
                bg: "white",
                borderRadius: "24px",
                backgroundColor: "white",
                padding: '0px 10px'
              }}
            >
              Super Visa
            </Tab>
            <Tab
              _selected={{
                color: "black",
                fontSize: "12px",
                fontWeight: "500",
                // width: "20%",
                borderRadius: "24px",
                bg: "white",
                backgroundColor: "white",
              }}
            >
              Visitors Insurance
            </Tab>
            <Tab
              _selected={{
                color: "black",
                fontSize: "12px",
                fontWeight: "500",
                // width: "20%",
                borderRadius: "24px",
                bg: "white",
                backgroundColor: "white",
              }}
            >
              Student Insurance
            </Tab>
          </TabList>
          <TabPanels>

            {/* **** super visa ***** */}
            <TabPanel padding={'0px'}>
              <Box>
                <Flex>
                  <Box marginLeft='20px' boxShadow='rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' w={'430px'} h={'auto'} p={'20px'} paddingTop={'35px'}>
                    <form>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <div style={{ marginRight: "10px", flex: "1" }}>
                          <label style={{ fontSize:'14px',fontFamily:'Open Sans,sans-serif'}}>Start Date</label>
                          <br />
                          <input
                            name="policyStartDate"
                            type="date"
                            placeholder="Start Date"
                            value={formData.policyStartDate}
                            onChange={handleChange}
                            min={new Date().toISOString().split('T')[0]}
                            style={{
                              border: "1px solid grey",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                          />
                          <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.policyStartDate}
                          </p>



                        </div>
                        <div style={{ flex: "1" }}>
                          <label style={{ fontSize:'14px',fontFamily:'Open Sans,sans-serif'}}>End Date</label>
                          <br />
                          <input
                            name="policyEndDate"
                            type="date"
                            placeholder="End Date"
                            value={newEnd ? newEnd.toISOString().split("T")[0] : ""}
                            readOnly
                            style={{
                              border: "1px solid grey",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                          />
                          <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.policyEndDate}
                          </p>
                        </div>
                      </div>
                      <Text fontSize={'14px'} mt={'9px'} fontFamily={"Open Sans,sans-serif"}>Email</Text>
                      <input
                        name="email"
                        placeholder="email"
                        value={formData.email}
                        onChange={handleChange}
                        style={{
                          border: "1px solid grey",
                          padding: "5px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      />
                      <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.email}
                          </p>
                      <Text fontSize={'14px'} mt={'9px'} fontFamily={"Open Sans,sans-serif"}>DOB</Text>
                      {formData.insuredDetailsRequests.map((traveler, index) => (
                        <>
                        <input
                          key={index}
                          mt={2}
                          type="date"
                          placeholder={`Traveler ${index + 1} Age`}
                          value={traveler.insuredDOB}
                          max={getMaxSelectableDate()}
                          onChange={(e) =>
                            handleTravelerDOBChange(index, e.target.value)
                          }
                          name={`insuredDOB${index}`}
                          style={{
                            border: "1px solid grey",
                            padding: "5px",
                            borderRadius: "5px",
                            width: "100%",
                            // marginTop: "10px",
                          }}
                        />
                        <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.insuredDOB}
                          </p>
                       </>
                      ))}
 <p style={{textAlign:"start",color:"red",fontSize:"sm" }}  >
                        {errors.insuredDetailsRequests}
                      </p>
                      <div style={{ display: "flex", marginTop: "10px" }}>
                        <button
                          style={{
                            marginRight: "10px",
                            backgroundColor: 'rgb(8, 129, 131)',
                            color: 'white',
                            padding: "2px 15px",
                            fontSize: "18px",
                          }}
                          onClick={handleAddTraveler}
                        >
                          +
                        </button>
                        <button
                          style={{
                            backgroundColor: buttonBackgroundColor,
                            padding: "2px 15px",
                            fontSize: "18px",
                            color: buttonColor,
                          }}
                          onClick={handleRemoveTraveler}
                        >
                          -
                        </button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "19px",
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => handleSubmit1("Supervisa")}
                          style={{ backgroundColor: 'teal', padding: '5px 8px', color: 'white', borderRadius: '10px' }}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </Box>
                  {showSuperVisaTable === true ?
                    <Box marginLeft={'30px'} marginTop={"-70px"} boxShadow={'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'} w={'65%'} padding={"20px"} p={'10px'} overflowY="auto">
                      <TableContainer>
                        <Table size="sm" variant="simple">
                          <Thead>
                            <Tr>
                              <Th p={'3px'} fontSize={'12px'} fontWeight={'600'} fontFamily={'poppins'}>Plan Type</Th>
                              <Th p={'3px'} fontWeight={'600'} fontFamily={'poppins'}>Sum Insured</Th>
                              <Th p={'3px'} fontSize={'10px'} fontWeight={'600'} fontFamily={'poppins'}>Cost without <br /> Pre-Existing</Th>
                              <Th p={'3px'} fontSize={'10px'} fontWeight={'600'} fontFamily={'poppins'}>Cost with <br /> Pre-Existing</Th>
                              <Th>Dedcutiables</Th>
                              <Th p={'3px'} fontSize={'11px'} fontWeight={'600'} fontFamily={'poppins'}>Buy Now</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {Object.entries(groupedData).map(([planType, data]) => (
                              <React.Fragment key={planType}>
                                <Tr>
                                  <Td colSpan="3" fontSize={'12px'} fontWeight={'600'}>
                                    {planType}
                                  </Td>
                                  {/* <Link to='/get-quick-quote'> */}
                                  <Td _hover={{color:'teal'}} onClick={() => handleGetQuickQuote('Data to pass to GetQuickQuote')}><FaRegArrowAltCircleRight /></Td>
                                  {/* </Link> */}
                                </Tr>
                                {data.map((item, index) => (
                                  <Tr cursor={'pointer'} _hover={{ backgroundColor: '#E9EDEF' }} key={index} p={'0px'}>
                                    <Td p={'3px'}>*</Td>
                                    <Td p={'3px'} fontSize={'12px'}>{item.sumInsured}</Td>
                                    <Td p={'3px'} fontSize={'12px'}>{item.costwithoutPreExisting}</Td>
                                    <Td p={'3px'} fontSize={'12px'}>{item.costWithPreExisting}</Td>
                                    <Td p={'3px'}>
                                      <Select w={'70%'} size={'xs'}>
                                        <option value="">$0</option>
                                        <option value="">$100</option>
                                        <option value="">$250</option>
                                        <option value="">$500</option>
                                        <option value="">$1000</option>
                                        <option value="">$3000</option>
                                      </Select>
                                    </Td>
                                    <Td _hover={{ color: 'teal' }}><FaRegArrowAltCircleRight /></Td>
                                  </Tr>
                                ))}
                              </React.Fragment>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box> : <><br /></>}
                </Flex>
              </Box>
            </TabPanel>


            {/* **** visitor insurance **/}
            <TabPanel padding={'0px'}>
              <Box>
                <Flex>
                  <Box marginLeft='20px' boxShadow='rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' w={'430px'} h={'auto'} p={'20px'} paddingTop={'35px'}>
                    <form>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <div style={{ marginRight: "10px", flex: "1" }}>
                          <label style={{ fontSize:'14px',fontFamily:'Open Sans,sans-serif'}}>Start Date</label>
                          <br />
                          <input
                            name="policyStartDate"
                            type="date"
                            placeholder="Start Date"
                            value={formData.policyStartDate}
                            onChange={handleChangeVisitor}
                            min={new Date().toISOString().split('T')[0]}
                            style={{
                              border: "1px solid grey",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                          />
                          <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.policyStartDate}
                          </p>
                        </div>
                        <div style={{ flex: "1" }}>
                          <label style={{ fontSize:'14px',fontFamily:'Open Sans,sans-serif'}}>End Date</label>
                          <br />
                          <input
                            name="policyEndDate"
                            type="date"
                            placeholder="End Date"
                            value={formData.policyEndDate}
                            onChange={handleChangeVisitor}
                            min={new Date().toISOString().split('T')[0]}
                            style={{
                              border: "1px solid grey",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                          />
                          <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.policyEndDate}
                          </p>
                        </div>
                      </div>
                      <Text fontSize={'14px'} mt={'9px'} fontFamily={"Open Sans,sans-serif"}>Email</Text>
                      <input
                        name="email"
                        placeholder="email"
                        value={formData.email}
                        onChange={handleChangeVisitor}
                        style={{
                          border: "1px solid grey",
                          padding: "5px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      />
                       <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.email}
                          </p>
                      <Text fontSize={'14px'} mt={'9px'} fontFamily={"Open Sans,sans-serif"}>DOB</Text>
                      {travelerAges.map((traveler, index) => (
                        <input
                          key={index}
                          mt={2}
                          type="date"
                          placeholder={`Traveler ${index + 1} Age`}
                          value={traveler.insuredDOB}
                          max={getMaxSelectableDate()}
                          onChange={(e) =>
                            handleTravelerDOBChange(index, e.target.value)
                          }
                          name={`insuredDOB${index}`}
                          style={{
                            border: "1px solid grey",
                            padding: "5px",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        />
                      ))}
                       <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.insuredDOB}
                          </p>
                      <div style={{ display: "flex", marginTop: "10px" }}>
                        <button
                          style={{
                            marginRight: "10px",
                            backgroundColor: buttonBackgroundColor,
                            padding: "2px 15px",
                            fontSize: "18px",
                            color: buttonColor
                          }}
                          onClick={handleAddTraveler}
                        >
                          +
                        </button>
                        <button
                          style={{
                            backgroundColor: buttonBackgroundColor,
                            padding: "2px 15px",
                            fontSize: "18px",
                            color: buttonColor,
                          }}
                          onClick={handleRemoveTraveler}
                        >
                          -
                        </button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "19px",
                        }}
                      >
                        <button
                          type="button"
                          style={{ backgroundColor: 'teal', padding: '5px 8px', color: 'white', borderRadius: '10px' }}
                          onClick={() => handleSubmit2("Visitorvisa")}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </Box>
                  {
                    showVisitorTable === true ?

                      <Box marginLeft={'30px'} marginTop={"-70px"} boxShadow={'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'} w={'65%'} padding={"20px"} p={'10px'} overflowY="auto">
                        <TableContainer>
                          <Table size="sm" variant="simple">
                            <Thead>
                              <Tr>
                                <Th p={'3px'} fontSize={'12px'} fontWeight={'600'} fontFamily={'poppins'}>Plan Type</Th>
                                <Th p={'3px'} fontWeight={'600'} fontFamily={'poppins'}>Sum Insured</Th>
                                <Th p={'3px'} fontSize={'10px'} fontWeight={'600'} fontFamily={'poppins'}>Cost without <br /> Pre-Existing</Th>
                                <Th p={'3px'} fontSize={'10px'} fontWeight={'600'} fontFamily={'poppins'}>Cost with <br /> Pre-Existing</Th>
                                <Th>Dedcutiables</Th>
                                <Th p={'3px'} fontSize={'11px'} fontWeight={'600'} fontFamily={'poppins'}>Buy Now</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {Object.entries(groupedVisitorData).map(([planType, data]) => (
                                <React.Fragment key={planType}>
                                  <Tr>
                                    <Td colSpan="6">
                                      <strong>{planType}</strong>
                                    </Td>
                                  </Tr>
                                  {data.map((item, index) => (
                                    <Tr cursor={'pointer'} _hover={{ backgroundColor: '#E9EDEF' }} key={index} p={'0px'}>
                                      <Td p={'3px'}>*</Td>
                                      <Td p={'3px'} fontSize={'12px'}>{item.sumInsured}</Td>
                                      <Td p={'3px'} fontSize={'12px'}>{item.costwithoutPreExisting}</Td>
                                      <Td p={'3px'} fontSize={'12px'}>{item.costWithPreExisting}</Td>
                                      <Td p={'3px'}>
                                        <Select w={'70%'} size={'xs'}>
                                          <option value="">$0</option>
                                          <option value="">$100</option>
                                          <option value="">$250</option>
                                          <option value="">$500</option>
                                          <option value="">$1000</option>
                                          <option value="">$3000</option>
                                        </Select>
                                      </Td>
                                      <Td _hover={{ color: 'teal' }}><FaRegArrowAltCircleRight /></Td>
                                    </Tr>
                                  ))}
                                </React.Fragment>
                              ))}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </Box> : <><br /></>}
                </Flex>
              </Box>
            </TabPanel>


            {/* ******* Student ***** */}
            <TabPanel padding={'0px'}>
              <Box>
                <Flex>
                  <Box marginLeft='20px' boxShadow='rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' w={'430px'} h={'auto'} p={'20px'} paddingTop={'35px'}>
                    <form>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <div style={{ marginRight: "10px", flex: "1" }}>
                          <label  style={{ fontSize:'14px',fontFamily:'Open Sans,sans-serif'}}>Start Date</label>
                          <br />
                          <input
                            name="policyStartDate"
                            type="date"
                            placeholder="Start Date"
                            value={formData.policyStartDate}
                            onChange={handleChangeStudent}
                            min={new Date().toISOString().split('T')[0]}
                            style={{
                              border: "1px solid grey",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                          />
                           <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.policyStartDate}
                          </p>
                        </div>
                        <div style={{ flex: "1" }}>
                          <label style={{ fontSize:'14px',fontFamily:'Open Sans,sans-serif'}}>End Date</label>
                          <br />
                          <input
                            name="policyEndDate"
                            type="date"
                            placeholder="End Date"
                            value={formData.policyEndDate}
                            onChange={handleChangeStudent}
                            min={new Date().toISOString().split('T')[0]}
                            style={{
                              border: "1px solid grey",
                              padding: "5px",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                          />
                           <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.policyEndDate}
                          </p>
                        </div>
                      </div>
                      <Text fontSize={'14px'} mt={'9px'} fontFamily={"Open Sans,sans-serif"}>Email</Text>
                      <input
                        name="email"
                        placeholder="email"
                        value={formData.email}
                        onChange={handleChangeStudent}
                        style={{
                          border: "1px solid grey",
                          padding: "5px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      />
                       <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.email}
                          </p>
                      <Text fontSize={'14px'} mt={'9px'} fontFamily={"Open Sans,sans-serif"}>DOB</Text>
                      {travelerAges.map((traveler, index) => (
                        <input
                          key={index}
                          mt={2}
                          type="date"
                          placeholder={`Traveler ${index + 1} Age`}
                          value={traveler.insuredDOB}
                          max={getMaxSelectableDate()}
                          onChange={(e) =>
                            handleTravelerDOBChange(index, e.target.value)
                          }
                          name={`insuredDOB${index}`}
                          style={{
                            border: "1px solid grey",
                            padding: "5px",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        />
                      ))}
                       <p style={{textAlign:"start",color:"red",fontSize:"12px" }}  >
                            {errors.insuredDOB}
                          </p>
                      <div style={{ display: "flex", marginTop: "10px" }}>
                        <button
                          style={{
                            marginRight: "10px",
                            backgroundColor: buttonBackgroundColor,
                            padding: "2px 15px",
                            fontSize: "18px",
                            color: buttonColor
                          }}
                          onClick={handleAddTraveler}
                        >
                          +
                        </button>
                        <button
                          style={{
                            backgroundColor: buttonBackgroundColor,
                            padding: "2px 15px",
                            fontSize: "18px",
                            color: buttonColor,
                          }}
                          onClick={handleRemoveTraveler}
                        >
                          -
                        </button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "19px",
                        }}
                      >
                        <button
                          type="button"
                          style={{ backgroundColor: 'teal', padding: '5px 8px', color: 'white', borderRadius: '10px' }}
                          onClick={() => handleSubmit3("StudentVisa")}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </Box>
                  {Studentquotedata.studentQuote.length > 0 ? (
                    <Box marginLeft={'30px'} marginTop={"-70px"} boxShadow={'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'} w={'65%'} padding={"40px"} p={'10px'} overflowY="auto">
                      <TableContainer>
                        <Table size="sm" variant="simple">
                          <Thead>
                            <Tr>
                              <Th>Plan Type</Th>
                              <Th>Amount</Th>
                              <Th>Action</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {Studentquotedata.studentQuote.map((item, index) => (
                              <Tr key={index}>
                                <Td>{item.planType}</Td>
                                <Td>{item.amount}</Td>
                                <Td>
                                  <Td _hover={{ color: 'teal' }}><FaRegArrowAltCircleRight /></Td>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ) : null}<><br /></>
                </Flex>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

    </div>
  )
}

export default NewCode