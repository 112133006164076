import React from 'react';
import Sidebar from '../Sidebar';
import { Box,Flex,Tooltip } from '@chakra-ui/react';
import { FaRegEye } from "react-icons/fa6";




const AssignAga = () => {


  return (
    <Sidebar headingText={'Assign Aga'}>  
          
          <Box w={'90%'} m={'auto'} mt={'70px'}>
      <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
        <Box>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                    <col style={{ width: '15%'   }} />
                    <col style={{ width: '10%'    }} />
                    <col style={{ width: '15%'    }} />
                </colgroup>
                <thead>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Advisor Name</th>
                        <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>AGA Name</th>
                        <th style={{ padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Assign AGA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Ravi</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Navneet</td>
                        <td style={{ padding: '10px',fontSize:'22px',fontFamily:'Open Sans, sans-serif' }}>
                          <Flex color={'#4caf50'} justifyContent={'center'} alignItmes='center'>
                            <FaRegEye />
                          </Flex>
                        </td>
                    </tr>
                </tbody>
                 </table>
        </Box>

        </Box>
      </Box>
    </Sidebar>
  )
}

export default AssignAga
