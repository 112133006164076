import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translation files
import enTranslation from '../locales/en.json';
import frTranslation from '../locales/fr.json';
import spTranslation from '../locales/sp.json'
import hiTranslation from '../locales/hi.json'

// Set up i18next
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      sp: {
        translation: spTranslation,
      },
      hi: {
        translation: hiTranslation,
      },
    },
    lng: 'en', // Default language
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
