import Home from "../../Components/Home";
import Aboutus from "../../Components/Aboutus";
import QuickQuote from "../../Components/QuickQuote";
import AdvisorLogin from "../../Components/Advisor/AdvisorLogin";
import AdvisorRegister from "../../Components/Advisor/AdvisorSignup";
import CustomerLogin from "../../Components/Customer/CustomerLogin";
import Enquiry from "../../Components/Enquiry";
import GetQuote from "../../Components/SuperVisa/GetQuoteFamilyCover";
import PlanCompare from "../../Components/SuperVisa/PlanComparison";
import ClaimProcedure from "../../Components/SuperVisa/ClaimProcedure";
import Terms from "../../Components/Terms&Conditions";
import Privacy from "../../Components/PrivacyPolicy";
import CustomerSignUp from "../../Components/Customer/CustomerSignup";
import GetQuoteComp from "../../Components/VisitorInsurance/GetQuoteFamilyCover";
import PolicyWording from '../../Components/SuperVisa/PolicyWording'
import VisitorPlanCompare from "../../Components/VisitorInsurance/PlanComparison";
import VisitorPolicyWording from "../../Components/VisitorInsurance/PolicyWording";
import VisitorClaimProcedure from "../../Components/VisitorInsurance/ClaimProcedure";
import CustomerProfile from "../../Components/Customer/CustomerProfile/index";
import CustomerHomePage from "../../Components/Customer/CustomerHomePage";
import StudentGetQuoteComp from "../../Components/StudentInsurance/GetQuoteSingle";
import StudentPlanCompare from "../../Components/StudentInsurance/PlanComparison";
import StudentPolicyWording from "../../Components/StudentInsurance/PolicyWording";
import StudentClaimProcedure from "../../Components/StudentInsurance/ClaimProcedure";
import StudentTravelContactUs from "../../Components/StudentInsurance/ContactUs";
import VisitorContactUs from "../../Components/VisitorInsurance/ContactUs";
import SuperVisaContactUs from "../../Components/SuperVisa/ContactUs";
import TripDetails from "../../Components/SuperVisa/GetQuoteSingle/Tripdetails";
import MyQuotation from "../../Components/Customer/MyQuoation/MyQuoation";
import CustomerPolicies from "../../Components/Customer/CustomerPolicies";
import AdvisorProfile from "../../Components/Advisor/AdvisorProfile";
import AdvisorMyQuote from "../../Components/Advisor/AdvisorMyQuote";
import AdvisorMyPolicy from "../../Components/Advisor/AdvisorMyPolicy";
import AdvisorCommission from "../../Components/Advisor/AdvisorCommission";
import AdvisorTeam from "../../Components/Advisor/AdvisorTeam";
import AdvisorGenerateQuote from "../../Components/Advisor/AdvisorQuote";
import NewCode from "../../Components/QuickQuote/index";
import SuperAdminPanel from "../../Components/SuperAdmin";
import GetQuickQuote from "../../Components/QuickQuote/GetQuote";
import AdvisorViewSingleQuote from "../../Components/Advisor/AdvisorViewSingleQuote";
import CustomerSingleQuoteView from "../../Components/Customer/CustomerSingleViewQuote";
import SuperAdminLogin from "../../Components/SuperAdmin/SuperAdminLogin";
import SuperAdminDashbaord from "../../Components/SuperAdmin";
import SuperAdminPermessionPanel from "../../Components/SuperAdmin/SuperAdminPermission";
import CreateAdmin from "../../Components/SuperAdmin/CreateAdmin";
import CustomerEditQuote from "../../Components/Customer/CustomerEditQuote";
import AdminDashboard from "../../Components/Admin/AdminDashboard";
import AdminProfile from "../../Components/Admin/AdminProfile";
import AdminUsers from "../../Components/Admin/AdminUsers";
import Customers from "../../Components/Admin/Customers";
import AssignMga from "../../Components/Admin/AssignMga";
import AssignAga from "../../Components/Admin/AssignAga";
import Products from "../../Components/Admin/Products";
import InsurancePlans from "../../Components/Admin/InsurancePlans";
import Deductibles from "../../Components/Admin/Deductibles";
import Quotes from "../../Components/Admin/Quotes";
import Policies from "../../Components/Admin/Policies";
import Commission from "../../Components/Admin/Commission";
import RefundRequests from "../../Components/Admin/RefundRequests";
import GuestEnquiry from "../../Components/Admin/GuestEnquiry";
import Pages from "../../Components/Admin/Pages";
import Reports from "../../Components/Admin/Reports";
import Logs from "../../Components/Admin/Logs";
import MgaAgaAdvisor from "../../Components/Admin/MgaAgaAdvisor";
import AdminAllPolicies from "../../Components/Admin/Policies/AdminAllPolicies";
import RenewalPolicies from "../../Components/Admin/Policies/RenewalPolicies";
import ClaimRequests from "../../Components/Admin/Policies/ClaimRequests";
import PolicyDocs from "../../Components/Admin/Policies/AdminAllPolicies/PolicyDocs";
import QutotesDocDownload from "../../Components/Admin/Quotes/QuotesDocDownload";
import AdminQuoteEdit from "../../Components/Admin/Quotes/AdminQuoteEdit";
import QuotePaymentTranscation from "../../Components/Admin/Quotes/QuotePaymentTranscation";
import AssignAdmin from "../../Components/Admin/AdminUsers/AssignAdmin";
import EditAdmin from "../../Components/Admin/AdminUsers/EditAdmin";
import EditProduct from "../../Components/Admin/Products/EditProduct";
import ViewPolicyPaymentTranscations from "../../Components/Admin/Policies/AdminAllPolicies/ViewPolicyPaymentTranscations";
import ViewDeclinedTranscationList from "../../Components/Admin/Policies/AdminAllPolicies/ViewDeclinedTranscationList";
import PolicyEndorsements from "../../Components/Admin/Policies/AdminAllPolicies/PolicyEndorsements";
import MonthlyPolicies from "../../Components/Admin/Policies/MonthlyPolicies";

export const routes = [
  {
    path: "/",
    element: <Home />,
    protected: false,
  },
  {
    path: "/about-us",
    element: <Aboutus />,
    protected: false,
  },
  {
    path: "/quickquote",
    element: <QuickQuote />,
    protected: false,
  },
  {
    path: "/newcode",
    element: <NewCode />,
    protected: false,
  },
  {
    path: "/advisorLogin",
    element: <AdvisorLogin />,
    protected: false,
  },
  {
    path: "/advisorRegister",
    element: <AdvisorRegister />,
    protected: false,
  },
  {
    path: "/customerLogin",
    element: <CustomerLogin />,
    protected: false,
  },
  {
    path: "/customerRegister",
    element: <CustomerSignUp />,
    protected: false,
  },
  {
    path: "/customerProfile",
    element: <CustomerProfile />,
    protected: false,
  },

  {
    path: "/enquiry",
    element: <Enquiry />,
    protected: false,
  },
  {
    path: "/supervisa-getQuote",
    element: <GetQuote />,
    protected: false,
  },
  {
    path: "/supervisa-plan-compare",
    element: <PlanCompare />,
    protected: false,
  },
  {
    path: "/supervisa-policy-wording",
    element: <PolicyWording />,
    protected: false,
  },
  {
    path: "/supervisa-claim-procedure",
    element: <ClaimProcedure />,
    protected: false,
  },
  {
    path: "/supervisa-contact-us",
    element: <SuperVisaContactUs />,
    protected: false,
  },
  {
    path: "/visitor-getquote",
    element: <GetQuoteComp />,
    protected: false,
  },
  {
    path: "/visitor-plan-compare",
    element: <VisitorPlanCompare />,
    protected: false,
  },
  {
    path: "/visitor-policy-wording",
    element: <VisitorPolicyWording />,
    protected: false,
  },
  {
    path: "/visitor-claim-procedure",
    element: <VisitorClaimProcedure />,
    protected: false,
  },
  {
    path: "/visitor-contact-us",
    element: <VisitorContactUs />,
    protected: false,
  },
  {
    path: "/student-travel-getquote",
    element: <StudentGetQuoteComp />,
    protected: false,
  },
  {
    path: "/student-travel-plan-compare",
    element: <StudentPlanCompare />,
    protected: false,
  },
  {
    path: "/student-travel-policy-wording",
    element: <StudentPolicyWording />,
    protected: false,
  },
  {
    path: "/student-travel-claim-procedure",
    element: <StudentClaimProcedure />,
    protected: false,
  },
  {
    path: "/student-travel-contactus",
    element: <StudentTravelContactUs />,
    protected: false,
  },
  {
    path: "/terms_condition",
    element: <Terms />,
    protected: false,
  },
  {
    path: "/privacy_policy",
    element: <Privacy />,
    protected: false,
  },
  {
    path: "/customer-dashboard",
    element: <CustomerHomePage />,
    protected: false,
  },
  {
    path: "/get-quote-single",
    element: <TripDetails />,
    protected: false,
  },
  {
    path: "/my-quotes",
    element: <MyQuotation />,
    protected: false,
  },
  {
    path: "/customer-profile",
    element: <CustomerProfile />,
    protected: false,
  },
  {
    path: "/customer-policies",
    element: <CustomerPolicies />,
    protected: false,
  },
  {
    path: "/advisor-profile",
    element: <AdvisorProfile />,
    protected: false,
  },
  {
    path: "/advisor-quotes",
    element: <AdvisorMyQuote />,
    protected: false,
  },
  {
    path: "/advisor-policies",
    element: <AdvisorMyPolicy />,
    protected: false,
  },
  {
    path: "/advisor-commission",
    element: <AdvisorCommission />,
    protected: false,
  },
  {
    path: "/advisor-team",
    element: <AdvisorTeam />,
    protected: false,
  },
  {
    path: "/advisor-generate-quote",
    element: <AdvisorGenerateQuote />,
    protected: false,
  },
  {
    path: "/quick-quote",
    element: <NewCode />,
    protected: false,
  },
  {
    path: "/get-quick-quote",
    element: <GetQuickQuote />,
    protected: false,
  },
  {
    path: "/advisor-view-quote/:id",
    element: <AdvisorViewSingleQuote />,
    protected: false,
  },
  {
    path: "/customer-view-quote/:id",
    element: <CustomerSingleQuoteView />,
    protected: false,
  },
  {
    path: "/customer-edit-quote/:id",
    element: <CustomerEditQuote />,
    protected: false,
  },
  {
    path: "/admin",
    element: <SuperAdminLogin />,
    protected: false,
  },
  {
    path: "/super-admin-permission",
    element: <SuperAdminPermessionPanel/>,
    protected: false,
  },
  {
    path: "/super-admin-dashbaord",
    element: <SuperAdminDashbaord />,
    protected: false,
  },
  {
    path: "/admin-create",
    element: <CreateAdmin />,
    protected: false,
  },
  {
    path: "/admin/dashboard",
    element: <AdminDashboard />,
    protected: false,
  },
  {
    path: "/admin/admin-profile",
    element: <AdminProfile />,
    protected: false,
  },
  {
    path: "/admin/admin-users",
    element: <AdminUsers />,
    protected: false,
  },
  {
    path: "/admin/customers",
    element: <Customers />,
    protected: false,
  },
  {
    path: "/admin/assign-mga",
    element: <AssignMga />,
    protected: false,
  },
  {
    path: "/admin/assign-aga",
    element: <AssignAga />,
    protected: false,
  },
  {
    path: "/admin/products",
    element: <Products />,
    protected: false,
  },
  {
    path: "/admin/insurance-plans",
    element: <InsurancePlans />,
    protected: false,
  },
  {
    path: "/admin/deductibles",
    element: <Deductibles />,
    protected: false,
  },
  {
    path: "/admin/quotes",
    element: <Quotes />,
    protected: false,
  },
  {
    path: "/admin/policies",
    element: <Policies />,
    protected: false,
  },
  {
    path: "/admin/commission",
    element: <Commission />,
    protected: false,
  },
  {
    path: "/admin/refund-requests",
    element: <RefundRequests />,
    protected: false,
  },
  {
    path: "/admin/guest-enquiry",
    element: <GuestEnquiry />,
    protected: false,
  },
  {
    path: "/admin/pages",
    element: <Pages />,
    protected: false,
  },
  {
    path: "/admin/reports",
    element: <Reports />,
    protected: false,
  },
  {
    path: "/admin/logs",
    element: <Logs />,
    protected: false,
  },
  {
    path: "admin/mga/aga/advisor",
    element: <MgaAgaAdvisor />,
    protected: false,
  },
  {
    path: "admin/all-policies",
    element: <AdminAllPolicies />,
    protected: false,
  },
  {
    path: "admin/montly-policies",
    element: <MonthlyPolicies />,
    protected: false,
  },
  {
    path: "admin/renewal-policies",
    element: <RenewalPolicies />,
    protected: false,
  },
  {
    path: "admin/claim-requests",
    element: <ClaimRequests />,
    protected: false,
  },
  {
    path: "admin/download-documents",
    element: <PolicyDocs />,
    protected: false,
  },
  {
    path: "admin/download-quote-documents",
    element: <QutotesDocDownload />,
    protected: false,
  },
  {
    path: "admin/editQuote/:id",
    element: <AdminQuoteEdit />,
    protected: false,
  },
  {
    path: "admin/quotePaymentTranscation/:id",
    element: <QuotePaymentTranscation />,
    protected: false,
  },
  {
    path: "admin/viewPolicyPaymentTranscation/:id",
    element: <ViewPolicyPaymentTranscations />,
    protected: false,
  },
  {
    path: "admin/viewDeclinedTranscationList/:id",
    element: <ViewDeclinedTranscationList />,
    protected: false,
  },
  {
    path: "admin/policyEndorsements/:id",
    element: <PolicyEndorsements />,
    protected: false,
  },
  {
    path: "admin/assign-admin",
    element: <AssignAdmin />,
    protected: false,
  },
  {
    path: "admin/edit-admin",
    element: <EditAdmin />,
    protected: false,
  },
  {
    path: "admin/edit-product",
    element: <EditProduct />,
    protected: false,
  },
];
;