import {
  Button,Checkbox,Input,Select,Box,Flex,Text,TableContainer,Table,Thead,Tr,Th,Td,Tbody,Stack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { buttonBackgroundColor} from "../../../Utils/UniversalColor";
import { VTCDetailsModal } from "../../Advisor/AdvisorQuote/Validation";
import Description from "./Description";
import { useDispatch } from "react-redux";
import RadioOption from "../../../Utils/RadioCard";
import { advisorCalculateVtc } from "../../Advisor/Dependencies/action";
// import Api from "../../../dependencies/utils/Api";



function TripDetails() {
  const toast = useToast();
  const [tripDetailsFirst, setTripDetailsFirst] = useState(true);
  const [tripDetailsSecond, setTripDetailsSecond] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const [waitingPeriod, setWaitingPeriod] = useState("0");
  const [isVtcModalOpen, setIsVtcModalOpen] = useState(false);
  const [contactDetails,setContactDetails]=useState(false)
  const [data,setData]=useState([])
  const dispatch=useDispatch()
  const [checked,setChecked]=useState('')
  const [policyLimit,setPolicyLimit]=useState('25000')
  const [dedecutibles,setDeductibles]=useState ('0')
  const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState('1')
  const [PlanType,setPlanType]=useState('Standard')
  const [selectedCostOption, setSelectedCostOption] = useState('');
  const [duration,setDuration]=useState('')
  const [studentPolicyOption,setStudentPolicyOption]=useState('Single Trip')
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [calculatedData,setCalculatedData]=useState([])
  const [addTravelers, setAddTravelers] = useState([
    {
      name: "",
      gender: "",
      dob: "",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
    },
  ]);
 
    const [travelersError, setTravelersError] = useState([
      {
        name: "",
        gender: "",
        dob: "",
        relation: "",
        benificiaryDob: "",
        benificiaryName: "",
      },
    ]);
    const [formData, setFormData] = useState({
      firstDate: "",
      lastDate: "",
      departureDate: "",
      departureMaxDate: "",
    });
    const [policyHolder, setPolicyHolder] = useState({
      policyOwnerName: '',
      policyOwnerDob: '',
      policyOwnerEmail: '',
      policyOwnerAddress: '',
      policyOwnerPostalCode: '',
      policyOwnerCity: '',
      policyOwnerPhone: ''
    });

    const handleStudentPolicyOption = (e) => {
      const { value } = e.target;
      setStudentPolicyOption(value);
    };

    const isInitialRender = useRef(true);

    useEffect(() => {
      if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
      }
      handleModalSubmit();
    }, [PlanType, policyLimit, policyTypeSuperVisa, dedecutibles]);

    const handlePolicyOwnerChange = (e) => {
      const { name, value } = e.target;
      setPolicyHolder({ ...policyHolder, [name]: value });
      const selectedPolicyHolder = addTravelers.find((traveler) => traveler.name === value);
      if (selectedPolicyHolder) {
        setPolicyHolder({ ...policyHolder, policyOwnerDob: selectedPolicyHolder.dob, policyOwnerName: selectedPolicyHolder.name });
      }
    };
    
    const [errors, setErrors] = useState({
      firstDate: "Enter First Date cover",
      lastDate: "",
      departureDate: "",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    
      if (name === "lastDate") {
        if (!formData.firstDate) {
          alert("Please select the first date of cover first");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
          return;
        }
        const startDate = new Date(formData.firstDate);
        const endDate = new Date(value);
        const diffInMilliseconds = endDate - startDate;
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
    
        if (endDate < startDate) {
          alert("Last date cannot be earlier than the first date");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
        } else if (diffInMilliseconds > oneYearInMilliseconds) {
          alert("Duration cannot exceed 1 year");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
        } else {
          const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
          setDuration(durationInDays);
        }
      }
    };

  const startDate = new Date(formData.firstDate);
  let endDate = null;

  if (formData.firstDate) {
    endDate = new Date(startDate);
    endDate.setFullYear(startDate.getFullYear() + 1);
  }

  let durat = 0;
  if (endDate) {
    const diff = endDate.getTime() - startDate.getTime();
    durat = Math.ceil(diff / (1000 * 60 * 60 * 24));
  }

  const handleToTravellerDetails = () => {
    if(!formData.departureDate || !formData.firstDate || !formData.lastDate){
      alert('Please enter date of departure')
      return;
    }
      setTripDetailsFirst(false);
      setTripDetailsSecond(true)
  };

  const handleToContactDetails = () =>{
    if(!selectedCostOption){
      alert("Please selected Costing")
      return;
    }
      setOpenPlan(false)
      setContactDetails(true)
  }

  const handleTripDetailsBackEvent = () => {
    setTripDetailsSecond(false);
    setTripDetailsFirst(true)
  };

  const handlePlanBackEvent = () =>{
    setOpenPlan(false)
    setTripDetailsSecond(true)
  }

  const handleContactDetailsBackEvent = ( ) =>{
    setOpenPlan(true)
    setContactDetails(false)
  }

  const handleCheckbox = () => {
    setChecked(true);
  };

  const handleRemoveTraveller = (index) => {
    const updatedTravelers = [...addTravelers];
    updatedTravelers.splice(index, 1);
    setAddTravelers(updatedTravelers);
  };

  const handleVtcModalClose = () => {
    setIsVtcModalOpen(false);
  };

  useEffect(() => {
    if (!formData.departureDate) {
      setWaitingPeriod("NA");
    } else {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);
    
      const selectedDate = new Date(formData.departureDate);
      const firstDate = new Date(formData.firstDate)
      const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
      const isTodayOrFuture = selectedDate >= today;
    
      if (selectedDate>=firstDate) {
        setWaitingPeriod("NA");
      } else {
        setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
      }
    }
  }, [formData.departureDate]);

  const handleAddTraveller = () => {
    if (addTravelers.length < 3) {
      setAddTravelers([
        ...addTravelers,
        {
          name: "",
          gender: "",
          dob: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
    } else {
      toast({ title: "you can not add more than 3 family members." });
    }
  };

  const handleChangeTraveler = (index, e) => {
    const { name, value } = e.target;
    if (name === "dob" || name === "benificiaryDob") {
      const selectedDate = new Date(value);
      const minDate = new Date();
      minDate.setDate(minDate.getDate() - 15);
      if (selectedDate >= minDate) {
          alert("Users must be at least 15 days older than today.");
          return;
      }
  }
    setAddTravelers((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = { ...updatedState[index], [name]: value };
      return updatedState;
    });
  };

  const handleSubmitTravellerForm = () => {
    const isAnyBeneficiaryDetailEmpty = addTravelers.some(
      (traveler) =>
        traveler.benificiaryName === "" ||
        traveler.benificiaryDob === "" ||
        traveler.relation === ""
    );
    if (isAnyBeneficiaryDetailEmpty) {
      alert("Please fill in all mandatory details.")
      return;
    }
    setIsVtcModalOpen(true)

  };

  // const handleModalSubmit = async () => {
  //   setTripDetailsSecond(false)
  //   setOpenPlan(true);
  //       const updatedTravelers = addTravelers.map((traveler) => ({
  //         insuredName: traveler.name,
  //         insuredGender: traveler.gender,
  //         insuredDOB: traveler.dob,
  //         insuredRelation: traveler.relation,
  //         beneficiaryName: traveler.benificiaryName,
  //         beneficiaryDob: traveler.benificiaryDob,
  //       }));
  //     const dataToLog = {
  //         policyStartDate: formData.firstDate,
  //         policyEndDate: formData.lastDate,
  //         sumInsured:2000000,
  //         planType:'Standard',
  //         insuredDetailsRequests: updatedTravelers,
  //         policyType:'Studentvisa',
  //         tripType:studentPolicyOption
  //     };

  //       dispatch(advisorCalculateVtc(dataToLog)).then(res => {
  //         const {status, data} = res
  //         setData(data)
  //         console.log(res,'data')
  //       })
  // };

  const handleModalSubmit = async () => {
    setTripDetailsSecond(false);
    setOpenPlan(true);
    
    const updatedTravelers = addTravelers.map((traveler) => ({
      insuredName: traveler.name,
      insuredGender: traveler.gender,
      insuredDOB: traveler.dob,
      insuredRelation: traveler.relation,
      beneficiaryName: traveler.benificiaryName,
      beneficiaryDob: traveler.benificiaryDob,
    }));
    const requests = [];
    const responseDataArray = [];
    const planTypes = ['Standard', 'Enhanced', 'Premium'];
    for (const planType of planTypes) {
      const dataToLog = {
        firstDateOfCover: formData.firstDate,
        lastDateOfCover: formData.lastDate,
        policyLimit: 2000000,
        planType: planType,
        insuredDetailsRequests: updatedTravelers,
        policyType: 'Studentvisa',
        tripType: studentPolicyOption
      };
  
      requests.push(dispatch(advisorCalculateVtc(dataToLog)));
    }
  
    try {
      const responses = await Promise.all(requests);  
      responses.forEach((res, index) => {
        const { status, data } = res;
        responseDataArray.push(data.listOfInsuredQutation);
      });
      // Update the state with the combined response data
      setCalculatedData(responseDataArray);
    } catch (error) {
      console.error('Error occurred while fetching data:', error);
    }
  };
  

  const handleGetQuote = () =>{

      const dataToSend={

      }
  }

  console.log(calculatedData,'data')

  const rows = [
    {
      planType:'Standard',
      policyLimit: '2000000',
      insuredPlanData: '7.00CAD',
      adjustment: '6.00 CAD',
      tripType: 'Single'
    },
    {
      planType:'Enhanced',
      policyLimit: '2000000',
      insuredPlanData: '20.00CAD',
      adjustment: '2.00 CAD',
      tripType: 'Single'
    },
    {
      planType:'Premium',
      policyLimit: '2000000',
      insuredPlanData: '10.00CAD',
      adjustment: '0.00 CAD',
      tripType: 'Single'
    }
  ];

  const handleRowClick = (index, rowData) => {
    setSelectedRowIndex(index);
    setSelectedRowData(rowData);
  };

return (
    <div style={{ width: "100%", padding: "10px",paddingTop:'0px',textAlign:'left'}}>
        <Text marginLeft={'40px'} fontFamily={'poppins'} fontWeight={'700'} mb={'15px'}>Visitor Insurance</Text>
      <div style={{ display: "flex",flexDirection: "column",alignItems: "center",width:'95%',margin:'auto'}}>

        {tripDetailsFirst && (
          <div
            style={{
              width: "100%",
              maxWidth: "870px",
              margin: "auto",
              padding: "18px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p style={{ fontFamily: "poppins", fontSize: "18px", fontWeight: 400, marginBottom: "20px",borderBottom: "2px solid #088183"}}>Trip Details</p>
            <div
              style={{
                gap: "20px",
                margin: "auto",
                padding: "18px",
              }}
            >
              <Flex gap={'40px'} fontSize={'14px'} pb={'25px'}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>First Date of Cover</span>
                <Input name="firstDate" min={new Date().toISOString().split("T")[0]}
                  onChange={handleChange} w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Last Date of Cover</span>
                <Input name="lastDate" w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} 
                  value={formData.lastDate} onChange={handleChange}/>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Duration</span>
                <Input w={'70px'} size='sm' backgroundColor={'#eaeaea'} value={duration} border={'1px solid #d7d7d7'} />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Country</span>
                <p> Canada</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Province</span>
                <p>Ontario</p>
              </div>
              </Flex>

              <Flex gap={'40px'} fontSize={'14px'}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Plan Option</span>
                <Select size={'sm'} value={studentPolicyOption} onChange={handleStudentPolicyOption}>
                  <option value="Single Trip">International Student Policy -Single Trip</option>
                  <option value="Annual">International Student Policy - Annual</option>
                </Select>
                {/* <Input name="departureDate" max={formData.departureMaxDate}
                  onChange={handleChange} w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} /> */}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Departure from country of origin</span>
                <Input name="departureDate" max={formData.departureMaxDate}
                  onChange={handleChange} w={'220px'} size='sm' type='date' border={'1px solid #d7d7d7'} />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Waiting Period</span>
                <input
                  placeholder="Basic usage"
                  name="waiting"
                  value={waitingPeriod}
                />
              </div>
              </Flex>
              <button
                onClick={handleToTravellerDetails}
                style={{
                  gridColumn: "1 / span 1",
                  backgroundColor: "#24b6b7",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                  marginTop:'40px'
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        )}

        {tripDetailsSecond && (
          <div
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "auto",
              padding: "18px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p
              style={{
                fontFamily: "poppins",
                fontSize: "18px",
                fontWeight: 400,
                marginBottom: "20px",
                borderBottom: "2px solid #088183",
              }}
            >
              Traveller Details family
            </p>
            <Flex fontFamily={'poppins'} fontSize={'14px'} gap={'10px'} justifyContent={'end'} cursor={'pointer'} color={'teal'} mt={'10px'} mb={'10px'}>
                <Flex alignItems={'center'}>
                  <IoMdAdd style={{fontWeight:'bold'}}/>
                    </Flex>
                  <Text onClick={handleAddTraveller}>Add Traveller</Text>
              </Flex>
            {addTravelers.map((item, index) => {
              return (
                <>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    gap: "20px",
                    margin: "auto",
                    padding: "18px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>Name Of Insured</span>
                    <Input name="name" value={item.name}
                      onChange={(e) => handleChangeTraveler(index, e)} w={'180px'} size='sm' border={'1px solid #d7d7d7'} min={new Date().toISOString().split('T')[0]} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>Gender</span>
                    <Select w={'180px'} background={'white'} size={'sm'} border={'1px solid #d7d7d7'}
                       value={item.gender}
                       name="gender"
                      onChange={(e) => handleChangeTraveler(index, e)}>
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
 
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>Date of Birth</span>
                    <Input name="dob"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.dob}
                       w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} />
                  </div>
                  </div>
                  <Flex gap={'20px'} p={'18px'} pt={'5px'} pb={'20px'}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>
                      Beneficiary Name
                    </span>
                    <Input name="benificiaryName" placeholder="Name"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.benificiaryName} w={'180px'} size='sm' border={'1px solid #d7d7d7'} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>Relation</span>
                    <Input name="relation"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.relation}
                      placeholder="Relation" w={'180px'} size='sm' border={'1px solid #d7d7d7'} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>Beneficiary DOB</span>
                    <Input onChange={(e) => handleChangeTraveler(index, e)}
                      name="benificiaryDob"
                      value={item.benificiaryDob}
                      placeholder="Select Date and Time"
                       w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} />
                  
                    </div>
                    {index > 0 && (
                    <Flex justifyContent={'end'}>
                            <IoMdCloseCircle
                              style={{fontSize:'22px',color:buttonBackgroundColor}}
                              onClick={() => handleRemoveTraveller(index)}
                              />
                          </Flex>
                        )}
                    </Flex>
                   </>
              );
            })}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gap: "8px",
              }}
            >
              <button
                onClick={handleTripDetailsBackEvent}
                style={{
                  backgroundColor: "red",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                Previous
              </button>
              <button
                onClick={handleSubmitTravellerForm}
                style={{
                  backgroundColor: "#24b6b7",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                Proceed & Confirm
              </button>
            </div>
          </div>
        )}

        {openPlan === true && (
        <div style={{ margin: "auto", width: "95%", maxHeight: "200%", padding: "18px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)"}}>
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 400,
              marginBottom: "20px",
              borderBottom: "3px solid #088183",
            }}
          >
            Select Plan
          </p>
          <Box>
          <TableContainer>
              <Table>
                  <Thead>
                      <Tr>
                      <Th p={'7px'} fontSize={'10px'}></Th>
                          <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>POLICY LIMIT</Th>
                          <Th p={'7px'} fontSize={'10px'}>INSURED PLAN DATA</Th>
                          <Th p={'7px'} fontSize={'10px'}>ADJUSTMENT</Th>
                          <Th p={'7px'} fontSize={'10px'}>TRIP TYPE</Th>
                      </Tr>
                  </Thead>
                  <Tbody>
                  {calculatedData[0].map((row, index) => (
                    <>
                         <Tr style={{ backgroundColor: selectedRowIndex === index ? 'teal' : 'white', cursor: 'pointer',color: selectedRowIndex === index ? 'white' : 'black', borderRadius:'5px' }}
                          fontSize={'12px'} p={'2px'} textAlign={'center'}>
                         <Td p={'2px'}>
                         <Box>
                             <Text>{row.planType}</Text>
                             <Text>20.00CAD</Text>
                           </Box></Td>
                         <Td>
                             <Text>${2000000}</Text>
                           </Td>
                           <Td>
                           {
                             addTravelers.map((ele)=>(
                                 <Box>
                                   <>
                                   {ele.name} - {row.costWithPreExisting}
                                   </>
                                 </Box>
                             ))}
                             </Td> 
                         <Td>{row.adjustment}</Td>
                         <Td>Single</Td>
                         <Td onClick={() => handleRowClick(index, row)} >Select</Td>
                     </Tr>
                     </>
                      ))}
                  </Tbody>
              </Table>
          </TableContainer>
          </Box>


            
          <div style={{ display: "flex", gap: "20px", marginTop: "40px" }}>
            <Button onClick={handlePlanBackEvent} style={{ backgroundColor: "red", color: "white", padding: "4px 10px", fontFamily: "poppins",}}>
              Previous
            </Button>
            <Button
              onClick={handleToContactDetails}
              style={{
                backgroundColor: "rgb(36, 182, 183)",
                color: "white",
                padding: "4px 10px",
                fontFamily: "poppins",
              }}
            >
              Proceed
            </Button>
          </div>
        </div>
        )}

        { contactDetails === true &&(
            <div style={{ margin: "auto", width: "95%", maxHeight: "200%", padding: "18px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)"}}>
            <Flex gap={'40px'} fontSize={'14px'} pb={'20px'} mt={'20px'}>
            <Box>
              <Text>Policy Owner*</Text>
              <Select w={'200px'} background={'white'} size={'sm'} border={'1px solid #d7d7d7'} name="policyOwnerName" value={policyHolder.policyOwnerName} onChange={handlePolicyOwnerChange}>
                <option value="">Select Policy Owner</option>
                {addTravelers.map((traveler, index) => (
                  <option key={index} value={traveler.name}>
                    {traveler.name}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <Text>Policy Owner Date Of Birth*</Text>
              <Input type='date' w={'200px'} size='sm' name="policyOwnerDob" value={policyHolder.policyOwnerDob} onChange={handlePolicyOwnerChange} border={'1px solid #d7d7d7'} />            
            </Box>
            <Box>
              <Text>Policy Owner Email*</Text>
              <Input placeholder='Email' w={'180px'} size='sm' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={policyHolder.policyOwnerEmail} onChange={handlePolicyOwnerChange}/>    
              <Text color="red.500">{errors.policyOwnerEmail}</Text>        
            </Box>
            </Flex>
            <Flex gap={'40px'} fontSize={'14px'} pb={'20px'} mt={'20px'}>
            <Box>
              <Text>Policy Owner Postal Code*</Text>
              <Input placeholder='Postal code' w={'200px'} size='sm' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={policyHolder.policyOwnerPostalCode} onChange={handlePolicyOwnerChange}/>   
              <Text color="red.500">{errors.policyOwnerPostalCode}</Text>         
            </Box>
            <Box>
              <Text>Policy Owner City*</Text>
              <Input placeholder='city' w={'200px'} size='sm' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={policyHolder.policyOwnerCity} onChange={handlePolicyOwnerChange} />   
              <Text color="red.500">{errors.policyOwnerCity}</Text>         
            </Box>
            <Box>
              <Text>Policy Owner Phone*</Text>
              <Input placeholder='phone' w={'180px'} size='sm' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={policyHolder.policyOwnerPhone} onChange={handlePolicyOwnerChange} />            
              <Text color="red.500">{errors.policyOwnerPhone}</Text>
            </Box>
            </Flex>
            <Box pb='30px'>
              <Text>Policy Owner Address*</Text>
              <Input placeholder='Address' w={'200px'} size='sm' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={policyHolder.policyOwnerAddress} onChange={handlePolicyOwnerChange} />     
              <Text color="red.500">{errors.policyOwnerAddress}</Text>       
            </Box>
            <Flex gap='40px'>
            <button onClick={handleContactDetailsBackEvent} style={{ backgroundColor: "red", color: "#fff", padding: "6px 12px", fontWeight: 400, cursor: "pointer", borderRadius: "4px", width: "fit-content", alignSelf: "flex-end", }} > Previous</button>
            <button onClick={handleGetQuote} style={{ backgroundColor: "#24b6b7", color: "#fff", padding: "6px 12px", fontWeight: 400, cursor: "pointer", borderRadius: "4px", width: "fit-content", alignSelf: "flex-end", }} > Get Quote</button>
            </Flex></div>
        )}
        <Description/>
      </div>
      <VTCDetailsModal isOpen={isVtcModalOpen} onClose={handleVtcModalClose} onSubmit={handleModalSubmit} />
    </div>
  );
}

export default TripDetails;