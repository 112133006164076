export const IS_LOADING = "IS_LOADING";
export const SET_USER = "SET_USER";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const CUSTOMER_SIGNUP = "CUSTOMER_SIGNUP";
export const CUSTOMER_LOGIN = "CUSTOMER_LOGIN";
export const CUSTOMER_PROFILE = "CUSTOMER_PROFILE";
export const CUSTOMER_EDIT_PROFILE = "CUSTOMER_EDIT_PROFILE";
export const ADVISOR_SIGNUP = "ADVISOR_SIGNUP";
export const ADVISOR_LOGIN = "ADVISOR_LOGIN";
export const SUPERVISA_QUICKNOTE = "SUPERVISA_QUICKNOTE";
export const STUDENTVISA_QUICKNOTE = "STUDENTVISA_QUICKNOTE";
export const CUSTOMER_QUOTE_LIST="CUSTOMER_QUOTE_LIST"
export const VISITOR_QUOTE_LIST='VISITOR_QUOTE_LIST'
export const ADVISOR_PROFILE_LIST = "ADVISOR_PROFILE_LIST";
export const ADVISOR_GENERATEQUOTE = "ADVISOR_GENERATEQUOTE";
export const ADVISOR_CALCULATEVTC = "ADVISOR_CALCULATEVTC";
export const ADVISOR_GETQUOTES = "ADVISOR_GETQUOTES";
export const SUPER_ADMIN_LOGIN="SUPER_ADMIN_LOGIN";
export const ADMIN_CREATE_LIST="ADMIN_CREATE_LIST";
export const MENU_LIST_TYPE="MENU_LIST_TYPE";
export const ADMIN_LOGIN_TYPE="ADMIN_LOGIN_TYPE";
export const ADVISOR_SINGLE_QUOTE="ADVISOR_SINGLE_QUOTE";
export const CUSTOMER_SINGLE_QUOTE="CUSTOMER_SINGLE_QUOTE";
export const PAGES_DETAIL="PAGES_DETAIL";
export const PAGES_DETAILS_EDIT="PAGES_DETAILS_EDIT";
export const PAGES_DETAILS_ALL="PAGES_DETAILS_ALL";
export const ADMIN_USER_GET_LIST="ADMIN_USER_GET_LIST";
export const AGA_MGA_GET_ALL="AGA_MGA_GET_ALL";
export const CUSTOMER_GET_ALL="CUSTOMER_GET_ALL"

export const isLoading = (data) => {
  return {
    type: IS_LOADING,
    payload: data,
  };
};
