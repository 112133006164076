import React from 'react'
import Sidebar from '../../../Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Flex, Text } from '@chakra-ui/react'



const ViewDeclinedTranscationList = () => {
    const params= useParams()
    const navigate=useNavigate()
    console.log(params.id)
  
  
    const handleBack = () => {
      navigate(-1);
    }


return (
    <Sidebar headingText={'Declined Transcation List'}>
       <Box w='95%' m='auto' mt={'70px'}>
            <Button size={'sm'} background={'#9c27b0'} color='white' mb={'20px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
            <Text fontSize={'20px'}>Policy Details</Text>

          <Flex justifyContent={'space-around'}>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy No:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Created date:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Amount:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Duration:</Text>
            </Box>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'10px'}t color={'#3C4858'} fontWeight={'300'}>{params.id}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{'2024-04-27'}</Text>
                <Text mt={'10px'} textAlign={'left'} color={'#3C4858'} fontWeight={'300'}>{'$0156  '}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{params.id}</Text>
            </Box>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Holder Name:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>First Date Of Cover:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>First Date Of Cover:</Text>
            </Box>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'10px'}t color={'#3C4858'} fontWeight={'300'}>{params.id}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{'2024-04-27'}</Text>
                <Text mt={'10px'} textAlign={'left'} color={'#3C4858'} fontWeight={'300'}>{'$0156  '}</Text>
            </Box>
          </Flex>



        <Box background={'white'} mt={'50px'} p={'20px'}> 
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                    <col style={{ width: '15%'   }} />
                    <col style={{ width: '10%'    }} />
                    <col style={{ width: '15%'    }} />
                </colgroup>
                <thead>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Card Holder Name</th>
                        <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Date	</th>
                        <th style={{textAlign:'left',padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Transcation ID</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Ref ID</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Auth Code</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Amount CAD</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Transcation Description</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Aniket Anil Sawant	</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>2024-01-24	</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>
                        Premium(Policy Issued)</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Credit</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>155</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>120245072055	</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>This transaction has been approved.	</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Update</td>
                    </tr>
                </tbody>
                 </table>
          </Box>
        </Box>
    </Sidebar>
  )
}

export default ViewDeclinedTranscationList
