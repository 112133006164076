import React from 'react'
import Sidebar from '../Sidebar'

const Commission = () => {
  return (
    <Sidebar headingText={'Commission'}>
      
    </Sidebar>
  )
}

export default Commission
