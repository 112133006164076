import React from 'react'
import Sidebar from '../../Sidebar'

const EditAdmin = () => {



return (
    <Sidebar headingText={'Edit Admin'}>
      
    </Sidebar>
  )
}

export default EditAdmin
