import {
  Button,Checkbox,Input,Select,Box,Flex,Text,TableContainer,Table,Thead,Tr,Th,Td,Tbody,Stack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { buttonBackgroundColor} from "../../../Utils/UniversalColor";
import { VTCDetailsModal } from "../../Advisor/AdvisorQuote/Validation";
import Description from "./Description";
import { useDispatch } from "react-redux";
import RadioOption from "../../../Utils/RadioCard";
import { advisorCalculateVtc, advisorGenerateQuote } from "../../Advisor/Dependencies/action";
// import Api from "../../../dependencies/utils/Api";



function TripDetails() {
  const toast = useToast();
  const [tripDetailsFirst, setTripDetailsFirst] = useState(true);
  const [tripDetailsSecond, setTripDetailsSecond] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const [waitingPeriod, setWaitingPeriod] = useState("0");
  const [checked, setChecked] = useState(false);
  const [isVtcModalOpen, setIsVtcModalOpen] = useState(false);
  const [contactDetails,setContactDetails]=useState(false)
  const [data,setData]=useState([])
  const dispatch=useDispatch()
  const [policyLimit,setPolicyLimit]=useState('25000')
  const [dedecutibles,setDeductibles]=useState ('0')
  const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState('1')
  const [PlanType,setPlanType]=useState('Standard')
  const [selectedCostOption, setSelectedCostOption] = useState('');
  const [message,setMessage]=useState('')
  const [duration,setDuration]=useState('')
  const [amount,setAmount]=useState(0)
  const [addTravelers, setAddTravelers] = useState([
    {
      name: "",
      gender: "",
      dob: "",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
    },
  ]);
 
    const [travelersError, setTravelersError] = useState([
      {
        name: "",
        gender: "",
        dob: "",
        relation: "",
        benificiaryDob: "",
        benificiaryName: "",
      },
    ]);
    const [formData, setFormData] = useState({
      firstDate: "",
      lastDate: "",
      departureDate: "",
      departureMaxDate: "",
    });
    const [policyHolder, setPolicyHolder] = useState({
      policyOwnerName: '',
      policyOwnerDob: '',
      policyOwnerEmail: '',
      policyOwnerAddress: '',
      policyOwnerPostalCode: '',
      policyOwnerCity: '',
      policyOwnerPhone: ''
    });

    const handlePolicyLimitChange = (value) => {
        setPolicyLimit(value);
        // handleModalSubmit()
    };
    const handleDeductiblesChange = (value) => { 
      setDeductibles(value);
      // handleModalSubmit()
    };
    const handlePlanTypeChange = (value) => { 
      setPlanType(value);
      // handleModalSubmit()
    };

    const isInitialRender = useRef(true);

    useEffect(() => {
      if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
      }
      handleModalSubmit();
    }, [PlanType, policyLimit, policyTypeSuperVisa, dedecutibles]);

    const handlePolicyOwnerChange = (e) => {
      const { name, value } = e.target;
      setPolicyHolder({ ...policyHolder, [name]: value });
      const selectedPolicyHolder = addTravelers.find((traveler) => traveler.name === value);
      if (selectedPolicyHolder) {
        setPolicyHolder({ ...policyHolder, policyOwnerDob: selectedPolicyHolder.dob, policyOwnerName: selectedPolicyHolder.name });
      }
    };
    
    const [errors, setErrors] = useState({
      firstDate: "Enter First Date cover",
      lastDate: "",
      departureDate: "",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    
      if (name === "lastDate") {
        if (!formData.firstDate) {
          alert("Please select the first date of cover first");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
          return;
        }
        const startDate = new Date(formData.firstDate);
        const endDate = new Date(value);
        const diffInMilliseconds = endDate - startDate;
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
    
        if (endDate < startDate) {
          alert("Last date cannot be earlier than the first date");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
        } else if (diffInMilliseconds > oneYearInMilliseconds) {
          alert("Duration cannot exceed 1 year");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
        } else {
          const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
          setDuration(durationInDays);
        }
      }
    };

  const startDate = new Date(formData.firstDate);
  let endDate = null;

  if (formData.firstDate) {
    endDate = new Date(startDate);
    endDate.setFullYear(startDate.getFullYear() + 1);
  }

  let durat = 0;
  if (endDate) {
    const diff = endDate.getTime() - startDate.getTime();
    durat = Math.ceil(diff / (1000 * 60 * 60 * 24));
  }

  const handleToTravellerDetails = () => {
    if(!formData.departureDate || !formData.firstDate || !formData.lastDate){
      alert('Please enter date of departure')
      return;
    }
      setTripDetailsFirst(false);
      setTripDetailsSecond(true)
  };

  const handleToContactDetails = () =>{
    if(!selectedCostOption){
      alert("Please selected Costing")
      return;
    }
      setOpenPlan(false)
      setContactDetails(true)
  }

  const handleTripDetailsBackEvent = () => {
    setTripDetailsSecond(false);
    setTripDetailsFirst(true)
  };

  const handlePlanBackEvent = () =>{
    setOpenPlan(false)
    setTripDetailsSecond(true)
  }

  const handleContactDetailsBackEvent = ( ) =>{
    setOpenPlan(true)
    setContactDetails(false)
  }

  const handleCheckbox = () => {
    setChecked(true);
  };

  const handleRemoveTraveller = (index) => {
    const updatedTravelers = [...addTravelers];
    updatedTravelers.splice(index, 1);
    setAddTravelers(updatedTravelers);
  };

  const handleVtcModalClose = () => {
    setIsVtcModalOpen(false);
  };

  useEffect(() => {
    if (!formData.departureDate) {
      setWaitingPeriod("NA");
    } else {
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);
    
      const selectedDate = new Date(formData.departureDate);
      const firstDate = new Date(formData.firstDate)
      const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
      const isTodayOrFuture = selectedDate >= today;
    
      if (selectedDate>=firstDate) {
        setWaitingPeriod("NA");
      } else {
        setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
      }
    }
  }, [formData.departureDate]);

  const handleAddTraveller = () => {
    if (addTravelers.length < 3) {
      setAddTravelers([
        ...addTravelers,
        {
          name: "",
          gender: "",
          dob: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
    } else {
      toast({ title: "you can not add more than 3 family members." });
    }
  };

  const handleChangeTraveler = (index, e) => {
    const { name, value } = e.target;
    if (name === "dob" || name === "benificiaryDob") {
      const selectedDate = new Date(value);
      const minDate = new Date();
      minDate.setDate(minDate.getDate() - 15);
      if (selectedDate >= minDate) {
          alert("Users must be at least 15 days older than today.");
          return;
      }
  }
    setAddTravelers((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = { ...updatedState[index], [name]: value };
      return updatedState;
    });
  };

  const handleSubmitTravellerForm = () => {
    const isAnyBeneficiaryDetailEmpty = addTravelers.some(
      (traveler) =>
         traveler.name === "" ||
          traveler.gender === "" ||
          traveler.dob === "" ||
        traveler.benificiaryName === "" ||
        traveler.benificiaryDob === "" ||
        traveler.relation === ""
    );
    if (isAnyBeneficiaryDetailEmpty) {
      alert("Please fill in all mandatory details.")
      return;
    }
    if(checked && addTravelers.length<3){
      alert('Select Atleast 3 member of family')
      return;
    }
    const isAnyAgeBetween27to69 = addTravelers.some((traveler) => {
      const dob = new Date(traveler.dob);
      const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
      return age >= 27 && age <= 69;
    });
    
      if (checked && !isAnyAgeBetween27to69) {
        alert("At least one family member's age should be between 27 to 69 years for family coverage.");
        return;
      }
      const isAllAgeGreaterThanOne = addTravelers.every((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 1;
      });
    
      if (checked && !isAllAgeGreaterThanOne) {
        alert("family member must be greater than one year");
        return;
      }
    setIsVtcModalOpen(true)
  };

  const handleModalSubmit = async () => {
    setTripDetailsSecond(false)
    setOpenPlan(true);
        const updatedTravelers = addTravelers.map((traveler) => ({
          insuredName: traveler.name,
          insuredGender: traveler.gender,
          insuredDOB: traveler.dob,
          insuredRelation: traveler.relation,
          beneficiaryName: traveler.benificiaryName,
          beneficiaryDob: traveler.benificiaryDob,
        }));
      const dataToLog = {
          firstDateOfCover: formData.firstDate,
          lastDateOfCover: endDate.toISOString().split("T")[0],
          deductible:parseInt(dedecutibles),
          policyLimit:parseInt(policyLimit),
          planType:PlanType,
          // departureDate: formData.departureDate,
          insuredDetailsRequests: updatedTravelers,
          policyType:'VTC',
          familyPolicy:checked,
      };
        console.log(dataToLog,"vtc submit data")
        dispatch(advisorCalculateVtc(dataToLog)).then(res => {
          const {status, data} = res
          setData(data)
          console.log(res,'data')
        })
  };

  const updateAddTravelers = (response) => {
    console.log(response,'respnse')
    setAddTravelers(prevAddTravelers => {
      const updatedTravelers = [...prevAddTravelers];
      response.forEach(responseTraveler => {
        const index = updatedTravelers.findIndex(traveler => traveler.name === responseTraveler.name);
        if (index !== -1) {
          updatedTravelers[index] = {
            ...updatedTravelers[index],
            costWithPreExisting: responseTraveler.costWithPreExisting,
            costWithoutPreExisting: responseTraveler.costwithoutPreExisting
          };
        }
      });
      return updatedTravelers;
    });
  };

  const handleCostOptionChange = (index, value, ele) => {
    setSelectedCostOption(prevSelectedCostOption => ({
      ...prevSelectedCostOption,
      [index]: value
    }));
    // Exclude the value of the currently selected button from the calculation
    const selectedValues = Object.values(selectedCostOption).filter((val, idx) => idx !== index);
    const totalSelectedValue = selectedValues.reduce((acc, curr) => acc + curr, 0);
    // Calculate the total value from all selected radio buttons
    const totalValue = totalSelectedValue + value;
    const selectedOption = (value === ele.costWithPreExisting) ? 'withPreExisting' : 'withoutPreExisting';
    const message = selectedOption === 'withPreExisting' ? data.messageOfPreExisting : data.messageOfWithoutPreExisting;
    console.log(message, 'selected', ele, value, index);
    setMessage(message);
    setAmount(totalValue);
  };
  // const handleCostOptionChange = (index, value, ele) => {
  //   setSelectedCostOption(prevSelectedCostOption => ({
  //     ...prevSelectedCostOption,
  //     [index]: value
  //   }));
  
  //   const selectedOption = (value === ele.costWithPreExisting) ? 'withPreExisting' : 'withoutPreExisting';
  //   const message = selectedOption === 'withPreExisting' ? data.messageOfPreExisting : data.messageOfWithoutPreExisting;
  //   console.log(message);
  //   setMessage(message)
  // };
  
  const handleGetQuote = () =>{
    const updatedTravelers = addTravelers.map((traveler) => ({
      nameOfInsured: traveler.name,
      gender: traveler.gender,
      dateOfBirth: traveler.dob,
      beneficiaryRelation: traveler.relation,
      beneficiaryName: traveler.benificiaryName,
      beneficiaryDOB: traveler.benificiaryDob,
      costWithPreExisting:traveler.costWithPreExisting,
      costWithoutPreExisting:traveler.costWithoutPreExisting
    }));
    const dataToSend = {
      email: policyHolder.policyOwnerEmail,
      firstDateOfCover: formData.firstDate,
      lastDateOfCover:formData.lastDate,
      policyEndDate: formData.lastDate,
      duration:parseInt(duration),
      country:1,
      province:'Ontario',
      departureFromCountryOfOrigin:formData.departureDate,
      waitingPeriod:waitingPeriod,
      policyLimit:policyLimit,
      deductible:dedecutibles,
      paymentFrequency:0,
      policyOwner:policyHolder.policyOwnerName,
      policyOwnerDateOfBirth:policyHolder.policyOwnerDob,
      policyOwnerEmail:policyHolder.policyOwnerEmail,
      policyOwnerAddress:policyHolder.policyOwnerAddress,
      policyOwnerPostalCode:policyHolder.policyOwnerPostalCode,
      policyOwnerCity:policyHolder.policyOwnerCity,
      policyOwnerPhone:policyHolder.policyOwnerPhone,
      planType:PlanType,
      insuredTravellerDetailsRequest: updatedTravelers,
      policyType:'VTC',
      amount: amount,
      familyCoverage:checked===true?1:0
  };
      console.log(dataToSend,'dataTo Send')
      dispatch(advisorGenerateQuote(dataToSend)).then(res => {
        const {status, data} = res
        console.log(data,'data')
      })
  }


return (
    <div style={{ width: "100%", padding: "10px",paddingTop:'0px',textAlign:'left'}}>
        <Text marginLeft={'40px'} fontFamily={'poppins'} fontWeight={'700'} mb={'15px'}>Visitor Insurance</Text>
      <div style={{ display: "flex",flexDirection: "column",alignItems: "center",width:'90%',margin:'auto'}}>

        {tripDetailsFirst && (
          <div
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "auto",
              padding: "18px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p style={{ fontFamily: "poppins", fontSize: "18px", fontWeight: 400, marginBottom: "20px",borderBottom: "2px solid #088183"}}>Trip Details</p>
            <div
              style={{
                gap: "20px",
                margin: "auto",
                padding: "18px",
              }}
            >
              <Flex gap={'40px'} fontSize={'14px'} pb={'25px'}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>First Date of Cover</span>
                <Input name="firstDate" min={new Date().toISOString().split("T")[0]}
                  onChange={handleChange} w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Last Date of Cover</span>
                <Input name="lastDate" w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} 
                  value={formData.lastDate} onChange={handleChange}/>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Duration</span>
                <Input w={'80px'} size='sm' backgroundColor={'#eaeaea'} value={duration} border={'1px solid #d7d7d7'} />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Country</span>
                <p> Canada</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Province</span>
                <p>Ontario</p>
              </div>
              </Flex>

              <Flex gap={'40px'} fontSize={'14px'}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Departure from country of origin</span>
                <Input name="departureDate" max={formData.departureMaxDate}
                  onChange={handleChange} w={'230px'} size='sm' type='date' border={'1px solid #d7d7d7'} />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Waiting Period</span>
                <input
                  placeholder="Basic usage"
                  name="waiting"
                  value={waitingPeriod}
                />
              </div>
              <Box >
                <Text>Family Coverage</Text>
              <Checkbox pt={'7px'} onChange={handleCheckbox} size="md" colorScheme="green"></Checkbox>
              </Box>
              </Flex>
              <button
                onClick={handleToTravellerDetails}
                style={{
                  gridColumn: "1 / span 1",
                  backgroundColor: "#24b6b7",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                  marginTop:'40px'
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        )}

        {tripDetailsSecond && (
          <div
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "auto",
              padding: "18px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p
              style={{
                fontFamily: "poppins",
                fontSize: "18px",
                fontWeight: 400,
                marginBottom: "20px",
                borderBottom: "2px solid #088183",
              }}
            >
              Traveller Details family
            </p>
            <Flex fontFamily={'poppins'} fontSize={'14px'} gap={'10px'} justifyContent={'end'} cursor={'pointer'} color={'teal'} mt={'10px'} mb={'10px'}>
                <Flex alignItems={'center'}>
                  <IoMdAdd style={{fontWeight:'bold'}}/>
                    </Flex>
                  <Text onClick={handleAddTraveller}>Add Traveller</Text>
              </Flex>
            {addTravelers.map((item, index) => {
              return (
                <>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    gap: "20px",
                    margin: "auto",
                    padding: "18px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>Name Of Insured</span>
                    <Input name="name" value={item.name}
                      onChange={(e) => handleChangeTraveler(index, e)} w={'180px'} size='sm' border={'1px solid #d7d7d7'} min={new Date().toISOString().split('T')[0]} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>Gender</span>
                    <Select w={'180px'} background={'white'} size={'sm'} border={'1px solid #d7d7d7'}
                       value={item.gender}
                       name="gender"
                      onChange={(e) => handleChangeTraveler(index, e)}>
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
 
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>Date of Birth</span>
                    <Input name="dob"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.dob}
                       w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} />
                  </div>
                  </div>
                  <Flex gap={'20px'} p={'18px'} pt={'5px'} pb={'20px'}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>
                      Beneficiary Name
                    </span>
                    <Input name="benificiaryName" placeholder="Name"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.benificiaryName} w={'180px'} size='sm' border={'1px solid #d7d7d7'} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>Relation</span>
                    <Input name="relation"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.relation}
                      placeholder="Relation" w={'180px'} size='sm' border={'1px solid #d7d7d7'} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: "8px" }}>Beneficiary DOB</span>
                    <Input onChange={(e) => handleChangeTraveler(index, e)}
                      name="benificiaryDob"
                      value={item.benificiaryDob}
                      placeholder="Select Date and Time"
                       w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} />
                  
                    </div>
                    {index > 0 && (
                    <Flex justifyContent={'end'}>
                            <IoMdCloseCircle
                              style={{fontSize:'22px',color:buttonBackgroundColor}}
                              onClick={() => handleRemoveTraveller(index)}
                              />
                          </Flex>
                        )}
                    </Flex>
                   </>
              );
            })}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gap: "8px",
              }}
            >
              <button
                onClick={handleTripDetailsBackEvent}
                style={{
                  backgroundColor: "red",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                Previous
              </button>
              <button
                onClick={handleSubmitTravellerForm}
                style={{
                  backgroundColor: "#24b6b7",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                Proceed & Confirm
              </button>
            </div>
          </div>
        )}

        {openPlan === true && (
        <div style={{ margin: "auto", width: "95%", maxHeight: "200%", padding: "18px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)"}}>
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 400,
              marginBottom: "20px",
              borderBottom: "3px solid #088183",
            }}
          >
            Select Plan
          </p>
             <Box gap={'70px'} mt={'15px'} mb={'20px'}>
                  <Box>
                    <Text fontSize={'14px'} mt={'15px'} mb={'7px'}>Policy Limit</Text>
                    <Stack spacing={4} direction='row'>
                          <RadioOption value='25000' isSelected={policyLimit === '25000'} onChange={handlePolicyLimitChange}>
                          $25000
                          </RadioOption>
                          <RadioOption value='50000' isSelected={policyLimit === '50000'} onChange={handlePolicyLimitChange}>
                          $50000
                          </RadioOption>
                          <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                          $100000
                          </RadioOption>
                          <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                          $150000
                          </RadioOption>
                          <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                          $300000
                          </RadioOption>
                    </Stack>
                  </Box>
                  <Box>
                    <Text fontSize={'14px'} mt={'15px'} mb={'7px'}>Select Plan Type</Text>
                    <Stack spacing={4} direction='row'>
                      <RadioOption value='Standard' isSelected={PlanType === 'Standard'} onChange={handlePlanTypeChange}>
                        Standard</RadioOption>
                      <RadioOption value='Enhanced' isSelected={PlanType === 'Enhanced'} onChange={handlePlanTypeChange}>
                        Enhanced</RadioOption>
                      <RadioOption value='Premium' isSelected={PlanType === 'Premium'} onChange={handlePlanTypeChange}>
                       Premium </RadioOption>
                    </Stack>
                  </Box>
                  </Box>
                  
                  {
                    PlanType === 'Premium'? 
                    <Box mb={'30px'}>
                    <Text fontSize={'14px'} >Deductible</Text>
                    <Stack spacing={4} direction='row'>
                      <RadioOption value='500' isSelected={dedecutibles === '500'} onChange={handleDeductiblesChange}>
                      $500</RadioOption>
                      <RadioOption value='1000' isSelected={dedecutibles === '1000'} onChange={handleDeductiblesChange}>
                      $1000</RadioOption>
                      <RadioOption value='3000' isSelected={dedecutibles === '3000'} onChange={handleDeductiblesChange}>
                      $3000</RadioOption>
                    </Stack>
                  </Box>:
                      <Box mb={'30px'}>
                      <Text fontSize={'14px'} >Deductible</Text>
                      <Stack spacing={4} direction='row'>
                        <RadioOption value='0' isSelected={dedecutibles === '0'} onChange={handleDeductiblesChange}>
                        $0</RadioOption>
                        <RadioOption value='100' isSelected={dedecutibles === '100'} onChange={handleDeductiblesChange}>
                        $100</RadioOption>
                        <RadioOption value='250' isSelected={dedecutibles === '250'} onChange={handleDeductiblesChange}>
                        $250 </RadioOption>
                        <RadioOption value='500' isSelected={dedecutibles === '500'} onChange={handleDeductiblesChange}>
                        $500</RadioOption>
                        <RadioOption value='1000' isSelected={dedecutibles === '1000'} onChange={handleDeductiblesChange}>
                        $1000</RadioOption>
                        <RadioOption value='3000' isSelected={dedecutibles === '3000'} onChange={handleDeductiblesChange}>
                        $3000</RadioOption>
                      </Stack>
                    </Box>
                  }

        
        <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>
                      User Name
                    </Th>
                    <Th p={'7px'} fontSize={'10px'}>
                      Plan Name
                    </Th>
                    <Th p={'7px'} fontSize={'10px'}>
                      With Pre-existing
                    </Th>
                    <Th p={'7px'} fontSize={'10px'}>
                      Without Pre-existing
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                {data?.listOfInsuredQutation?.map((ele, index) => (
                  <>
                    <Tr key={index} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                      <Td p={'2px'}>{ele.name}</Td>
                      <Td p={'2px'}>{ele.planType}</Td>
                      <Td p={'2px'} display={'flex'}>
                        <input
                          type="radio"
                          name={`costOption-${index}`}
                          value={ele.costWithPreExisting}
                          checked={selectedCostOption[index] === ele.costWithPreExisting}
                          onChange={() => handleCostOptionChange(index, ele.costWithPreExisting,ele)}
                        />
                        <Text pl={'10px'}>
                        {ele.costWithPreExisting}
                        </Text>
                      </Td>
                      <Td p={'2px'}>
                        <Flex>
                        <input
                          type="radio"
                          name={`costOption-${index}`}
                          value={ele.costwithoutPreExisting}
                          checked={selectedCostOption[index] === ele.costwithoutPreExisting}
                          onChange={() => handleCostOptionChange(index, ele.costwithoutPreExisting,ele)}
                          />
                        <Text pl={'10px'}>
                        {ele.costwithoutPreExisting}
                        </Text>
                          </Flex>
                      </Td>
                    </Tr>
                    </>
                ))}
                </Tbody>
              </Table>
            </TableContainer>
                  
          <Text color={'red'} fontSize={'11px'}>{message}</Text>
          <div style={{ display: "flex", gap: "20px", marginTop: "40px" }}>
            <Button onClick={handlePlanBackEvent} style={{ backgroundColor: "red", color: "white", padding: "4px 10px", fontFamily: "poppins",}}>
              Previous
            </Button>
            <Button
              onClick={handleToContactDetails}
              style={{
                backgroundColor: "rgb(36, 182, 183)",
                color: "white",
                padding: "4px 10px",
                fontFamily: "poppins",
              }}
            >
              Proceed
            </Button>
          </div>
        </div>
        )}

        { contactDetails === true &&(
            <div style={{ margin: "auto", width: "95%", maxHeight: "200%", padding: "18px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)"}}>
            <Flex gap={'40px'} fontSize={'14px'} pb={'20px'} mt={'20px'}>
            <Box>
              <Text>Policy Owner*</Text>
              <Select w={'200px'} background={'white'} size={'sm'} border={'1px solid #d7d7d7'} name="policyOwnerName" value={policyHolder.policyOwnerName} onChange={handlePolicyOwnerChange}>
                <option value="">Select Policy Owner</option>
                {addTravelers.map((traveler, index) => (
                  <option key={index} value={traveler.name}>
                    {traveler.name}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <Text>Policy Owner Date Of Birth*</Text>
              <Input type='date' w={'200px'} size='sm' name="policyOwnerDob" value={policyHolder.policyOwnerDob} onChange={handlePolicyOwnerChange} border={'1px solid #d7d7d7'} />            
            </Box>
            <Box>
              <Text>Policy Owner Email*</Text>
              <Input placeholder='Email' w={'180px'} size='sm' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={policyHolder.policyOwnerEmail} onChange={handlePolicyOwnerChange}/>    
              <Text color="red.500">{errors.policyOwnerEmail}</Text>        
            </Box>
            </Flex>
            <Flex gap={'40px'} fontSize={'14px'} pb={'20px'} mt={'20px'}>
            <Box>
              <Text>Policy Owner Postal Code*</Text>
              <Input placeholder='Postal code' w={'200px'} size='sm' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={policyHolder.policyOwnerPostalCode} onChange={handlePolicyOwnerChange}/>   
              <Text color="red.500">{errors.policyOwnerPostalCode}</Text>         
            </Box>
            <Box>
              <Text>Policy Owner City*</Text>
              <Input placeholder='city' w={'200px'} size='sm' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={policyHolder.policyOwnerCity} onChange={handlePolicyOwnerChange} />   
              <Text color="red.500">{errors.policyOwnerCity}</Text>         
            </Box>
            <Box>
              <Text>Policy Owner Phone*</Text>
              <Input placeholder='phone' w={'180px'} size='sm' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={policyHolder.policyOwnerPhone} onChange={handlePolicyOwnerChange} />            
              <Text color="red.500">{errors.policyOwnerPhone}</Text>
            </Box>
            </Flex>
            <Box pb='30px'>
              <Text>Policy Owner Address*</Text>
              <Input placeholder='Address' w={'200px'} size='sm' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={policyHolder.policyOwnerAddress} onChange={handlePolicyOwnerChange} />     
              <Text color="red.500">{errors.policyOwnerAddress}</Text>       
            </Box>
            <Flex gap='40px'>
            <button onClick={handleContactDetailsBackEvent} style={{ backgroundColor: "red", color: "#fff", padding: "6px 12px", fontWeight: 400, cursor: "pointer", borderRadius: "4px", width: "fit-content", alignSelf: "flex-end", }} > Previous</button>
            <button onClick={handleGetQuote} style={{ backgroundColor: "#24b6b7", color: "#fff", padding: "6px 12px", fontWeight: 400, cursor: "pointer", borderRadius: "4px", width: "fit-content", alignSelf: "flex-end", }} > Get Quote</button>
            </Flex></div>
        )}
        <Description/>
      </div>
      <VTCDetailsModal isOpen={isVtcModalOpen} onClose={handleVtcModalClose} onSubmit={handleModalSubmit} />
    </div>
  );
}

export default TripDetails;
