import React from 'react';
import Sidebar from '../Sidebar';
import { Box, Button, Flex, Image, Tooltip } from '@chakra-ui/react';
import { FaRegEdit } from "react-icons/fa";


const Deductibles = () => {


return (
    <Sidebar headingText="Deductibles List">
    <Box w={'90%'} m={'auto'} mt={'70px'}>
      <Flex justifyContent={'end'} mb={'5px'}>
          <Button size={'sm'} borderRadius={'20px'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'}>Add New</Button>
      </Flex>
    <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
      <Box>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <colgroup>
                  <col style={{ width: '7%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '20%' }} />
                  <col style={{ width: '10%' }} />
              </colgroup>
              <thead>
                  <tr style={{borderBottom:'1px solid #dddddd'}}>
                      <th style={{ textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Amount</th>
                      <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>	Standard + Enhanced Discount (Age 0-79)</th>
                      <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Standard + Enhanced Discount (Age 80-89)</th>
                      <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Premium Discount (Age 0-69)</th>
                      <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                      <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                  </tr>
              </thead>
              <tbody>
                  <tr style={{borderBottom:'1px solid #dddddd'}}>
                      <td style={{  textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>$0</td>
                      <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Base</td>
                      <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}> Not Applicable</td>
                      <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Not Applicable</td>
                      <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>
                        <Button size={'sm'} background={'green'} color={'white'}>Active</Button>
                      </td>
                      <td style={{   textAlign: 'left', padding: '8px',fontSize:'20px',fontFamily:'Open Sans, sans-serif' }}>
                      <FaRegEdit />
                      </td>
                  </tr>
              </tbody>
               </table>
      </Box>
      </Box>
    </Box>
  </Sidebar>
  )
}

export default Deductibles
