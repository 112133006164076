import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import {Flex, Box,Select, Button, space} from '@chakra-ui/react'
import { useDispatch } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaDownload } from "react-icons/fa6";
import Sidebar from '../Sidebar';
import './style.css'
import { useNavigate } from 'react-router-dom';
import Api from '../../../dependencies/utils/Api';


const Quotes = () => {
    const navigate=useNavigate()
    const pagination = true;
    const paginationPageSizeSelector = [2, 5, 1000];
    const [quotesData,setQuotesData]=useState([])


useEffect(()=>{
  getAllQuotes()
},[])


const getAllQuotes = ()=>{
  Api.GET(`http://192.168.1.6:8080/Quote/getListOfQuotation`).then((res)=>{
    console.log(res.data,'dat')
    if (res.status === 200) {
      setQuotesData(res.data);
      const formattedData = res.data.map((quote, index) => ({
        id: index + 1,
        employeeName: '',
        designation: '',
        product: quote.product==='Studentvisa'?'STC':'VTC',
        quotationNo: quote.quotationNo,
        dateOfIssue: quote.createdAt,
        tripType: quote.tripType,
        customerName: quote.policyHolderName,
        quoteAmount: `$${quote.quoteAmount}`,
      }));
      setRowData(formattedData);
    }
  })
}

  const downloadCellRenderer = (params) => {
    const handleDownload = () => {
      navigate('/admin/download-quote-documents')
      console.log('Download clicked for row:', params.data);
    };
  return(
    <div style={{ display: 'flex', justifyContent: 'center',marginTop:'10px'}}>
    <FaDownload color='grey' onClick={handleDownload} style={{ cursor: 'pointer' }} />
  </div>
  )
};

  const viewTranscations = (params)=>{
    const handleView = () => {
      navigate(`/admin/quotePaymentTranscation/${params.data.quotationNo}`)
      console.log('Download clicked for row:', params.data);
    };
  return(
    <div style={{ display: 'flex', justifyContent: 'center',marginTop:'5px'}}>
      <Button onClick={handleView} background={'#4caf50'} color={'white'} size={'xs'}>View</Button>
  </div>
  )
  }

  const handleAction = (params)=>{
    const handleChange = (value) => {
      if(value==='edit'){
        navigate(`/admin/editQuote/${params.data.quotationNo}`)
      }
      if(value==='send'){
        alert('Quote has been sent successfully')
      }
      console.log('Download clicked for row:', params.data,value);
    };
  return(
    <div style={{ display: 'flex', justifyContent: 'center',marginTop:'5px'}}>
       <select  onChange={(e) => handleChange(e.target.value)}>     
          <option value="">Select</option>
          <option value="edit">Edit Quote</option>
          <option value="send">Send Quote</option>
          </select>
  </div>
  )
  }
  const [colDefs, setColDefs] = useState([
    { field: "employeeName",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:280},
    { field: "designation", wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true },
    { field: "product",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:120},
    { field: "tripType",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true},
    { field: "quotationNo",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:250 },
    { field: "dateOfIssue",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:220 },
    { field: "customerName",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,},
    { field: "quoteAmount",wrapHeaderText: true,headerClass:'header',width:175 },
    { field: "download",headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,cellRenderer: downloadCellRenderer,width:140,wrapHeaderText: true},
    { field: "paymentTranscations",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellRenderer:viewTranscations,autoHeight:true},
    { field: "Action",headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,cellRenderer:handleAction},
  ]);

  const [rowData, setRowData] = useState([]);



  const downloadPDF = () => {
    const MyDoc = (
        <Document>
        <Page>
            <Text>Quotations</Text>
        </Page>
        </Document>
    );
    
    const blob = new Blob([MyDoc], { type: 'application/pdf' });
    saveAs(blob, 'quotations.pdf');
    };
    
    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rowData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'quotations.xlsx');
    };
    
    const downloadCSV = () => {
        const csvContent = [
        Object.keys(rowData[0]).join(','), // Header row
        ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
        ].join('\n');
        
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'quotations.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



return (
    <Sidebar headingText={'Quotes'}>
      <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
        <Flex>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color:'#999999' }}>Select Product</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
              <option value="">All Products</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color:'#999999' }}>Select Role</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
              <option value="">Select Roles</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color:'#999999' }}>Select User</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
              <option value="">Select Users</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color:'#999999' }}>Select User</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
              <option value="">Select Users</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color:'#999999' }}>Select User</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
              <option value="">Select Users</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box mt={'20px'}>
            <Button size={'sm'} background={'#9c27b0'} color='white'>Search</Button>
          </Box>
          <Box mt={'20px'} ml={'15px'}>
            <Button size={'sm'} background={'#9c27b0'} color='white'>Reset</Button>
          </Box>
        </Flex>
        <br />
        </div>


        <Box w={'95%'} m={'auto'} background={'white'} p={'10px'} mb={'30px'}>
        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" />
          </div>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ width: '100%', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            width='100%'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
        </Box>
    </Sidebar>
  )
}

export default Quotes
