import React, { useState } from 'react';
import './SideBox.css'; // Import CSS file for styling
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { FaCartPlus } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { FaCodeCompare } from "react-icons/fa6";
import { Link } from 'react-router-dom';




const SideBox = () => {
  const [hoveredBox, setHoveredBox] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (box, event) => {
    setHoveredBox(box);
    setPosition({ x: event.target.offsetLeft - 150, y: event.target.offsetTop });
  };

  const handleMouseLeave = () => {
    setHoveredBox(null);
  };

  return (
    <div className="sidebox-container">
      <div className="sidebox">
        <div
          className="box-content"
          style={{ background: 'teal', position: 'relative' }}
          onMouseEnter={(event) => handleMouseEnter('box1', event)}
          onMouseLeave={handleMouseLeave}
        >
          <Image src="https://travelmedicare.com/public/users/images/visa_icon.png" />
          {hoveredBox === 'box1' && (
            <div className="hovered-div" style={{ left: position.x, top: position.y, background:'teal',color:'whtie'}}>
              <Box fontSize={'12px'} fontFamily={'poppins'} color={'white'} background={'teal'}>
                <Text pb={'7px'}>Super Visa</Text>
                <hr />
                <Link to='/supervisa-getQuote'>
                <Flex gap={'10px'} mt={'7px'}>
                  <Flex alignItems={'center'}>
                    <FaCartPlus />
                  </Flex>
                  <Text>Buy Online</Text>
                </Flex></Link>
                <Flex gap={'10px'} mt={'7px'}>
                  <Flex alignItems={'center'}>
                  <IoMdDownload />
                  </Flex>
                  <Text>Download</Text>
                </Flex>
                <Flex gap={'10px'} mt={'7px'}>
                  <Flex alignItems={'center'}>
                  <FaCodeCompare />
                  </Flex>
                  <Text>Comparison</Text>
                </Flex>
              </Box>
            </div>
          )}
        </div>

        <div
          className="box-content"
          style={{ background: '#673ab7', position: 'relative' }}
          onMouseEnter={(event) => handleMouseEnter('box2', event)}
          onMouseLeave={handleMouseLeave}
        >
          <Image src="https://travelmedicare.com/public/users/images/flight_icon.png" />
          {hoveredBox === 'box2' && (
            <div className="hovered-div" style={{ left: position.x, top: position.y, background:'#673ab7',color:'whtie'}}>
      <Box fontSize={'12px'} fontFamily={'poppins'} color={'white'} background={'#673ab7'}>
                    <Text pb={'7px'}>Super Visa</Text>
                    <hr />
                    <Link to='/visitor-getquote'>
                    <Flex gap={'10px'} mt={'7px'}>
                      <Flex alignItems={'center'}>
                        <FaCartPlus />
                      </Flex>
                      <Text>Buy Online</Text>
                    </Flex></Link>
                    <Flex gap={'10px'} mt={'7px'}>
                      <Flex alignItems={'center'}>
                      <IoMdDownload />
                      </Flex>
                      <Text>Download</Text>
                    </Flex>
                    <Flex gap={'10px'} mt={'7px'}>
                      <Flex alignItems={'center'}>
                      <FaCodeCompare />
                      </Flex>
                      <Text>Comparison</Text>
                    </Flex>
                  </Box>           
                   </div>
          )}
        </div>

        <div
          className="box-content"
          style={{ background: '#03a9f4', position: 'relative' }}
          onMouseEnter={(event) => handleMouseEnter('box3', event)}
          onMouseLeave={handleMouseLeave}
        >
          <Image src="https://travelmedicare.com/public/users/images/student_icon.png" />
          {hoveredBox === 'box3' && (
            <div className="hovered-div" style={{ left: position.x, top: position.y, background:'#03a9f4',color:'whtie' }}>
        <Box fontSize={'12px'} fontFamily={'poppins'} color={'white'} background={'#03a9f4'}>
                      <Text pb={'7px'}>Super Visa</Text>
                      <hr />
                      <Link to='/student-travel-getquote'>
                      <Flex gap={'10px'} mt={'7px'}>
                        <Flex alignItems={'center'}>
                          <FaCartPlus />
                        </Flex>
                        <Text>Buy Online</Text>
                      </Flex>
                      </Link>
                      <Flex gap={'10px'} mt={'7px'}>
                        <Flex alignItems={'center'}>
                        <IoMdDownload />
                        </Flex>
                        <Text>Download</Text>
                      </Flex>
                      <Flex gap={'10px'} mt={'7px'}>
                        <Flex alignItems={'center'}>
                        <FaCodeCompare />
                        </Flex>
                        <Text>Comparison</Text>
                      </Flex>
                    </Box>      
                    </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBox;
