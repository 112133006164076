import React from 'react'
import AdvisorHeader from '../../Common/Header/AdvisrHeader'

const AdvisorTeam = () => {


return (
    <div>
      <AdvisorHeader/>
    </div>
  )
}

export default AdvisorTeam
