// utils/universalColor.js
export const primaryColor = '#157b51';

export const primaryBackgroundColor = "#064d98"

export const headingsColor='black'

export const subheadingColor='#064d98'

export const buttonBackgroundColor = 'rgb(8, 129, 131)'

export const buttonColor = 'white'

export const fontFamily = 'poppins'

export const new_FooterColor = "#6F8FAF";

export const dashboardBackgroundColor = '#eee'