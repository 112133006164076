import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'



function index() {



return (
    <Box pt={'90px'} pb={'120px'}>
      <Box m={'auto'}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
       <Box>
        <Text fontSize={'28px'} pl={'40px'}> Travel Medicare</Text>
       </Box>
       <Box w={'40%'}  backgroundImage={''}>
        <Image src='https://quickinsure.co.in/static/images/sub-header/company-banner.svg'/>
       </Box>
      </Flex>
      </Box>


      <Flex fontFamily={'poppins'} w={'80%'} m={'auto'} mt={'120px'}>
        <Box w={'50%'}>
          <Text fontWeight={'600'} fontSize={'18px'}>About Us</Text>
          <Text color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}>Quickinsure.co.in is presented by Quickinsure Insurance Brokers Private Limited. It is an online insurance comparison portal, It also has offline sales force using state of art technology to help customers compare and choose from the basket of Insures in India. It is the only well-known online comparison portal focused only in General Insurance Products. Our vision is to be the leaders in simplifying and speeding up the process of buying insurance in India using technology and growth of internet of things in India. Our Vision is shared by committed and passionate team at Quickinsure at every level.</Text>
        </Box>
        <Box alignItems={'center'} w={'30%'} m={'auto'} mt={'-20px'}>
          <Image src='https://img.freepik.com/free-vector/father-shaking-hands-with-insurance-agent_74855-4412.jpg?t=st=1712053807~exp=1712057407~hmac=92e01cbf88c5019e4a5c1cdc644576e3e6f69b4dd8f681fa5790dfb54f568283&w=740'/>
        </Box>
      </Flex>


        <Flex w={'80%'} m={'auto'} mt={'60px'}>
          <Box w={'40%'} m={'auto'}>
            <Text>Our Mission</Text>
            <Text color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}>
            With an utmost honest approach, we have developed ourselves with integrity as our primary principle, to provide the best experience to all our customers and to provide products and services which our clients can purchase and recommend with confidence.
            </Text>
          </Box>
          <Box w={'40%'} m={'auto'}>
            <Text>Our Vision</Text>
            <Text color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}>With an utmost honest approach, we have developed ourselves with integrity as our primary principle, to provide the best experience to all our customers and to provide products and services which our clients can purchase and recommend with confidence.</Text>
          </Box>
        </Flex>


      <Box w={'80%'} m={'auto'} mt={'120px'}>
        <Text fontWeight={'600'}>W. R. Berkley History</Text>
        <Text color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}>
        Founded in 1967, W. R. Berkley Corporation has grown from a small investment management firm into one of the largest commercial lines property and casualty insurers in the United States. Along the way, we’ve been listed on the New York Stock Exchange, seen our revenue soar well past $7 billion, and become a well-respected Fortune 500 Company. Today, the Berkley brand comprises 53 Berkley companies worldwide. Still managed by our founder and largest shareholder, W. R. Berkley Corporation is well-positioned to respond to opportunities for future growth. In April 2017, W. R. Berkley Corporation celebrated the 50th anniversary of its founding. 1967- While attending Harvard Business School, Bill Berkley and his partner formed Berkley Dean & Company – an investment management company that was the precursor to W. R. Berkley Corporation – with a $2,500 investment. In 2007, W. R. Berkley Corporation was named Best Managed Insurance Company by Forbes Magazine and set a new record during our 40th Anniversary year by earning $744 million. Total assets surpassed $16.8 billion, total revenues exceeded $5.5 billion and net worth reached $3.7 billion. In 2008, Berkley Asset Protection Underwriters, FinSecure, Berkley Canada, Berkley Professional Liability and Berkley Offshore Underwriting Managers were all formed.
            </Text>
      </Box>


    </Box>
  )
}

export default index