import './styles.css';
import React, { useState, useEffect, useRef } from 'react';
import {Menu,MenuButton,MenuList,MenuItem,MenuItemOption,MenuGroup,MenuOptionGroup,MenuIcon,MenuCommand,MenuDivider,Button,Flex,Text,chakra,Box,Switch,IconButton,HStack,Select} from '@chakra-ui/react';
import { CSSTransition } from 'react-transition-group';
import { MdChevronLeft, MdChevronRight, MdMenu } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { storage } from '../../dependencies/store/storage';


const HoverButton = () => {
  const [activeMenu, setActiveMenu] = useState('main');
  const [menuHeight, setMenuHeight] = useState(null);
  const { t, i18n } = useTranslation(); 
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    i18n.changeLanguage(language); 
  };


  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height + 20);
  }

  const handleLogout = ()=>{
    storage.clear()
    window.location.href='/'
  }

  return (
    <>
      <Menu className="dropdown" closeOnSelect={false}>
        <MenuButton cursor={'pointer'} as={Text} rightIcon={<MdChevronRight />}>
          My TM
        </MenuButton>
        <MenuList style={{ height: menuHeight }} className="dropdown">
          <CSSTransition
            in={activeMenu === 'main'}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="main-menu">
            <Link to='/advisor-quotes'>
              <MenuItem>
                <Text>My Quotes</Text>
                {/* <Box pos="absolute" ml="80%">
                  <MdChevronRight />
                </Box> */}
              </MenuItem>
                </Link>
                <Link to='/advisor-policies'>
                <MenuItem>
                    My Policies
                    {/* <Box pos="absolute" ml="80%">
                    <MdChevronRight />
                    </Box> */}
                </MenuItem>
                </Link>
                <Link to='/advisor-commission'>
                  <MenuItem>
                      Commission
                  </MenuItem>
                  </Link>
                <MenuItem>
                <Text>Language</Text>
                <Box pos="absolute" ml="80%">
                  <MdChevronRight />
                </Box>
              </MenuItem>
                  <MenuItem onClick={handleLogout}>
                     Logout
                  </MenuItem>
            </div>
          </CSSTransition>
        </MenuList>
      </Menu>
    </>
  );
}

export default HoverButton

// function SubMenus() {
//   return (

//   )
// }
