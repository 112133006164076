import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import AdvisrHeader from '../../Common/Header/AdvisrHeader';
import {Flex, Box,Select} from '@chakra-ui/react'
import { useDispatch } from "react-redux";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { primaryColor } from '../../../Utils/UniversalColor';
import { FaDownload } from "react-icons/fa6";
import CustomerHeader from '../../Common/Header/CustomerHeader';


const CustomButtonComponent = () => {
  return <button onClick={() => window.alert('clicked') }>Push Me!</button>;
};

const AdvisorMyPolicy = () => {
  const [product, setProduct] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();

  const [colDefs, setColDefs] = useState([
    { field: "employeeName" },
    { field: "quotationNo" },
    { field: "product" },
    { field: "quotationNo" },
    { field: "dateOfQuote" },
    { field: "tripType" },
    { field: "customerName" },
    { field: "amount", filter: 'agNumberColumnFilter' },
    { field: "download" },
    { field: "Action",cellRenderer: CustomButtonComponent, }
  ]);

  const [rowData, setRowData] = useState([
    { id: 1, employeeName: "Dalbir Singh", Designation:"MGA", product: 'VTC', dateOfIssue:'15-Aprail-2024', tripType: 'Type A', customerName: 'John Doe', amount: '$100',status:"Inactive", download: '#' },
    { id: 2,  employeeName: "Dalbir Singh", Designation:"MGA", product: 'Student Travel', dateOfIssue:'15-Aprail-2024', tripType: 'Type B', customerName: 'Jane SmiTh', amount: '$150',status:"Active", download: '#' },
  ]);

  const actionCellRenderer = (params) => {
    const handleActionChange = (action) => {
      console.log('Selected Action:', action);
    };

    return (
      <select onChange={(e) => handleActionChange(e.target.value)}>
        <option value="view">View Quote</option>
        <option value="view">Send Quote</option>
        <option value="edit">Edit Quote</option>
        <option value="editTrip">Edit Trip Details</option>
        <option value="cancel">Cancel Quote</option>
      </select>
    );
  };

  useEffect(() => {
    setColDefs([
      { field: "employeeName" },
      { field: "Designation",width:80 },
      { field: "product" },
      { field: "dateOfIssue" },
      { field: "tripType" },
      { field: "customerName" },
      { field: "amount", width:100 },
      { field: "download", cellRenderer: downloadCellRenderer,width:90},
      { field: "Action", width:150 , cellRenderer: props => {
        return <>
         <select>     
          <option value="">Select</option>
          <option value="edit">Email Policy</option>
          <option value="edit">Enodorsement</option>
          <option value="editTrip">Re-issue</option>
          </select>
        </>;
    } }
    ]);
  }, []);

  const downloadCellRenderer = (params) => {
    const handleDownload = () => {
      // Implement download logic here
      console.log('Download clicked for row:', params.data);
    };

    return(
      <div style={{ display: 'flex', justifyContent: 'center',marginTop:'10px'}}>
      <FaDownload color='grey' onClick={handleDownload} style={{ cursor: 'pointer' }} />
    </div>
    )
  };

  const downloadPDF = () => {
    const MyDoc = (
      <Document>
        <Page>
          <Text>Quotations</Text>
        </Page>
      </Document>
    );

    const blob = new Blob([MyDoc], { type: 'application/pdf' });
    saveAs(blob, 'quotations.pdf');
  };

  const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rowData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'quotations.xlsx');
    };

    const downloadCSV = () => {
      const csvContent = [
        Object.keys(rowData[0]).join(','), // Header row
        ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
      ].join('\n');
      
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'quotations.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

  return (
    <div>
      {/* <CustomerHeader /> */}
      <div style={{ paddingTop: '80px', width: '80%', margin: 'auto', marginBottom: '90px' }}>
        <h3 style={{ color: primaryColor, fontSize: '22px', fontFamily: 'poppins', paddingBottom: '20px' }}>My Policy</h3>
        <Flex>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '14px' }}>Select Product</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select" onChange={(e) => setProduct(e.target.value)}>
              <option value="">All Products</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
          </Box>
          <Box>
            <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
          </Box>
        </Flex>
        <br />

        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</button>
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            frameworkComponents={{ actionCellRenderer }}
            domLayout='autoHeight'
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>

      </div>
    </div>
  );
};

export default AdvisorMyPolicy;