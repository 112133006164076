import React, { useState } from 'react';
import { Box, Button, Flex, Image, Select,Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import { buttonBackgroundColor, buttonColor } from '../../../Utils/UniversalColor';
import HeaderDropdown from '../../../Utils/Dropdown-hover/HoverButton';
import { MdCall } from "react-icons/md";


function AdvisorHeader() {
  const { t, i18n } = useTranslation(); // Access t function and i18n instance
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  // Function to handle language change
  const handleLanguageChange = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    i18n.changeLanguage(language); 
  };

return (
    <Box>
      <Flex zIndex={'9999999'} background={'white'} position={'fixed'} w={'100%'} p={'10px'} justifyContent={'space-between'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px'>
        <Box w={'18%'}>
          <Link to={'/'}>
            <Image w={'65%'} src='https://travelmedicare.com/public/users/images/logo.png' />
          </Link>
        </Box>
        <Flex w={'90%'} gap={'40px'} fontWeight={'600'} fontSize={'15px'} fontFamily={'poppins'} justifyContent={'center'} mt={'10px'}>
          <Link to='/advisor-generate-quote'>
            <Box>{t('Generate Quote')}</Box>
          </Link>
          
          {/* <Link to={'/advisor-quotes'}>
            <Box>{t('My Quotes')}</Box>
          </Link>
          <Link to={'/advisor-policies'}>
            <Box>{t('My Policy')}</Box>
          </Link> */}
          {/* <Link to={'/advisor-commission'}>
            <Box>{t('Commission')}</Box>
          </Link> */}
          
          {/* <Link to={'/advisor-team'}>
            <Box>{t('My Team')}</Box>
          </Link> */}
          <Link to={'/advisor-profile'}>
            <Box>{t('My Account')}</Box>
          </Link>
          <HeaderDropdown />
        </Flex>
        <Flex w={'20%'} gap={'10px'} alignItems={'center'}>
        <MdCall />
          <Text fontWeight={'600'} fontFamily={'poppins'}>1844-844-3272</Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default AdvisorHeader;
