import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SideBox from "../../../Utils/SideBox/SideBox";
import { Tab, TabList, TabPanel, TabPanels, Tabs,Box } from "@chakra-ui/react";
import SuperVisa from "../../SuperVisa";
import ViewQuote from "./ViewQuote";
import { advisorSingleGetQuotes } from '../../Advisor/Dependencies/action';
import { useDispatch, useSelector } from 'react-redux';


const CustomerSingleQuoteView = () => {
    const { id } = useParams();
    const [data,setData]=useState([]);
    // const data = useSelector(state => state.advisorSingleQuote.data);
    console.log(id,'id')
    const dispatch = useDispatch();
    
    useEffect(() => {
      if (id) {
          dispatch(advisorSingleGetQuotes(id))
              .then(response => {
                  setData(response.data); // Update state with fetched data
              })
              .catch(error => {
                  console.error('Error fetching data:', error);
              });
      }
  }, [dispatch, id]);


return (
        <div>
          <SuperVisa />
          <div>
          <Tabs style={{ marginTop: "20px", display:'flex' ,width:'80%', margin:'auto' }} variant="unstyled">
            <Box width="20%">
            <TabList 
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px solid #088183",
                backgroundColor: "#088183",
                padding: "10px",
                paddingBottom: "10px",
                marginTop:'50px',
                height:'auto',
                color: "white",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              <Tab
                _selected={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Get a Quote
              </Tab>
              <Tab
                _selected={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Confirm & Pay
              </Tab>
              <Tab
                _selected={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Purchase Policy
              </Tab>
            </TabList>
            </Box>
    
            <TabPanels style={{ marginTop: "10px",width:'80%'}}>
              <TabPanel>
                <ViewQuote id={id}/>
              </TabPanel>
              <TabPanel>
                <p>two!</p>
              </TabPanel>
            </TabPanels>
          </Tabs>
          </div>
        </div>
      );
    }

export default CustomerSingleQuoteView
